// src/components/filter.table.js
import React from "react";

import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
// import { IconName } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaAngleDown } from "@fortawesome/free-solid-svg-icons";
// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      {/* Search:{' '} */}
      <input
        className="form-control"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Filter ${count} records...`}
      />
    </span>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      // placeholder={`Search ${count} records...`}
    />
  );
}

function Table({ columns, data }) {
  const defaultColumn = React.useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0, pageSize: 500 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div >
      <div style={{ margin: "auto", width: "250px" }}>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
      <br />
      <ul className="pagination" style={{ margin: "auto" }}>
        <span style={{ margin: "auto" }}></span>
        Rows per page
        <select
          className="form-control"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          style={{
            marginLeft: "15px",
            width: "80px",
            height: "38px",
            padding: "0px 2px",
          }}
        >
          {[10, 100, 500, 1000, 5000, "All"].map((pageSize) => (
            <option
              key={pageSize == "All" ? 20000 : pageSize}
              value={pageSize == "All" ? 20000 : pageSize}
            >
              {pageSize == "All" ? "All" : pageSize}
            </option>
          ))}
        </select>
        <li
          className="page-item"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <a className="page-link">First</a>
        </li>
        <li
          className="page-item"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <a className="page-link">{"<"}</a>
        </li>
        <li
          className="page-item"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <a className="page-link">{">"}</a>
        </li>
        <li
          className="page-item"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <a className="page-link">Last</a>
        </li>
        <li>
          <a className="page-link">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </a>
        </li>
        <li>
          <a className="page-link">
            <input
              className="form-control"
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px", height: "24px", border: "none" }}
            />
          </a>
        </li>
      </ul>
      <div style={{ maxHeight: "500px",
      overflowY: "auto",}}>
      <table className="table" {...getTableProps()}>
        <thead
          style={{
            position: "sticky",
            top: 0,
            backgroundColor: "white",
            textAlign: "center",
          }}
        >
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              <td></td>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <span>
                    {column.render("Header")}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span style={{ color: "grey", padding: "0 5px" }}>
                          &#8744;
                        </span>
                      ) : (
                        <span style={{ color: "grey", padding: "0 5px" }}>
                          &#8743;
                        </span>
                      )
                    ) : (
                      <span style={{ color: "grey", padding: "0 5px" }}>
                        &#8744;
                      </span>
                    )}
                  </span>
                  {/* # Free icons styles
npm i --save @fortawesome/free-solid-svg-icons */}

                  {/* Render the columns filter UI */}
                  {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody style={{ textAlign: "center" ,  backgroundColor: "white",height: '200px',overflow: 'scroll'}} {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                <td>{i + 1}</td>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      </div>
      <ul className="pagination" style={{ margin: "auto" }}>
        <span style={{ margin: "auto" }}></span>
        Rows per page
        <select
          className="form-control"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          style={{
            marginLeft: "15px",
            width: "80px",
            height: "38px",
            padding: "0px 2px",
          }}
        >
          {[10, 100, 500, 1000, 5000, "All"].map((pageSize) => (
            <option
              key={pageSize == "All" ? 20000 : pageSize}
              value={pageSize == "All" ? 20000 : pageSize}
            >
              {pageSize == "All" ? "All" : pageSize}
            </option>
          ))}
        </select>
        <li
          className="page-item"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <a className="page-link">First</a>
        </li>
        <li
          className="page-item"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <a className="page-link">{"<"}</a>
        </li>
        <li
          className="page-item"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <a className="page-link">{">"}</a>
        </li>
        <li
          className="page-item"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <a className="page-link">Last</a>
        </li>
        <li>
          <a className="page-link">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </a>
        </li>
        <li>
          <a className="page-link">
            <input
              className="form-control"
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px", height: "24px", border: "none" }}
            />
          </a>
        </li>
      </ul>
    </div>
  );
}

function ReactTable(props) {
  // const columns = React.useMemo(
  //     () => [
  //         {
  //             Header: 'Name',
  //             columns: [
  //                 {
  //                     Header: 'First Name',
  //                     accessor: 'firstName',
  //                 },
  //                 {
  //                     Header: 'Last Name',
  //                     accessor: 'lastName'
  //                 },
  //             ],
  //         },
  //         {
  //             Header: 'Info',
  //             columns: [
  //                 {
  //                     Header: 'Age',
  //                     accessor: 'age'
  //                 },
  //                 {
  //                     Header: 'Visits',
  //                     accessor: 'visits'
  //                 },
  //                 {
  //                     Header: 'Status',
  //                     accessor: 'status'
  //                 },
  //                 {
  //                     Header: 'Profile Progress',
  //                     accessor: 'progress'
  //                 },
  //             ],
  //         },
  //     ],
  //     []
  // )

  // const data = [
  //   {
  //     "firstName": "jane",
  //     "lastName": "leon",
  //     "age": 25,
  //     "visits": 200,
  //     "progress": 100,
  //     "status": "single"
  // },
  //     {
  //         "firstName": "horn-od926",
  //         "lastName": "selection-gsykp",
  //         "age": 1,
  //         "visits": 20,
  //         "progress": 12,
  //         "status": "single"
  //     },
  //     {
  //         "firstName": "heart-nff6w",
  //         "lastName": "information-nyp92",
  //         "age": 16,
  //         "visits": 98,
  //         "progress": 40,
  //         "status": "complicated"
  //     },
  //     {
  //         "firstName": "minute-yri12",
  //         "lastName": "fairies-iutct",
  //         "age": 17,
  //         "visits": 77,
  //         "progress": 1,
  //         "status": "single"
  //     },
  //     {
  //         "firstName": "degree-jx4h0",
  //         "lastName": "man-u2y40",
  //         "age": 27,
  //         "visits": 54,
  //         "progress": 92,
  //         "status": "relationship"
  //     },
  //     {
  //         "firstName": "degree-jx4h0",
  //         "lastName": "man-u2y40",
  //         "age": 7,
  //         "visits": 54,
  //         "progress": 92,
  //         "status": "relationship"
  //     },
  //     {
  //         "firstName": "degree-jx4h0",
  //         "lastName": "man-u2y40",
  //         "age": 2,
  //         "visits": 54,
  //         "progress": 92,
  //         "status": "relationship"
  //     },
  //     {
  //         "firstName": "degree-jx4h0",
  //         "lastName": "man-u2y40",
  //         "age": 27,
  //         "visits": 5,
  //         "progress": 92,
  //         "status": "relationship"
  //     },
  //     {
  //       "firstName": "horn-od926",
  //       "lastName": "selection-gsykp",
  //       "age": 22,
  //       "visits": 20,
  //       "progress": 1,
  //       "status": "single"
  //   },
  //   {
  //       "firstName": "heart-nff6w",
  //       "lastName": "information-nyp92",
  //       "age": 16,
  //       "visits": 98,
  //       "progress": 40,
  //       "status": "complicated"
  //   },
  //   {
  //       "firstName": "minute-yri12",
  //       "lastName": "fairies-iutct",
  //       "age": 7,
  //       "visits": 77,
  //       "progress": 39,
  //       "status": "single"
  //   },
  //   {
  //       "firstName": "degree-jx4h0",
  //       "lastName": "man-u2y40",
  //       "age": 27,
  //       "visits": 54,
  //       "progress": 92,
  //       "status": "relationship"
  //   },
  //   {
  //       "firstName": "degree-jx4h0",
  //       "lastName": "man-u2y40",
  //       "age": 7,
  //       "visits": 54,
  //       "progress": 92,
  //       "status": "relationship"
  //   },
  //   {
  //       "firstName": "degree-jx4h0",
  //       "lastName": "man-u2y40",
  //       "age": 2,
  //       "visits": 54,
  //       "progress": 92,
  //       "status": "relationship"
  //   },
  //   {
  //       "firstName": "degree-jx4h0",
  //       "lastName": "man-u2y40",
  //       "age": 27,
  //       "visits": 5,
  //       "progress": 92,
  //       "status": "relationship"
  //   },
  //   {
  //         "firstName": "horn-od926",
  //         "lastName": "selection-gsykp",
  //         "age": 22,
  //         "visits": 20,
  //         "progress": 39,
  //         "status": "single"
  //     },
  //     {
  //         "firstName": "heart-nff6w",
  //         "lastName": "information-nyp92",
  //         "age": 16,
  //         "visits": 98,
  //         "progress": 40,
  //         "status": "complicated"
  //     },
  //     {
  //         "firstName": "minute-yri12",
  //         "lastName": "fairies-iutct",
  //         "age": 7,
  //         "visits": 77,
  //         "progress": 39,
  //         "status": "single"
  //     },
  //     {
  //         "firstName": "degree-jx4h0",
  //         "lastName": "man-u2y40",
  //         "age": 27,
  //         "visits": 54,
  //         "progress": 92,
  //         "status": "relationship"
  //     },
  //     {
  //         "firstName": "degree-jx4h0",
  //         "lastName": "man-u2y40",
  //         "age": 7,
  //         "visits": 54,
  //         "progress": 92,
  //         "status": "relationship"
  //     },
  //     {
  //         "firstName": "degree-jx4h0",
  //         "lastName": "man-u2y40",
  //         "age": 2,
  //         "visits": 54,
  //         "progress": 92,
  //         "status": "relationship"
  //     },
  //     {
  //         "firstName": "degree-jx4h0",
  //         "lastName": "man-u2y40",
  //         "age": 27,
  //         "visits": 5,
  //         "progress": 92,
  //         "status": "relationship"
  //     },
  //     {
  //       "firstName": "horn-od926",
  //       "lastName": "selection-gsykp",
  //       "age": 22,
  //       "visits": 20,
  //       "progress": 39,
  //       "status": "single"
  //   },
  //   {
  //       "firstName": "heart-nff6w",
  //       "lastName": "information-nyp92",
  //       "age": 16,
  //       "visits": 98,
  //       "progress": 40,
  //       "status": "complicated"
  //   },
  //   {
  //       "firstName": "minute-yri12",
  //       "lastName": "fairies-iutct",
  //       "age": 7,
  //       "visits": 77,
  //       "progress": 39,
  //       "status": "single"
  //   },
  //   {
  //       "firstName": "degree-jx4h0",
  //       "lastName": "man-u2y40",
  //       "age": 27,
  //       "visits": 54,
  //       "progress": 92,
  //       "status": "relationship"
  //   },
  //   {
  //       "firstName": "degree-jx4h0",
  //       "lastName": "man-u2y40",
  //       "age": 7,
  //       "visits": 54,
  //       "progress": 92,
  //       "status": "relationship"
  //   },
  //   {
  //       "firstName": "degree-jx4h0",
  //       "lastName": "man-u2y40",
  //       "age": 2,
  //       "visits": 54,
  //       "progress": 92,
  //       "status": "relationship"
  //   },
  //   {
  //       "firstName": "degree-jx4h0",
  //       "lastName": "man-u2y40",
  //       "age": 27,
  //       "visits": 5,
  //       "progress": 92,
  //       "status": "relationship"
  //   }
  // ]

  return <Table columns={props.columns} data={props.data} />;
}

export default ReactTable;
