import {
  defaultFont,
  primaryColor,
  dangerColor,
  grayColor,
  warningColor,
  successColor
} from "assets/jss/material-dashboard-react.js";
import tooltipStyle from "assets/jss/material-dashboard-react/tooltipStyle.js";
import checkboxAdnRadioStyle from "assets/jss/material-dashboard-react/checkboxAdnRadioStyle.js";
const tasksStyle = {
  ...tooltipStyle,
  ...checkboxAdnRadioStyle,
  table: {
    marginBottom: "0",
    overflow: "visible",
  },
  tableRow: {
    position: "relative",
    borderBottom: "1px solid " + grayColor[5],
  },
  tableActions: {
    display: "flex",
    border: "none",
    padding: "12px 8px !important",
    verticalAlign: "middle",
  },
  tableHeadCell: {
    color: primaryColor[0],
    ...defaultFont,
    fontWeight: "800",
    "&, &$tableCell": {
      fontSize: "24px",
    },
  },
  tableCell: {
    ...defaultFont,
    padding: "8px",
    verticalAlign: "middle",
    border: "none",
    lineHeight: "1.42857143",
    fontSize: "14px",
  },
  tableCellRTL: {
    textAlign: "right",
  },
  tableActionButton: {
    width: "27px",
    height: "27px",
    padding: "0",
  },
  tableActionButtonIcon: {
    width: "17px",
    height: "17px",
  },
  edit: {
    backgroundColor: "transparent",
    color: primaryColor[0],
    boxShadow: "none",
  },
  close: {
    backgroundColor: "transparent",
    color: dangerColor[0],
    boxShadow: "none",
  },
  strong: {
    color: "#000",
    ...defaultFont,
    fontWeight: "800",
    "&, &$tableCell": {
      fontSize: "14px",
    },
  },
  originHeaderText: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '30px',
    textTransform: 'capitalize',
    color: '#34566A',
  },

  divChaterView:{
    marginTop:'25px',
  },
  icon:{
    cursor: 'pointer',
  },
  mouses:{
    cursor: 'pointer',
    border:' 1px solid #ddd',
    textAlign: 'left',
    padding: '8px'
  },
  paragraphStyle : {
    marginTop:'15px',
    padding: '5px 10px',
    border: '2px solid'+ primaryColor[0],
    borderTopWidth: '30px',
    overflowY: 'scroll',
    width:'100%',
    height:'400px',
  },
    highlightStyle:{
      backgroundColor: 'yellow', 
      color: 'black' ,
      
    },
    tableContainerChapterView: {
      maxHeight: "300px",
      overflowY: "auto",
    },
    tableChapterView: {
      width: "100%",
      borderCollapse: "collapse",
    },
    theadChapterView: {
      color: primaryColor[0],
      position: "sticky",
      top: "0"
    },
    thChapterView: {
      padding: "10px",
      border:' 1px solid #ddd'
    },
    tbodyChapterView: {
      height: "100%",
      overflowY: "auto",
      backgroundColor:'rgba(255, 205, 0, 0.25)',
    },
    trChapterView: {
      borderBottom: "1px solid #ddd",
    },
    tdChapterView: {
      padding: "10px",
      textAlign: "center",
      border:' 1px solid #ddd'
    }
  
};
export default tasksStyle;
