import React from 'react';
import "./AddConfirmationDialog.css";
import { useState } from 'react';
import { Dialog } from '@material-ui/core';
const AddConfirmationDialog = ({ itemName, handleAddWordToWordlist, onCancel, item }) => {
    const [openDialog, setOpenDialog] = useState(true)
    return (
        <Dialog
            open={openDialog}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description">
            <div className="add-confirmation-dialog">
                <h3>Confirm Add</h3>
                {itemName == "wordlists" || itemName=="literature-wordlists"|| itemName=="book_scan_lists"? <div><p> This word does not exist, are you sure you want to create a new word ?</p>
                    <ul>
                        <li>Word: <strong>{item?.word}</strong></li>
                        <li>Wordlist: <strong>{item?.wordlist}</strong></li>
                    </ul></div> : ""}
                <div className="button-container">
                    <button onClick={handleAddWordToWordlist} className="add-button">
                        Add
                    </button>
                    <button onClick={onCancel} className="cancel-button">
                        Cancel
                    </button>
                </div>
            </div>
        </Dialog>
    );
};

export default AddConfirmationDialog;
