/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.left}>
        <List className={classes.list}>

<ListItem className={classes.inlineBlock}>
  <a
    href="https://wordvoyage.com/index.php/about-us/our-story"
    className={classes.block}
    target="_blank"
  >
    About us
  </a>
</ListItem>
<ListItem className={classes.inlineBlock}>
  <a
    href="http://wordvoyage.com/blog/"
    className={classes.block}
    target="_blank"
  >
    Blog
  </a>
</ListItem>
<ListItem className={classes.inlineBlock}>
  <a
    href="https://wordvoyage.com/index.php/resources/word-voyage-podcasts"
    className={classes.block}
    target="_blank"
  >
    Podcast
  </a>
</ListItem>
<ListItem className={classes.inlineBlock}>
  <a
    href="https://wordvoyage.com/index.php/word-voyage-privacy-policy"
    className={classes.block}
    target="_blank"
  >
    Privacy Policy
  </a>
</ListItem>
</List>
        </div>
        <p className={classes.right}>
          <span>
            &copy; {1900 + new Date().getYear()} , Conversational Approach to learning English with
          <a
            href="https://wordvoyage.com/"
            target="_blank"
            className={classes.a}
          >
            {"  "}Wordvoyage
          </a>
          </span>
        </p>
      </div>
    </footer>
  );
}
