import axios from "axios";
class AuthService {
  login(email, password) {
    return axios
      .post(process.env.REACT_APP_API_URL + "signin", {
        email,
        password,
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        localStorage.setItem("user", JSON.stringify(response.data));
        return response.data;
      });
  }
  logout() {
    localStorage.removeItem("user");
  }
  register(email, password,organizationType) {
    return axios
      .post(process.env.REACT_APP_API_URL + "signup", {
        email,
        password,
        organizationType
      })
      .then((response) => {
        if (response.data.token) {
          // localStorage.setItem("user", JSON.stringify(response.data));
        }
        // localStorage.setItem("user", JSON.stringify(response.data));
        return response.data;
      });
  }
  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  sendPasswordResetCode(email) {
    return axios
      .post(process.env.REACT_APP_API_URL + "forgot-password", {
        email,
      })
      .then((response) => {
        return response.data;
      });
  }
  usePasswordResetCode(email, password, resetCode) {
    return axios
      .post(process.env.REACT_APP_API_URL + "reset-password", {
        email,
        password,
        resetCode,
      })
      .then((response) => {
        return response.data;
      });
  }

  resendConfirmationEmail(email) {
  return axios
    .post(process.env.REACT_APP_API_URL + "resend-email", {
      email
    })
    .then((response) => {
      return response.data;
    });
}
}
export default new AuthService();
