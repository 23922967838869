/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
// import BubbleChart from "@material-ui/icons/BubbleChart";
// import LocationOn from "@material-ui/icons/LocationOn";
import Book from "@material-ui/icons/Book";
import Notifications from "@material-ui/icons/Notifications";
// import Unarchive from "@material-ui/icons/Unarchive";
// import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
// import DashboardPage1 from "views/Dashboard/Dashboard1.js";
import UserProfile from "views/UserProfile/UserProfile.js";
// import TableList from "views/TableList/TableList.js";
import CoreData from "views/CoreData/CoreData.js";
import Typography from "views/Typography/Typography.js";
// import Icons from "views/Icons/Icons.js";
import Mapspage from "views/Maps/Maps.js";
// import Bookscan from "views/Bookscan/BookScan.js";
import NotificationsPage from "views/Notifications/Notifications.js";
// import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
// core components/views for RTL layout
// import RTLPage from "views/RTLPage/RTLPage.js";

const dashboardRoutes = [
  {
    path: "/admin/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "",
  },
  // {
  //   path: "/dashboard1",
  //   name: "Dashboard1",
  //   rtlName: "لوحة القيادة",
  //   icon: Dashboard,
  //   component: DashboardPage1,
  //   layout: "/admin",
  // },
  // {
  //   path: "/organizations",
  //   name: "Organizations",
  //   rtlName: "طباعة",
  //   icon: LibraryBooks,
  //   component: Typography,
  //   layout: "/admin",
  // },
  {
    path: "/users",
    name: "Users",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UserProfile,
    layout: "/admin",
  },
  // {
  //   path: "/data",
  //   name: "Core Data",
  //   rtlName: "قائمة الجدول",
  //   icon: "content_paste",
  //   component: TableList,
  //   layout: "/admin",
  // },
  // {
  //   path: "/admin/coredata",
  //   name: "Core Data",
  //   rtlName: "قائمة الجدول",
  //   icon: "content_paste",
  //   component: CoreData,
  //   layout: "",
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   rtlName: "الرموز",
  //   icon: BubbleChart,
  //   component: Icons,
  //   layout: "/admin",
  // },

  // {
  //   path: "/bookscan",
  //   name: "Bookscan",
  //   rtlName: "خرائط",
  //   icon: Book,
  //   component: Mapspage,
  //   layout: "/admin",
  // },

  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   rtlName: "إخطارات",
  //   icon: Notifications,
  //   component: NotificationsPage,
  //   layout: "/admin",
  // },

  // {
  // path: "/bookscan",
  // name: "BookScan",
  // rtlName: "پشتیبانی از راست به چپ",
  // component: BookScan,
  // layout: "/admin",
  // },

  // {
  // path: "/bookscan",
  // name: "RTL ",
  // rtlName: "پشتیبانی از راست به چپ",
  // icon: Language,
  // component: RTLPage,
  // layout: "/rtl",
  // },

  // {
  //   path: "/upgrade-to-pro",
  //   name: "Upgrade To PRO",
  //   rtlName: "التطور للاحترافية",
  //   icon: Unarchive,
  //   component: UpgradeToPro,
  //   layout: "/admin",
  // },
];

export default dashboardRoutes;
