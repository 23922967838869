import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
// import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import dashboardRoutes from "routes.js";
import dashboardAdminRoutes from "adminRoutes.js";
import Dashboard from "views/Dashboard/Dashboard.js";
import CoreData from "views/CoreData/CoreData.js";
import DataAnalysis from "views/DataAnalysis/DataAnalysis.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import SignUpPage from "views/SignUpPage/SignUpPage.js";
import Users from "views/UserProfile/UserProfile";
import Organizations from "views/Typography/Typography";
import WordPage from "views/WordPage/WordPage";
import Bookscan from "views/Maps/Maps.js";
import Auth from "./Auth";
import AuthService from "services/auth.service";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import image from "assets/img/bg7.jpg";
import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/reactlogo.png";
import { Button } from "bootstrap";



let ps;

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      AuthService.getCurrentUser() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
        // <Component {...props} />
      )
    }
  />
);
const switchRoutes = (
  <Switch>
    <Route exact path="/" component={LoginPage} />
    <Route exact path="/admin/signup" component={SignUpPage} />
    <PrivateRoute exact path="/admin/dashboard" component={Dashboard} />
    <PrivateRoute path="/admin/coredata" component={CoreData} exact />
    <PrivateRoute path="/admin/bookscan" component={Bookscan} exact />
    <PrivateRoute path="/admin/dataAnalysis" component={DataAnalysis} exact />
    <PrivateRoute path="/admin/users" component={Users} exact />
    <PrivateRoute path="/admin/organizations" component={Organizations} exact />
    <PrivateRoute
      path="/admin/books/:bookname"
      component={Organizations}
      exact
    />
    <PrivateRoute path="/admin/words/:word" component={WordPage} exact />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Router({ ...rest }) {
  //   // styles
  const classes = useStyles();
  //   // ref to help us initialize PerfectScrollbar on windows  devices
  const mainPanel = React.createRef();
  //   // states and functionsdisplayNone
  // const [image, setImage] = React.useState(bgImage);
  const [bgColor, setBgColor] = React.useState("#34566A");
  const [color, setColor] = React.useState("blue");
  const [width, setWidth] = React.useState("");
  const [displayNone, setDisplayNone] = React.useState("");
  const [user, setUser] = React.useState(AuthService.getCurrentUser());
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const collapseSideBar = () => {
    setWidth("100%");
    setDisplayNone("none");
  };

  const showSideBar = () => {
    setWidth("");
    setDisplayNone("");
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  // React.useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(mainPanel.current, {
  //       suppressScrollX: true,
  //       suppressScrollY: false,
  //     });
  //     document.body.style.overflow = "hidden";
  //   }
  //   window.addEventListener("resize", resizeFunction);
  //   // Specify how to clean up after this effect:
  //   return function cleanup() {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //     }
  //     window.removeEventListener("resize", resizeFunction);
  //   };
  // }, [mainPanel]);
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={
          window.location.href ==
            process.env.REACT_APP_ADMIN_DASHBOARD_URL + "admin/signup" ||
          window.location.href == process.env.REACT_APP_ADMIN_DASHBOARD_URL
            ? []
            : user && user.role === "Super Admin"
            ? dashboardRoutes
            : user && user.role === "admin"
            ? dashboardAdminRoutes
            : user && user.role === "organization"
            ? dashboardAdminRoutes
            : user && user.role === "teacher"
            ? dashboardAdminRoutes
            : user && user.role === "student"
            ? dashboardAdminRoutes
            : user && user.role === "parent"
            ? dashboardAdminRoutes
            : []
        }
        logoText={"Word Voyage"}
        logo={logo}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        display={displayNone}
        collapseSideBar={collapseSideBar}
        showSideBar={showSideBar}
        {...rest}
      />
      <div
        style={{ width: width }}
        className={classes.mainPanel}
        ref={mainPanel}
      >
        {window.location.href ==
          process.env.REACT_APP_ADMIN_DASHBOARD_URL + "admin/signup" ||
        window.location.href == process.env.REACT_APP_ADMIN_DASHBOARD_URL ? (
          []
        ) : (
          <Navbar
            routes={
              window.location.href ==
                process.env.REACT_APP_ADMIN_DASHBOARD_URL + "admin/signup" ||
              window.location.href == process.env.REACT_APP_ADMIN_DASHBOARD_URL
                ? []
                : user && user.role === "Super Admin"
                ? dashboardRoutes
                : dashboardAdminRoutes
            }
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />
        )}
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.mainPanel}>{switchRoutes}</div>
        )}
        {/* {getRoute() ? <Footer /> : null} */}
        {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
        /> */}
      </div>
    </div>
  );
}
