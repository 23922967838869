import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Header from "../../Header";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Table from "components/Table/Table.js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import avatar from "assets/img/faces/marc.jpg";
import ReactTable from "components/ReactTable/ReactTable";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "12px",
    textTransform: 'uppercase',
    marginTop: "0",
    marginBottom: "0",
    marginLeft:"20px",
    fontWeight: "300",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "5px",
    minHeight: "auto",
    fontSize: "16px",
    fontWeight: "300",
    fontFamily: "'Poppins', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    marginLeft:"20px",
    textDecoration: "none",
    textTransform: 'uppercase',

  },
};

const useStyles = makeStyles(styles);

export default function TypographyPage() {
  const classes = useStyles();
  const [newOrganization, setNewOrganization] = useState("");
  const [newOrganizationEmail, setNewOrganizationEmail] = useState("");
  const [newUserUsername, setNewUserUsername] = useState("");
  const [allOrganizations, setAllOrganization] = useState([]);
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const columns = React.useMemo(
    () => [
      {
        Header: "Organization Name",
        accessor: "organization",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Status",
        accessor: "status",
      },
    ],
    []
  );
  const handleAddNewOrganization = (e) => {
    e.preventDefault();
    let organization = {
      email: newOrganizationEmail,
      organization: newOrganization,
    };
    if (new Date() >new Date(currentUser.expiresInTime) ) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          newOrganizationEmail
        ) === false
      ) {
        toast.error("Email field is invalid", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (newOrganization === "") {
        toast.error("Organization field is invalid", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        axios
          .post(
            process.env.REACT_APP_API_URL + "organizations/new",
            { organization },
            { headers }
          )
          .then((response) => {
            if (response.data.code === 200) {
              toast.success(`${response.data.message}`, {
                position: toast.POSITION.TOP_CENTER,
              });
              const data=response.data.data;
              let arr = [];
              for (let i=0; i<data.length; i++) {
                arr.push({organization:data[i].organization,type:data[i].type, status:data[i].status});

              }
              setAllOrganization(arr);
              setNewOrganization("");
              setNewOrganizationEmail("");
            } else {
              toast.error(`${response.data.message}`, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          })
          .catch((error) => {
            toast.error("Failed to add organization", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      }
    }
  };

  const getallOrganizations = () => {
    if (new Date() >new Date(currentUser.expiresInTime) ) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
    fetch(process.env.REACT_APP_API_URL + "organizations",{headers})
      .then((res) => res.json())
      .then((data) => {
        let arr = [];
        for (const key in data) {
          arr.push({organization:data[key].organization,type:data[key].type, status:data[key].status});
        }
        setAllOrganization(arr);
        // console.log(allOrganizations);
      });
    }
  };
  useEffect(() => {
    getallOrganizations();
  }, []);
  return (
    <div>
      {currentUser.role==="Super Admin"? <div>
      {/* <Header /> */}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title=""
            headerColor="primary"
            tabs={[
              {
                tabName: "Add Organization",
                // tabIcon: Book,
                tabContent: (
                  <Card>
                    <CardHeader color="primary">
                      <h4 className={classes.cardTitleWhite}>
                        Organization Profile
                      </h4>
                      <p className={classes.cardCategoryWhite}>
                        Complete Organization profile
                      </p>
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={5}>
                          <CustomInput
                            labelText="Organization Name"
                            // id="company-disabled"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              // disabled: true,
                              value: newOrganization,
                              onChange: (e) =>
                                setNewOrganization(e.target.value),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={1}></GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="Email address"
                            id="email-address"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: newOrganizationEmail,
                              onChange: (e) =>
                                setNewOrganizationEmail(e.target.value),
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <br />
            
                    </CardBody>
                    <CardFooter>
                      <Button
                      color="secondary"
                        onClick={handleAddNewOrganization}
                      >
                        Add Organization
                      </Button>
                    </CardFooter>
                    <ToastContainer />
                  </Card>
                ),
              },
              {
                tabName: "All Organizations",
                // tabIcon: Book,
                tabContent: (
                  <div>
                    <div className={classes.searchWrapper}>
                    <ReactTable columns={columns} data={allOrganizations} />
                    </div>
                  </div>
                ),
              },
 
            ]}
          />
        </GridItem>

      </GridContainer>
    </div>:<h1 style={{textAlign:'center'}}>Unauthorized page. Please contact admin</h1>}
    </div>
  );
}
