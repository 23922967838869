import React, { useEffect } from "react";
import { useState } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import Header from "../../Header";
import axios from "axios";
// import BugReport from "@material-ui/icons/Home";
// import Book from "@material-ui/icons/Book";
// import Code from "@material-ui/icons/Person";
// import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import AuthService from "../../services/auth.service";
import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Grid from "@mui/material/Grid";
import styles1 from "./DashboardStlyes";
import IconButton from '@mui/material/IconButton';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import PercentIcon from '@mui/icons-material/Percent';
import GroupsIcon from '@mui/icons-material/Groups';
import StorageIcon from '@mui/icons-material/Storage';

import { SettingsInputAntennaTwoTone } from "@material-ui/icons";
// function Organization() {
//   fetch('http://localhost:3002/organizations')
//   .then(res => res.json())
//   .then(data => {
//     const organizationCount = [];
//     data.forEach(organization => {
//       organizationCount.push(organization)
//     })
//     console.log(organizationCount.length)
//     return organizationCount.length
//    })
// }

const useStyles = makeStyles(styles);
const useStyles1 = makeStyles(styles1);

export default function Dashboard() {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [organizationLength, setOrganizationLength] = useState(0);
  const [usersCount, setUsersCount] = useState([]);
  const [numberOfAllTeachers, setNumberOfAllTeachers] = useState(0);
  const [numberOfAllStudents, setNumberOfAllStudents] = useState(0);
  const [numberOfAllAdmin, setNumberOfAllAdmin] = useState(0);
  const [numberOfTeachers, setNumberOfTeachers] = useState(0);
  const [numberOfStudents, setNumberOfStudents] = useState(0);
  const [numberOfAdmin, setNumberOfAdmin] = useState(0);
  const [numberOfParents, setNumberOfParents] = useState(0);
  const [numberOfAllParents, setNumberOfAllParents] = useState(0);
  const [userLength, setUserLength] = useState(0);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + currentUser.accessToken,
  };

  const classes = useStyles();
  const classes1 = useStyles1();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "organizations", { headers })
      .then((data) => {
        const organizationCount = [];
        for (const key in data.data) {
          organizationCount.push(data.data[key]);
        }
        setOrganizationLength(organizationCount.length);
      });

    axios
      .get(process.env.REACT_APP_API_URL + "users", { headers })
      .then((data) => {
        const usersCount = [];
        for (const key in data.data.text) {
          usersCount.push(data.data.text[key]);
        }
        const numberOfTeachers = usersCount.filter((user) => {
          if (
            user.organization !== currentUser.organization ||
            user.role !== "teacher"
          ) {
            return false;
          }
          return true;
        });
        const numberOfStudents = usersCount.filter((user) => {
          if (
            user.organization !== currentUser.organization ||
            user.role !== "student"
          ) {
            return false;
          }
          return true;
        });
        const numberOfAdmin = usersCount.filter((user) => {
          if (
            user.organization !== currentUser.organization ||
            user.role !== "admin"
          ) {
            return false;
          }
          return true;
        });
        const numberOfAllTeachers = usersCount.filter((user) => {
          if (user.role !== "teacher") {
            return false;
          }
          return true;
        });
        const numberOfAllStudents = usersCount.filter((user) => {
          if (user.role !== "student") {
            return false;
          }
          return true;
        });
        const numberOfAllAdmin = usersCount.filter((user) => {
          if (user.role !== "admin") {
            return false;
          }
          return true;
        });
        const numberOfParents = usersCount.filter((user) => {
          if (
            user.organization !== currentUser.organization ||
            user.role !== "parent"
          ) {
            return false;
          }
          return true;
        });
        const numberOfAllParents = usersCount.filter((user) => {
          if (user.role !== "parent") {
            return false;
          }
          return true;
        });
        setNumberOfAdmin(numberOfAdmin.length);
        setNumberOfAllAdmin(numberOfAllAdmin.length);
        setNumberOfTeachers(numberOfTeachers.length);
        setNumberOfAllTeachers(numberOfAllTeachers.length);
        setNumberOfStudents(numberOfStudents.length);
        setNumberOfAllStudents(numberOfAllStudents.length);
        setNumberOfParents(numberOfParents.length);
        setNumberOfAllParents(numberOfAllParents.length);
        setUsersCount(usersCount);
        setUserLength(usersCount.length);
      });

    setCurrentUser(AuthService.getCurrentUser());
  }, []);
  return (
    <div style={{overflow: 'auto'}}>
      {currentUser.role === "Super Admin" ? (
        <div>
          <br></br>
          <br></br>
          <Grid container spacing={2} justifyContent='space-evenly' alignItems='start'>
            {/* <Grid item md={1}></Grid> */}
            <Grid item xs={10} sm={4} md={3}>
              <div className={classes1.cardWrapper}>
                <div className={classes1.dashIconWrapper}>
                  <div className='burst'>
                    <span>
                      <IconButton aria-label="sales" size="small" sx={{color: '#34566A'}}>
                      <PercentOutlinedIcon sx={{fontSize: 15}} />
                    </IconButton>
                    </span>
                  </div>
                </div>
                <div className={classes1.dashWrapper}>
                  <div className={classes1.dashHeader}>Sales:</div>
                  <p className={classes1.dashText}>Number of schools:</p>   
                  <p className={classes1.dashText}>Number of tutees:</p>  
                  <div className={classes1.dashFooter}>
                    <div className={classes1.dashLine}>&nbsp;</div>
                    <p className={classes1.footerText}>Get more space</p>
                  </div>         
                </div>
                <br/>
              </div>
            </Grid>

            <Grid item xs={10} sm={4} md={3}>
            <div className={classes1.cardWrapper}>
              <div className={classes1.dashIconWrapper}>
                <span>
                    <IconButton aria-label="users" size="small" sx={{color: '#FFFFFF'}}>
                    <GroupsIcon sx={{fontSize: 28}} />
                  </IconButton>
                </span>
              </div>
              <div className={classes1.dashWrapper}>
                <div className={classes1.dashHeader}>Users:</div>
                <p className={classes1.dashText}>Number of teachers/tutors: <strong>&nbsp; {organizationLength}</strong></p>   
                <p className={classes1.dashText}>Number of students:<strong>&nbsp; </strong></p>  
                <div className={classes1.dashFooter}>
                  <div className={classes1.dashLine}>&nbsp;</div>
                  <p className={classes1.footerText}>Last 24 Hours</p>
                </div>         
              </div>
              <br/>
            </div>
          </Grid>

            <Grid item xs={10} sm={4} md={3}>
              <div className={classes1.cardWrapper}>
                <div className={classes1.dashIconWrapper}>
                  <span>
                      <IconButton aria-label="sales" size="small" sx={{color: '#FFFFFF'}}>
                      <StorageIcon sx={{fontSize: 28}} />
                    </IconButton>
                  </span>
                </div>
                <div className={classes1.dashWrapperData}>
                  <div className={classes1.dashHeader}>Words</div>
                  <p className={classes1.dashText}>Number of books Scanned: <strong>&nbsp; </strong></p>   
                  <p className={classes1.dashText}>Number of words: <strong>&nbsp; 21300</strong></p>   
                  <p className={classes1.dashText}>Number of word families: <strong>&nbsp; 75</strong></p> 
                  <p className={classes1.dashText}>Number of root families: <strong>&nbsp; 3</strong></p>      
                  <div className={classes1.dashFooterLast}>
                    <div className={classes1.dashLine}>&nbsp;</div>

                  </div>         
                </div>
                <br/>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div style={{marginTop: '5px'}}><br></br></div>
            </Grid>
          </Grid>

          <Grid container spacing={3} justifyContent="space-between" alignItems='start'>
            <Grid item xs={12} sm={12} md={4}>
              <div chart="true" className={classes1.chartWrapper}>
                <div>
                  <ChartistGraph
                    style={{color: '#FFCD00B2'}}
                    className="ct-chart"
                    data={dailySalesChart.data}
                    type="Line"
                    options={dailySalesChart.options}
                    listener={dailySalesChart.animation}
                  />
                </div>
                <div className={classes1.chartText}>
                  <h4>Word List</h4>
                  <p>
                    <span className={classes.successText}>
                      <ArrowUpward className={classes.upArrowCardCategory} />{" "}
                      55%
                    </span>{" "}
                    increase in today interactions.
                  </p>
                </div>
                <div chart="true">
                  <div className={classes1.stats}>
                    <AccessTime /> updated 4 minutes ago
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div chart="true" className={classes1.chartWrapper}>
                <div>
                  <ChartistGraph
                    className="ct-chart"
                    data={emailsSubscriptionChart.data}
                    type="Bar"
                    options={emailsSubscriptionChart.options}
                    responsiveOptions={
                      emailsSubscriptionChart.responsiveOptions
                    }
                    listener={emailsSubscriptionChart.animation}
                  />
                </div>
                <div className={classes1.chartText}>
                  <h4>Email Subscriptions</h4>
                  <p>
                    Last Campaign Performance
                  </p>
                </div>
                <div chart="true">
                  <div className={classes1.stats}>
                    <AccessTime /> campaign sent 2 days ago
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div chart="true" className={classes1.chartWrapper}>
                <div>
                  <ChartistGraph
                    className="ct-chart"
                    data={completedTasksChart.data}
                    type="Line"
                    options={completedTasksChart.options}
                    listener={completedTasksChart.animation}
                  />
                </div>
                <div className={classes1.chartText}>
                  <h4>
                    Number of sentences written
                  </h4>
                  <p>
                    Last Campaign Performance
                  </p>
                </div>
                <div chart="true">
                  <div className={classes1.stats}>
                    <AccessTime /> campaign sent 2 days ago
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <Accessibility />
                </CardIcon>
                <p className={classes.cardCategory}>Number of Users</p>
                <h2
                  className={classes.cardCategory}
                  style={{ paddingRight: 105 }}
                >
                  Parent: {usersCount.length > 0 ? numberOfParents : ""}
                </h2>
                <h2
                  className={classes.cardCategory}
                  style={{ paddingRight: 100 }}
                >
                  Student: {usersCount.length > 0 ? numberOfStudents : ""}
                </h2>
                <h2
                  className={classes.cardCategory}
                  style={{ paddingRight: 100 }}
                >
                  Teacher: {usersCount.length > 0 ? numberOfTeachers : ""}
                </h2>
                <h2
                  className={classes.cardCategory}
                  style={{ paddingRight: 105 }}
                >
                  Admins:{usersCount.length > 0 ? numberOfAdmin : ""}
                </h2>
                <h3 className={classes.cardTitle}>
                  {usersCount.length > 0
                    ? numberOfStudents +
                      numberOfParents +
                      numberOfTeachers +
                      numberOfAdmin
                    : ""}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Update />
                  Just Updated
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </div>
      )}
    </div>
  );
}
