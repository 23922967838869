import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import AuthService from "../../services/auth.service";
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Auth from "../../Auth.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { Link } from "react-router-dom";
import image from "assets/img/bg7.jpg";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [forgot, setForgot] = React.useState(false);
  const [resetCode, setResetCode] = React.useState(false);

  const handleLogIn = (e) => {
    e.preventDefault();
    if (email==='') {
      toast.error("Email field is invalid", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (password === "") {
      toast.error("Password field is invalid", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      AuthService.login(email, password).then(
        (response) => {
          // this.props.history.push("/profile");
          // window.location.reload();
          if (response.code !== 401) {
            window.location.href = "/admin/dashboard";
            // console.log(response);
          } else if (response.status == "Not verified") {
            toast.error(<CustomToast/>, {
              position: toast.POSITION.TOP_CENTER,
              autoClose:10000
            });
          } else {
            toast.error(`${response.message}`, {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        },
        (error) => {
          toast.error("Unauthorize. Please contact Admin", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      );
    }
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setForgot(!forgot);
  };

  const handleEmailResetCode = (e) => {
    e.preventDefault();
    if (email==='') {
      toast.error("Email field is invalid", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      AuthService.sendPasswordResetCode(email).then(
        (response) => {
          if (response.code !== 401) {
            toast.success("A reset code has been sent to your email address!", {
              position: toast.POSITION.TOP_CENTER,
            });
          } else if (response.status == "Not verified") {
            toast.error(<CustomToast/>, {
              position: toast.POSITION.TOP_CENTER,
              autoClose:10000
            });
          }else {
            toast.error(`${response.message}`, {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        },
        (error) => {
          toast.error("Unauthorize. Please contact Admin", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      );
    }
  };
  const handleChangePassword = (e) => {
    e.preventDefault();
    if (email==='') {
      toast.error("Email field is invalid", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (password !== confirmPassword) {
      toast.error("Your password doesn't match with the confirmed password", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (resetCode === "") {
      toast.error("Reset code field is empty", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      AuthService.usePasswordResetCode(email, password, resetCode).then(
        (response) => {
          if (response.code !== 401) {
            toast.success("Your Password has been updated.", {
              position: toast.POSITION.TOP_CENTER,
            });
            setForgot(false);
            setEmail("");
            setPassword("");
          } else {
            toast.error(`${response.message}`, {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        },
        (error) => {
          toast.error("Unauthorize. Please contact Admin", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      );
    }
  };

  const CustomToast = ({ resendEmail }) => {
    return (
      <div>
        <p>Please verify your email first</p>
        <button
          onClick={handleResendConfirmationEmail}
          className={classes.btnLogin}     
               size="lg"
        >
          Resend confirmation email
        </button>
      </div>
    );
  };

  const handleResendConfirmationEmail= (e) => {
    e.preventDefault();
    if (email==='') {
      toast.error("Email field is invalid", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      AuthService.resendConfirmationEmail(email).then(
        (response) => {
          if (response.code !== 401) {
            toast.success(" We have sent an email with a confirmation link to your email address.", {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.error(`${response.message}`, {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        },
        (error) => {
          toast.error("Unauthorize. Please contact Admin", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      );
    }
  }

  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      {/* <Header
        absolute
        color="transparent"
        brand="Word Voyage"
        rightLinks={<HeaderLinks />}
        {...rest}
      /> */}
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          marginTop: "-150px",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>Login</h4>
                    {/* <div className={classes.socialLine}>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className={"fab fa-twitter"} />
                      </Button>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className={"fab fa-facebook"} />
                      </Button>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className={"fab fa-google-plus-g"} />
                      </Button>
                    </div> */}
                  </CardHeader>
                  <p className={classes.divider}>Explore Words!</p><br/><br/>
                  <CardBody>
                    <CustomInput
                    className={classes.inputCustom}
                      labelText="email..."
                      id="first"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: email,
                        onChange: (e) => setEmail(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <People className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    /><br/>
                    {forgot && (
                      <p
                      className={classes.forgotPassword}
                        onClick={handleEmailResetCode}
                      >
                        Send email reset code
                      </p>
                    )}<br/>
                    {/* <CustomInput
                      labelText="Organization..."
                      id="org"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    /> */}
                    <CustomInput
                      labelText="Password"
                      id="pass"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        value: password,
                        onChange: (e) => setPassword(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    /><br/>
                    {forgot && (
                      <CustomInput
                        labelText="Confirm Password"
                        id="confirmPassword"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "password",
                          value: confirmPassword,
                          onChange: (e) => setConfirmPassword(e.target.value),
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon className={classes.inputIconsColor}>
                                lock_outline
                              </Icon>
                            </InputAdornment>
                          ),
                          autoComplete: "off",
                        }}
                      />
                    )}
                    {forgot && (
                      <CustomInput
                        labelText="Reset code"
                        id="resetCode"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "number",
                          value: resetCode,
                          onChange: (e) => setResetCode(e.target.value),
                          autoComplete: "off",
                        }}
                      />
                    )}<br/>
                                        {forgot && (
                      <p
                      className={classes.forgotPassword}
                        onClick={handleForgotPassword}
                      >
                        Sign in
                      </p>
                    )}
                    {forgot === false && (
                      <p
                      className={classes.forgotPassword}
                        onClick={handleForgotPassword}
                      >
                        Forgot your username or password?
                      </p>
                    )}
                  </CardBody>

                  <CardFooter className={classes.cardFooter}>
                    {forgot === false && (
                      <Button
                      color="secondary"
                        onClick={handleLogIn}
                      >
                        Get started
                      </Button>
                    )}
                    {forgot && (
                      <Button
                        onClick={handleChangePassword}
                        color="secondary"
                      >
                        Reset Password
                      </Button>
                    )}
                  </CardFooter>
                  <p style={{textAlign:'center'}} ><strong >New to Word Voyage ? <Link to="/admin/signup" ><span className={classes.linkLogin}>Sign up</span></Link></strong></p>
                  <ToastContainer />
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        {/* <Footer whiteFont /> */}
      </div>
    </div>
  );
}
