import React from 'react';
import "./DeleteConfirmationDialog.css";
import { useState } from 'react';
import { Dialog } from '@material-ui/core';
const DeleteConfirmationDialog = ({ itemName, onDelete, onCancel,item }) => {
  const [openDialog,setOpenDialog]=useState(true)
  return (
    <Dialog 
   open={openDialog}
   aria-labelledby="modal-slide-title"
   aria-describedby="modal-slide-description">
    <div className="delete-confirmation-dialog">
    <h3>Deactivate</h3>
    <p>Are you sure you want to deactivate this {itemName} ?</p>
    {itemName==="root"}
    <ul>
      <li>Root Id: <strong>{item?.root_id}</strong></li>
      <li>Root Type: <strong>{item?.root_type}</strong></li>
      <li>Root Meaning: <strong>{item?.meaning}</strong></li>
      <li>Root Description: <strong>{item?.description}</strong></li>
    </ul>
    <div className="button-container">
      <button onClick={onDelete} className="delete-button">
      Deactivate
      </button>
      <button onClick={onCancel} className="cancel-button">
        Cancel
      </button>
    </div>
  </div>
  </Dialog>
  );
};

export default DeleteConfirmationDialog;
