import {
  primaryColor,
  secondaryColor,
  dangerColor,
  successColor,
  grayColor,
  whiteColor,
  blackColor,
  defaultFont,
} from "assets/jss/material-dashboard-react.js";


const styles1 = {
  cardWrapper: {
    boxSizing: "border-box",
    maxWidth: "220px",
    minHeight: "238px",
    color: whiteColor,
    fontFamily: 'Poppins',
    fontSize: '14px',
    textAlign: 'center',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // margin: '0 auto',
  },
  dashIconWrapper: {
    maxWidth: '4rem',
    maxHeight: '3rem',
    width: '100%',
    height: '100%',
    background: primaryColor[0],
    backgroundColor: primaryColor[0],
    border: '2px solid' + ' ' + secondaryColor[0],
    borderRadius: '5px',
    position: 'absolute',
    top: '-22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1
  },

  dashWrapper: {
    minWidth: '200px',
    minHeight: '238px',
    width: '100%',
    height: '100%',
    background: primaryColor[0],
    backgroundColor: primaryColor[0],
    borderRadius: '5px',
    padding: '2.5rem 10px 5px 10px',
    position: 'relative',
  },

  dashWrapperData: {
    minWidth: '200px',
    minHeight: '322px',
    width: '100%',
    background: primaryColor[0],
    backgroundColor: primaryColor[0],
    borderRadius: '5px',
    padding: '2.5rem 10px 5px 10px',
    position: 'relative',
  },


  dashHeader: {
    fontWeight: 700,
    lineHeight: '2rem',
    fontSize: '18px'
  },

  dashText: {
    fontSize: '14px',
    // fontWeight: 500,
    lineHeight: '18px',
    margin: '1rem 0'
  },

  dashFooter: {
    position: 'absolute', 
    bottom: '0px',
    width: '100%',
    padding: '0 17px 0 14px',
    textAlign: 'center'
  }, 

  dashFooterLast: {
    position: 'absolute', 
    marginTop: '20px',
    bottom: '28px',
    width: '100%',
    padding: '0 17px 0 14px',
    textAlign: 'center'
  }, 
  footerText: {
    fontSize: '10px',
    fontWeight: 400,
    margin: '8px 0 9px 0'
  },

  dashLine: {
   borderBottom: '2px solid #FFFFFF'
  },

  chartWrapper: {
    borderRadius: '5px',
    background: primaryColor[0],
    backgroundColor: primaryColor[0],
    color: whiteColor,
    fontFamily: 'Poppins',
    fontSize: '14px',
    padding: '15px 11px 15px 8px'
  },

  chartText: {
    color: whiteColor,
  },

  stats: {
    color: '#808080'
  }


}

export default styles1;