import React from "react";
import "./ToggleSwitch.css";
  
const SimpleToggleSwitch = ({ status,handleChange }) => {
  return (
    <label className="switchToggle">
        <input type="checkbox" id="toggleSwitchSentences" checked={status?true:false}  onChange={handleChange}/>
    <span className="sliderToggle roundToggle"></span>
  </label>
  );
};
  
export default SimpleToggleSwitch;