import {
    primaryColor,
    dangerColor,
    successColor,
    grayColor,
    whiteColor,
    blackColor,
    secondaryColor,
  } from "assets/jss/material-dashboard-react.js";
  
  const styles = {
  
    cardCategoryWhite: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    cardTitleWhite: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWight: '700',
      fontSize: '12px',
      lineHeight: '18px',
    },
  
    coreSearchWrapper: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      margin: "12px 18px 0px",
      fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif'
    },
  
    removeCardShadow: {
      boxShadow: "none"
    },
  
    coreSearchControl: {
      width: "100%",
      fontSize: 'inherit',
    },
  
    dictCardHeader: {
      margin: "20px 32px 0px",
      height: "71px"
    },
    dictCardHeaderWrapper: {
      padding: "15px 16px",
      fontSize: "12px",
      textTransform: "uppercase",
    },
  
    dictFormWrapper: {
      fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      gap: "9rem",
      marginTop: '1rem'
    },
  
    dictFormText: {
      fontSize: "12px",
      lineHeight: "15px",
      fontWeight: "400",
      color: blackColor,
      marginTop: '10px',
      width: "30%",
    },
  
    dictRadioWrapper: {
      marginLeft: '-24px',
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '15px'
    },
  
    dictRadioWrapper: {
      marginLeft: '-24px',
      marginTop: '1rem',
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '15px'
    },
  
    dictSpeechWrapper: {
      marginLeft: '15px',
      marginTop: '-1px',
      fontWeight: '400',
      fontSize: '10px',
      lineHeight: '15px'
    },
  
    gridMarginTop: {
      marginTop: '20px'
    },
  
    speechpartText: {
      fontSize: '12px'
    },
  
    wordMeaningWrapper: {
      marginTop: '-10px',
      marginLeft: '-15px'
    },
  
    sylInputText: {
      fontWeight: 400,
      fontSize: '12px'
    },
  
    rootFamWrapper: { 
      color: '#34566A',
      fontSize: '12px',
      fontWeight: 500
    },
  
    rootFamSearchText: {
      color: '#34566A',
      fontSize: '12px',
      fontWeight: 500
    },
  
    rootMeaningFilterWrapper: {
      marginTop: '-15px',
      marginLeft: '-9px',
      color: '#808080'
    },
  
    originHeaderText: {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '30px',
      textTransform: 'capitalize',
      color: '#34566A',
    },
    titleColor:{
      color: '#34566A',
      fontWeight: '600',
    },
    synonymsHeaderText: {
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '15px',
      textTransform: 'capitalize',
      color: '#34566A',
      textDecoration: 'underline'
    },
  
    synonymsSpeechText: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '15px',
      textTransform: 'capitalize',
      padding: '1.2rem 0'
    },
  
    symListWrapper: {
      ul: {
        listStyle: 'none',
  
        '& li::before': {
          content: "0",
          color: '#808080',
          fontWeight: 'bold',
          display: 'inline-block',
          width: '1em',
          marginLeft: '-1em'
        }
      }
    },
  
    storyHeaderText: {
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '15px',
      textTransform: 'capitalize',
      color: '#34566A',
      padding: '1rem'
  
    },
  
    alignButtons: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
  
    senOxfordheaderText: {
      color: '#808080',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
    },
    senBorderLine: {
      borderBottom: '1px solid #808080'
    },
    posText: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '10px',
      color: '#000000'
    },
  
    posTextStrong: {
      fontWeight: 700
    },
  
    rootRadioBtn:{
      accentColor: secondaryColor[0],
    },
    modalTitle:{
      color: '#34566A',
      display: "flex",
      justifyContent: "center",
      alignItems: 'center',
  
    }
  };
  
  export default styles;