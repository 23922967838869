import React, { Component } from 'react';
// import './App.css';
import Router from './Router.js';
class App extends Component {
  render() {
    return (
      <div className="App">
      <Router/>
      </div>
    );
  }
}
export default App;