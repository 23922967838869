import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Auth from "../../Auth.js";
import AuthService from "../../services/auth.service";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import image from "assets/img/bg7.jpg";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
const options = [
  { value: "School", label: "School" },
  { value: "Tutoring", label: "Tutoring" },
  { value: "Data", label: "Data" },
  { value: "Assessment", label: "Assessment" },
  { value: "Other", label: "Other" },
];

const useStyles = makeStyles(styles);
export default function SignUpPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [organizationType, setOrganizationType] = React.useState("");

  const handleRegister = (e) => {
    e.preventDefault();
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) === false) {
      toast.error("Email field is invalid", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (password.length < 6 || password.length > 50) {
      toast.error("Password must be between 6 and 50 characters", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (password !== confirmPassword) {
      toast.error("Your password doesn't match with the confirmed password", {
        position: toast.POSITION.TOP_CENTER,
      });
    }else if (organizationType==='') {
      toast.error("Please select the type of organization", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      // Auth.authenticate();
      AuthService.register( email, password,organizationType.value).then(
        (response) => {
          if (response.code === 200) {
            setEmail("");
            setPassword("");
            setConfirmPassword("");
            setOrganizationType("");
            toast.success(
              "You have successfully registered. We have sent an email with a confirmation link to your email address",
              { position: toast.POSITION.TOP_CENTER }
            );
          } else {
            toast.error(`${response.message}`, {
              position: toast.POSITION.TOP_CENTER,
            });
          }
          // this.setState({
          //   message: response.data.message,
          //   successful: true
          // });
        },
        (error) => {
          toast.error("Unauthorize. Please contact Admin", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      );
    }
  };

  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      {/* <Header
        absolute
        color="transparent"
        brand="Word Voyage"
        rightLinks={<HeaderLinks />}
        {...rest}
      /> */}
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          marginTop: "-150px",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>Register</h4>
                    {/* <div className={classes.socialLine}>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className={"fab fa-twitter"} />
                      </Button>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className={"fab fa-facebook"} />
                      </Button>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className={"fab fa-google-plus-g"} />
                      </Button>
                    </div> */}
                  </CardHeader>
                  <p className={classes.divider}>Explore Words!</p><br/><br/>
                  <CardBody>
                    {/* <CustomInput
                      labelText="UserName..."
                      id="first"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: username,
                        onChange: (e) => setUsername(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <People className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    /> */}
                    {/* <CustomInput
                      labelText="Organization..."
                      id="org"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: organization,
                        onChange: (e) => setOrganization(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    /> */}
                    <CustomInput
                      labelText="Email..."
                      id="org"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "email",
                        value: email,
                        onChange: (e) => setEmail(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    /><br/><br/>
                    <span >Organization Type</span>
                    <Select
                      defaultValue={organizationType}
                      onChange={setOrganizationType}
                      options={options}
                    />
                    <CustomInput
                      labelText="Password"
                      id="pass"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        value: password,
                        onChange: (e) => setPassword(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                    <CustomInput
                      labelText="Confirm Password"
                      id="confirmPass"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        value: confirmPassword,
                        onChange: (e) => setConfirmPassword(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                    
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button
                      onClick={handleRegister}
                      color="secondary"
                    >
                      Sign Up
                    </Button>
                  </CardFooter>
                  <p style={{textAlign:'center'}}><strong>Already have an account ? <Link to="/"><span className={classes.linkLogin}>Sign in</span></Link></strong></p>

                  <ToastContainer />
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        {/* <Footer whiteFont /> */}
      </div>
    </div>
  );
}
