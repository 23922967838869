// src/components/filter.table.js
import React from "react";

import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination, useSortBy } from 'react-table'
import 'bootstrap/dist/css/bootstrap.min.css';
// import { IconName } from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FaAngleDown } from '@fortawesome/free-solid-svg-icons'
// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <span>
            {/* Search:{' '} */}
            <input
                className="form-control"
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`Filter ${count} records...`}
            />
        </span>
    )
}

function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length

    return (
        <input
            className="form-control"
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            // placeholder={`Search ${count} records...`}
        />
    )
}

function Table({ columns, data }) {

    const defaultColumn = React.useMemo(
        () => ({
            // Default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: { pageIndex: 0, pageSize: 100 },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,

    )

    return (
        <div>
            <table className="table" {...getTableProps()}>
                <thead style={{position: 'sticky',  top: "0px", backgroundColor: 'white', textAlign: 'center', zIndex: "20",}}>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            <td></td>
                            {headerGroup.headers.map((column, index) => (
                                <th className={ [0, 1, 2].includes(index) ? "list-item" : "list-items"} {...column.getHeaderProps(column.getSortByToggleProps())}  style={{border: "0.5px solid gray"}}>
                                
                                <span >
                                {column.render('Header')}
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? <span style={{color: 'grey', padding: '0 5px'}}></span>
                                                : <span  style={{color: 'grey', padding: '0 5px'}}></span>
                                            : <span style={{color: 'grey', padding: '0 5px'}}></span>}
                                    </span>
                                   
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody  style={{textAlign: 'center'}} {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                            <td style={{border: "0.5px solid gray", textAlign: "center", paddingTop: "25px"}}>{i + 1}</td>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()} style={{border: "0.5px solid gray", textAlign: "center", paddingTop: "25px"}}>{cell.render('Cell')}
                                    </td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}



function ReactTable2(props) {
    return (
        <Table columns={props.columns} data={props.data} />
    )
}

export default ReactTable2;

