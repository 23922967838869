import React from 'react';
import { Link } from 'react-router-dom';
import Auth from './Auth';
import AuthService from 'services/auth.service';

class Header extends React.Component{
  constructor(props){
    super(props);
    // this.login=this.login.bind(this);
    this.logout=this.logout.bind(this);
    this.user = AuthService.getCurrentUser()
  }
  // login(){
  //   Auth.authenticate();
  // }
  logout(){
  //   Auth.signout();
    AuthService.logout();
    window.location.href='/'
    
}
  render(){
    return(
      <div
      style={{
        margin: '20px auto',
        display: 'flex',
        width: '50vw',
        justifyContent: 'space-around',
      }}>
      {
        this.user.role === 'Super Admin' ? 
        <div 
        style={{
        display: 'flex',
        width: '50vw',
        justifyContent: 'space-around'
        
      }}>
          <Link to='/admin/dashboard'>Dashboard</Link>
          <Link to='/admin/coredata'>Core Data</Link>
          <Link to='/admin/bookscan'>Bookscan</Link>
          <Link to='/admin/dataAnalysis'>Data Analysis</Link>
          {/* <Link to='/admin/users'>Users</Link> */}
          <Link to='/admin/organizations'>Organizations</Link>
        </div> :
        <div
        style={{
        display: 'flex',
        width: '30vw',
        justifyContent: 'space-around'
        
      }}
        >
          <Link to='/admin/users'>Users</Link>
          <Link to='/admin/users'>Wordlists</Link>
          <Link to='/admin/users'>Books</Link>
        </div> 
      }
        <button onClick={this.logout}>LogOut</button>
      </div>
    );
  }
}
export default Header;