import { hexToRgb, whiteColor } from "assets/jss/material-dashboard-react.js";

const customTabsStyle = {
  cardTitle: {
    float: "left",
    padding: "7px 10px 0px 10px",
    lineHeight: "24px",
  },
  cardTitleRTL: {
    float: "right",
    padding: "10px 0px 10px 10px !important",
  },
  displayNone: {
    display: "none !important",
  },
  tabsRoot: {
    minHeight: "unset !important",
    overflowX: "visible",
    "& $tabRootButton": {
      fontSize: "0.875rem",
    },
    margin: "6px 15px",
  },
  tabRootButton: {
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    fontSize: "12px",
    fontFamily: "Poppins",
    padding: "10px 15px",
    borderRadius: "3px",
    lineHeight: "24px",
    border: "0 !important",
    color: whiteColor + " !important",
    marginLeft: "4px",
    marginRight: "10px",
    fontWeight: '300',
    "&:last-child": {
      marginLeft: "0px",
    },
  },
  tabSelected: {
    backgroundColor: "rgba(" + hexToRgb(whiteColor) + ", 0.2)",
    transition: "0.2s background-color 0.1s",
  },
  tabWrapper: {
    display: "inline-block",
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    // fontWeight: "700",
    fontSize: "14px",
    marginTop: "1px",

    "& > svg,& > .material-icons": {
      verticalAlign: "middle",
      margin: "-3px 5px 0 0 !important",
      width: "1rem",
      height: "1rem"
    },
  },

  showShadow: {
    boxShadow: "none !important",
    padding: "0.5375rem 0"
  }
};

export default customTabsStyle;
