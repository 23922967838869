// /*!

// =========================================================
// * Material Dashboard React - v1.10.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/material-dashboard-react
// * Copyright 2021 Creative Tim (https://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

// * Coded by Creative Tim

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// */
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
// import RTL from "layouts/RTL.js";

// pages for this product
import Dashboard from "views/Dashboard/Dashboard.js";
import CoreData from "views/CoreData/CoreData.js"
import Users from "views/UserProfile/UserProfile.js";
import Bookscan from "views/Maps/Maps.js";
import Organizations from "views/Typography/Typography.js"
import LoginPage from "views/LoginPage/LoginPage.js";
import App from "App.js"

import "assets/css/material-dashboard-react.css?v=1.10.0";

var hist = createBrowserHistory();

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  // <Router history={hist}>
  //   <Switch>
  //     <Route path="/" component={Dashboard} exact/>
  //     <Route path="/dashboard" component={Dashboard} exact/>
  //     <Route path="/coredata" component={CoreData} exact/>
  //     <Route path="/users" component={Users} exact/>
  //     <Route path="/bookscan" component={Bookscan} exact/>
  //     <Route path="/organizations" component={Organizations} exact/>
  //     <Route path="/login-page" component={LoginPage} exact />

  //   </Switch>
  //   </Router>,
  document.getElementById("root")
);
