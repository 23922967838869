import React from "react";
import "./ToggleSwitch.css";
  
const ToggleSwitch = ({ value, status,name,handleChange }) => {
  return (
    <label className="switchToggle">
        <input type="checkbox" id="bookscanStatus" name={name} value={value} checked={status?true:false}  onChange={handleChange}/>
    <span className="sliderToggle roundToggle"></span>
  </label>
  );
};
  
export default ToggleSwitch;