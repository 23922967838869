import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Header from "../../Header";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Table from "components/Table/Table.js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import avatar from "assets/img/faces/marc.jpg";
import Select from "react-select";
import ReactTable from "components/ReactTable/ReactTable";
const currentUser = JSON.parse(localStorage.getItem("user"));

const options = [
  { value: "admin", label: "Organization admin" },
  { value: "teacher", label: "Teacher/Tutor" },
  { value: "parent", label: "Parent/Guardian" },
  { value: "student", label: "Student" },
];

const optionsTeacher = [
  { value: "teacher", label: "Teacher/Tutor" },
  { value: "parent", label: "Parent/Guardian" },
  { value: "student", label: "Student" },
];

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "12px",
    textTransform: 'uppercase',
    marginTop: "0",
    marginBottom: "0",
    marginLeft:"20px",
    fontWeight: "300",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "5px",
    minHeight: "auto",
    fontSize: "16px",
    fontWeight: "300",
    fontFamily: "'Poppins', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    marginLeft:"20px",
    textDecoration: "none",
    textTransform: 'uppercase',
paddingTop:"5px"
  },
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
  const classes = useStyles();
  const [newUserOrganization, setNewUserOrganization] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserRole, setNewUserRole] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [allStudents, setAllStudents] = useState([]);
  const [allAdmin, setAllAdmin] = useState([]);
  const [allParents, setAllParents] = useState([]);
  const [allTeachers, setAllTeachers] = useState([]);
  const [allUsers, setAllUsers] = useState(new Array(2).fill());
  const [allOrganizations, setAllOrganizations] = useState([]);
  const option = allOrganizations;

  const columns = React.useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Organization",
        accessor: "organization",
      },
      {
        Header: "Role",
        accessor: "role",
      },
    ],
    []
  );
  const handleAddNewUser = (e) => {
    e.preventDefault();
    let newUser = {
      organization:
        currentUser.role === "organization"
          ? currentUser.organization
          : currentUser.role === "admin"
          ? currentUser.organization
          : currentUser.role === "teacher"
          ? currentUser.organization
          : newUserOrganization.value,
      firstName: firstName,
      lastName: lastName,
      email: newUserEmail,
      role: newUserRole.value,
      city: city,
      country: country,
      postalCode: postalCode,
    };
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newUserEmail) ===
        false
      ) {
        toast.error("Email field is invalid", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (newUserRole === "") {
        toast.error("New user role field is invalid", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (firstName === "") {
        toast.error("First name field is invalid", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (lastName === "") {
        toast.error("Last name field is invalid", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (
        newUserOrganization === "" &&
        currentUser.role === "organization" &&
        currentUser.role === "admin" &&
        currentUser.role === "teacher"
      ) {
        toast.error("New user organization field is invalid", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        axios
          .post(
            process.env.REACT_APP_API_URL + "users/new",
            {
              newUser,
            },
            { headers }
          )
          .then((response) => {
            if (response.data.code === 200) {
              toast.success(`${response.data.message}`, {
                position: toast.POSITION.TOP_CENTER,
              });
              window.location.reload(false);
            } else {
              toast.error(`${response.data.message}`, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            toast.error("Failed to add user");
          });
      }
    }
  };

  const getAllUsers = () => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      fetch(process.env.REACT_APP_API_URL + `users/${currentUser.organization}`, { headers })
        .then((res) => res.json())
        .then((data1) => {
          let arr = [];
          let admin = [];
          let student = [];
          let parent = [];
          let teacher = [];
          let allOrga=[]
          const data=data1.data
          if (
            currentUser.role === "organization" ||
            currentUser.role === "admin" ||
            currentUser.role === "teacher"
          ) {
            for (let i=0;i<data.length;i++) {
              if (
                data[i].role == "student" &&
                data[i].organization == currentUser.organization
              ) {
                student.push({
                  email:
                    data[i].email,
                  organization: data[i].organization,
                  role: data[i].role,
                });
                arr.push({
                  email:
                   data[i].email,
                  organization: data[i].organization,
                  role: data[i].role,
                });
              } else if (
                data[i].role == "teacher"&&
                data[i].organization == currentUser.organization
              ) {
                teacher.push({
                  email:
                     data[i].email,
                  organization: data[i].organization,
                  role: data[i].role,
                });
                arr.push({
                  email: data[i].email,
                  organization: data[i].organization,
                  role: data[i].role,
                });
              } else if (
                data[i].role == "parent"&&
                data[i].organization == currentUser.organization
              ) {
                parent.push({
                  email: data[i].email,
                  organization: data[i].organization,
                  role: data[i].role,
                });
                arr.push({
                  email: data[i].email,
                  organization: data[i].organization,
                  role: data[i].role,
                });
              } else if (
               ( data[i].role == "admin" ||  data[i].role == "organization")&&
                data[i].organization == currentUser.organization
              ) {
                admin.push({
                  email:data[i].email,
                  organization: data[i].organization,
                  role: data[i].role,
                });
                arr.push({
                  email: data[i].email,
                  organization: data[i].organization,
                  role: data[i].role,
                });
              }
            }
          } else if (
            (currentUser.role === "student" ||
            currentUser.role === "parent" )
          ) {
            for (let i=0;i<data.length;i++) {
              if (
                data[i].role == "student" &&
                data[i].organization == currentUser.organization
              ) {
                student.push({
                  email: data[i].email,
                  organization: data[i].organization,
                  role: data[i].role,
                });
                arr.push({
                  email: data[i].email,
                  organization: data[i].organization,
                  role: data[i].role,
                });
              } else if (
                data[i].role == "teacher"&&
                data[i].organization == currentUser.organization
              ) {
                teacher.push({
                  email: data[i].email,
                  organization: data[i].organization,
                  role: data[i].role,
                });
                arr.push({
                  email: data[i].email,
                  organization: data[i].organization,
                  role: data[i].role,
                });
              }
            }
          } else {
            for (let i=0;i<data.length;i++) {
              if (data[i].role == "student") {
                student.push({
                  email: data[i].email,
                  organization: data[i].organization,
                  role: data[i].role,
                });
              } else if (data[i].role == "teacher") {
                teacher.push({
                  email: data[i].email,
                  organization: data[i].organization,
                  role: data[i].role,
                });
              } else if (data[i].role == "parent") {
                parent.push({
                  email: data[i].email,
                  organization: data[i].organization,
                  role: data[i].role,
                });
              } else if ((data[i].role == "admin" ||  data[i].role == "organization")) {
                admin.push({
                  email:data[i].email,
                  organization: data[i].organization,
                  role: data[i].role,
                });
                allOrga.push( { value: data[i].organization, label: data[i].organization });
              }

              arr.push({
                email: data[i].email,
                organization: data[i].organization,
                role: data[i].role,
              });
            }
          }
          setAllUsers(arr);
          setAllStudents(student);
          setAllTeachers(teacher);
          setAllAdmin(admin);
          setAllParents(parent);
          setAllOrganizations(allOrga);
        });
    }
  };
  useEffect(() => {
    getAllUsers();
    setAllOrganizations();
    setAllStudents();
    setAllTeachers();
    setAllAdmin();
    setAllParents();
  }, []);
  return (
    <div>
      {/* <Header /> */}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title=""
            headerColor="primary"
            tabs={[
              {
                tabName: "Add User",
                // tabIcon: Book,
                tabContent: (
                  <Card>
                      {currentUser.role==='student'?'':currentUser.role==='parent'?'':
                     <div>
                   <CardHeader color="primary">
                      <h4 className={classes.cardTitleWhite}>User Profile</h4>
                      <p className={classes.cardCategoryWhite}>
                        Complete user profile
                      </p>
                    </CardHeader>
                   
                    <CardBody>
            
                      <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Email address"
                            id="email-address"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: newUserEmail,
                              onChange: (e) => setNewUserEmail(e.target.value),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={1}></GridItem>
                        <GridItem xs={12} sm={12} md={5}>
                          <br />

                          {currentUser.role !== "teacher" ? (
                            <Select
                              defaultValue={newUserRole}
                              onChange={setNewUserRole}
                              options={options}
                            />
                          ) : (
                            <Select
                              defaultValue={newUserRole}
                              onChange={setNewUserRole}
                              options={optionsTeacher}
                            />
                          )}
                        </GridItem>
                        
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                          <br />
                          {currentUser.role === "organization" ? (
                            <strong>
                              Organization: {currentUser.organization}
                            </strong>
                          ) : currentUser.role === "admin" ? (
                            <strong>
                              Organization: {currentUser.organization}
                            </strong>
                          ) : currentUser.role === "teacher" ? (
                            <strong>
                              Organization: {currentUser.organization}
                            </strong>
                          ) : (
                            <Select
                              defaultValue={newUserOrganization}
                              onChange={setNewUserOrganization}
                              options={option}
                            />
                          )}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={1}></GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText="First Name"
                            id="first-name"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: firstName,
                              onChange: (e) => setFirstName(e.target.value),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={1}></GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="Last Name"
                            id="last-name"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: lastName,
                              onChange: (e) => setLastName(e.target.value),
                            }}
                          />
                        </GridItem>
                        
                      </GridContainer>
                      <br />
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText="City"
                            id="city"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: city,
                              onChange: (e) => setCity(e.target.value),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={1}></GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText="Country"
                            id="country"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: country,
                              onChange: (e) => setCountry(e.target.value),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={1}></GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="Postal Code"
                            id="postal-code"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: postalCode,
                              onChange: (e) => setPostalCode(e.target.value),
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <br />
                    </CardBody>
                    <CardFooter>
                      <Button color="secondary" onClick={handleAddNewUser}>
                        Add User
                      </Button>
                    </CardFooter>
                    <ToastContainer />
                    </div>}
                  </Card>
                ),
              },
              {
                tabName: "All Users",
                // tabIcon: Book,
                tabContent: (
                  <div>
                    <div className={classes.searchWrapper}>
                      <ReactTable columns={columns} data={allUsers} />
                    </div>
                  </div>
                ),
              },
              {
                tabName: "All Admins",
                // tabIcon: Book,
                tabContent: (
                  <div>
                    {currentUser.role === "student" ? (
                      ""
                    ) : currentUser.role === "parent" ? (
                      ""
                    ) : (
                      <div className={classes.searchWrapper}>
                        <ReactTable columns={columns} data={allAdmin} />
                      </div>
                    )}
                  </div>
                ),
              },
              {
                tabName: "All Teachers",
                // tabIcon: Book,
                tabContent: (
                  <div>
                    <div className={classes.searchWrapper}>
                      <ReactTable columns={columns} data={allTeachers} />
                    </div>
                  </div>
                ),
              },
              {
                tabName: "All Parents",
                // tabIcon: Book,
                tabContent: (
                  <div>
                                        {currentUser.role === "student" ? (
                      ""
                    ) : currentUser.role === "parent" ? (
                      ""
                    ) : (
                      <div className={classes.searchWrapper}>
                        <ReactTable columns={columns} data={allParents} />
                      </div>
                    )}
                  </div>
                ),
              },
              {
                tabName: "All Students",
                // tabIcon: Book,
                tabContent: (
                  <div>
                    <div className={classes.searchWrapper}>
                      <ReactTable columns={columns} data={allStudents} />
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
