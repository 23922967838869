import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import ReactTable2 from "components/ReactTable/ReactTable2";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import MenuList from "@material-ui/core/MenuList";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { ToastContainer, toast } from "react-toastify";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Search from "@material-ui/icons/Search";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import Header from "../../Header";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Popover from "@material-ui/core/Popover";
import BugReport from "@material-ui/icons/Home";
import Book from "@material-ui/icons/Book";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Code from "@material-ui/icons/Person";
import Slide from "@material-ui/core/Slide";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import styles1 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import { BarLoader, BeatLoader } from "react-spinners";
import { ClipLoader } from "react-spinners";
import { Checkbox, CircularProgress, DialogContentText, Radio } from "@material-ui/core";
import Select from "react-select";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import play from "../../assets/img/play.png";
import "../../App.css";
import { saveAs } from 'file-saver';
import { ArrowUpward, ArrowDownward, FileUploadOutlined, Folder } from "@mui/icons-material";

import { useSpeechSynthesis } from "react-speech-kit";
import styles from "./CoreDataStyles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Box, Button as Mbutton, Switch } from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import RadioGroup from "@mui/material/RadioGroup";
import { Radio as Mradio } from "@mui/material";
import MTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch";
import SimpleToggleSwitch from "components/ToggleSwitch/SimpleToggleSwitch";
import ReactTable from "components/ReactTable/ReactTable";
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog/DeleteConfirmationDialog";
import AddConfirmationDialog from "components/AddConfirmationDialog/AddConfirmationDialog";
import ReactTable3 from "components/ReactTable/ReactTable3";
const currentUser = JSON.parse(localStorage.getItem("user"));

import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { FileUpload, Sort, SortByAlpha, SortByAlphaOutlined, SortByAlphaTwoTone, SortOutlined, SortTwoTone } from "@mui/icons-material";


// const styles = {
//   cardCategoryWhite: {
//     color: "rgba(255,255,255,.62)",
//     margin: "0",
//     fontSize: "14px",
//     marginTop: "0",
//     marginBottom: "0",
//   },
//   cardTitleWhite: {
//     color: "#FFFFFF",
//     marginTop: "0px",
//     minHeight: "auto",
//     fontWeight: "300",
//     fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
//     marginBottom: "3px",
//     textDecoration: "none",
//   },
// };

const useStyles1 = makeStyles(styles1);
const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Dashboard() {
  const { speak } = useSpeechSynthesis();
  const [tableHeading, setTableHeading] = useState("OPTIONS");
  const [tableSentencesHeading, setTableSentencesHeading] = useState(
    "Sentences view"
  );
  const [isTableSenetences, setIsTableSenetences] = React.useState(false);
  const [allBookSentences, setAllBookSentences] = useState([]);
  const [modal, setModal] = React.useState(false);
  const [modal1, setModal1] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  const [modal3, setModal3] = React.useState(false);
  const [modal4, setModal4] = React.useState(false);
  const [word, setWord] = useState("");
  const [meaningChange, setMeaningChange] = useState(false);
  const [wordId, setWordId] = useState("");
  const [wordId2, setWordId2] = useState("");
  const [wordId3, setWordId3] = useState("");
  const [selectedPOSArrays, setSelectedPOSArrays] = useState([]);
  const [borrowingLanguage, setBorrowingLanguage] = useState([]);
  const [englishPeriodOrigins, setEnglishPeriodOrigins] = useState([]);
  const [borrowingMeaning, setBorrowingMeaning] = useState("");
  const [englishPeriodSelect, setEnglishPeriodSelect] = useState("");
  const [borrowingName, setBorrowingName] = useState("");
  const [borrowingLanguageSelect, setBorrowingLanguageSelect] = useState("");
  const [selectedPOS, setSelectedPOS] = useState("");
  const [audio, setAudio] = useState(false);
  const [audioSentence, setAudioSentence] = useState(false);
  const [wordWithoutID, setWordWithoutID] = useState(false);
  const [audio2, setAudio2] = useState(false);
  const [audio3, setAudio3] = useState(false);
  const [audioIsFound, setAudioIsFound] = useState(false);
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
  const [sortCognateList, setSortCognateList] = useState(false);

  const [sentenceAudioRead, setSentenceAudioRead] = useState("");
  const [firstLegacySentence, setFirstLegacySentence] = useState("");
  const [sentenceArr, setSentenceArr] = useState([]);
  const [wordList, setWordList] = useState("");
  const [allWordList, setAllWordList] = useState("");
  const [allLiteratureLists, setAllLiteratureLists] = useState("");
  const [literatureLists, setLiteratureLists] = useState([]);
  const [literatureListsArr, setLiteratureListsArr] = useState([]);
  const [literatureListsTableArr, setLiteratureListsTableArr] = useState([]);
  const [allRootList, setAllRootList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [allRootsObject, setAllRootsObject] = useState({});
  const [selectedRoot, setSelectedRoot] = useState("");
  const [ortherFormsWithPOS, setOrtherFormsWithPOS] = useState([]);
  const [googleDef1, setGoogleDef1] = useState("");
  const [googleDef2, setGoogleDef2] = useState("");
  const [googleDef3, setGoogleDef3] = useState("");
  const [googleDef4, setGoogleDef4] = useState("");
  const [googleWordMeaning1, setGoogleWordMeaning1] = useState("");
  const [googleWordMeaning2, setGoogleWordMeaning2] = useState("");
  const [googleWordMeaning3, setGoogleWordMeaning3] = useState("");
  const [addWordWordlist, setAddWordWorlist] = useState("");
  const [googleWordMeaning4, setGoogleWordMeaning4] = useState("");
  const [searchWordList, setSearchWordList] = useState("");
  const [searchRootList, setSearchRootList] = useState("");
  const [rootNumber, setRootNumber] = useState("");
  const [searchbyroot, setSearchByRoot] = useState("");
  const [wordSyllables, setWordSyllables] = useState([]);
  const [wordlistbyword, setWordListByWord] = useState("");
  const [wordlistbytitle, setWordListByTitle] = useState("");
  const [wordListWords, setWordListWords] = useState([]);
  const [wordlistname, setWordListName] = useState([]);
  const [file, setFile] = useState("");
  const [sentenceToUpload, setSentenceToUpload] = useState("");
  const [uploadBookauthor, setUploadBookauthor] = useState("");

  const initialQuestion = {
    question: '',
    options: ['Noun', 'Verb', 'Adjective', 'Adverb'],
    answer: '',
    word: ''
  };

  const [posExercise, setPosExercise] = useState({
    text: '',
    quiz_questions: [initialQuestion]
  });
  const [draggedItemIndex, setDraggedItemIndex] = useState(null);

  const handleDragStart = (index) => {
    setDraggedItemIndex(index);
  };

  const handleDrop = (index) => {
    if (draggedItemIndex === null || draggedItemIndex === index) return;

    const reorderedQuestions = [...posExercise.quiz_questions];
    const [removed] = reorderedQuestions.splice(draggedItemIndex, 1);
    reorderedQuestions.splice(index, 0, removed);

    setPosExercise({
      ...posExercise,
      quiz_questions: reorderedQuestions
    });
    setDraggedItemIndex(null);
  };

  const handleInputChange = (e, index, field) => {
    const newQuestions = [...posExercise.quiz_questions];
    newQuestions[index][field] = e.target.value;
    setPosExercise({
      ...posExercise,
      quiz_questions: newQuestions
    });
  };
  const [loadingPOSExercise, setLoadingPOSExercise] = useState(false);
  const [audioTtsPOSExerciseurl, setAudioTtsPOSExerciseurl] = useState('');
  const [audioTtsPOSExerciseurlSaved, setAudioTtsPOSExerciseurlSaved] = useState(false);
  const [audioTtsPOSExerciseurlSavedRead, setAudioTtsPOSExerciseurlSavedRead] = useState('');
  const [spinnerTtsPOSExercise, setSpinnerTtsPOSExercise] = useState(false);
  const [promptChatGPTPOS, setPromptChatGPTPOS] = useState("");
  // const [roots, setRoots] = useState([]);
  const [roots, setRoots] = useState([]);
  const initialLayout = roots
    ? roots.map((root, index) => ({
      i: root.root_id,
      x: 0, // Single column
      y: index * 2, // Adjust y to display vertically
      w: 1,
      h: 2,
    }))
    : [];
  const [layout, setLayout] = useState(
    initialLayout
  );
  const [rootType, setRootType] = useState("prefix");
  const [replaceRoot, setReplaceRoot] = useState({});
  const [editRoot, setEditRoot] = useState("");
  const [newEditRootMeaning, setNewEditRootMeaning] = useState("");
  const [newEditRootDescription, setNewEditRootDescription] = useState("");
  const [newRootMeaning, setNewRootMeaning] = useState("");
  const [newRootType, setNewRootType] = useState("");
  const [newRootDescription, setNewRootDescription] = useState("");
  const [editingRoot, setEditingRoot] = useState("");
  const [allRoots, setAllRoots] = useState([]);
  const [multipleMeaning, setMultipleMeaning] = useState([]);
  const [filterRootsArr, setFilterRootsArr] = useState([]);
  const [wordlistnamearr, setWordListNameArr] = useState([]);
  const [literatureWordlistnamearr, setLiteratureWordListNameArr] = useState(
    []
  );
  const [wordlistnamearrwords, setWordListNameArrWords] = useState({});
  const [wordlistnamearrheadwords, setWordListNameArrHeadWords] = useState({});
  const [wordsArrToDisplay, setWordsArrToDisplay] = useState([]);
  const [wordsObjToDisplay, setWordsObjToDisplay] = useState({});
  const [averageWordFrequency, setAverageWordFrequency] = useState(0);
  const [headWordsArrToDisplay, setHeadWordsArrToDisplay] = useState([]);
  const [rootWordsArrToDisplay, setRootWordsArrToDisplay] = useState([]);
  const [rootFamily, setRootFamily] = useState({});
  const [isEditRootFamilies, setIsEditRootFamilies] = useState(false);
  const [bookscanWordlistSpiner, setBookscanWordlistSpiner] = useState(false);
  const [showUploadList, setShowUploadList] = useState(false);

  const [literatureWordlistSpiner, setliteratureWordlistSpiner] = useState(false);
  const [bookCousinsCount, setBookCousinsCount] = useState({});
  const [isFetchingBookCousinCouns, setIsFetchingBookCousinCouns] = useState(false);
  const [
    newRootFamilyFromRootFamilies,
    setNewRootFamilyFromRootFamilies,
  ] = useState("");
  const [
    newRootMeaningFromRootFamilies,
    setNewRootMeaningFromRootFamilies,
  ] = useState("");
  const [headWord, setHeadWord] = useState("");
  const [isHeadWord, setIsHeadWord] = useState();
  const [headWordSpeechPart, setHeadWordSpeechPart] = useState("");
  const [headWordSpeechPart2, setHeadWordSpeechPart2] = useState("");
  const [headWordSpeechPart3, setHeadWordSpeechPart3] = useState("");
  const [wordFamily, setWordFamily] = useState([]);
  const [otherForms, setOtherForms] = useState([]);
  const [frequency, setFrequency] = useState("");
  const [speechPart, setSpeechPart] = useState("");
  const [firstSpeechPart, setFirstSpeechPart] = useState({});
  const [secondSpeechPart, setSecondSpeechPart] = useState("");
  const [thirdSpeechPart, setThirdSpeechPart] = useState("");
  const [googleData, setGoogleData] = useState({});
  const [sentenceArray, setSentenceArray] = useState([]);
  const [
    wordVoyageOtherFormSentences,
    setWordVoyageOtherFormSentences,
  ] = useState([]);
  const [wordVoyageSentences, setWordVoyageSentences] = useState([]);
  const [googleDataSentences, setGoogleDataSentences] = useState([]);
  const [bookSentences, setBookSentences] = useState([]);
  const [wordsObjToDisplayChecked, setWordsObjToDisplayChecked] = useState([]);
  const [allSentences, setAllSentences] = useState([]);
  const [newSentence, setNewSentence] = useState("");
  const [oldSentence, setOldSentence] = useState("");
  const [googleDataSentencesLength, setGoogleDataSentencesLength] = useState(0);
  const [authorName, setAuthorName] = useState("");
  const [wordMeaning, setWordMeaning] = useState("");
  const [wordMeaning2, setWordMeaning2] = useState("");
  const [wordMeaning3, setWordMeaning3] = useState("");
  const [syllable, setSyllable] = useState("");
  const [googleSyllable, setGoogleSyllable] = useState("");
  const [literatureWordlistWords, setLiteratureWordlistWords] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptionFunc, setSelectedOptionFunc] = useState("");
  const [wordBook, setWordBook] = useState("");
  const classes1 = useStyles1();
  let [loading, setLoading] = useState(false);
  let [loadingSentences, setLoadingSentences] = useState(false);
  const [booklist, setBooklist] = useState([]);
  const [otherFormBookList, setOtherFormBookList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [updateSentence, setUpdateSentence] = useState(false);
  const [sourceSentence, setSourceSentence] = useState("");
  const [authorNameSentence, setAuthorNameSentence] = useState("");

  const [oldAuthorName, setOldAuthorName] = useState("");
  const [openModalStoryEdit, setOpenModalStoryEdit] = useState(false);
  const [openModalSynonymsAntonyms, setOpenModalSynonymsAntonyms] = useState(
    false
  );
  const [addSynonyms, setAddSynonyms] = useState("");
  const [addAntonyms, setAddAntonyms] = useState("");
  const [wordDetail, setWordDetail] = useState("");
  const [updateStory, setUpdateStory] = useState(false);
  const [pos, setPos] = useState("");
  
  const [separateWordFamilies, setSeparateWordFamilies] = useState("");
  const [etymMeaning, setEtymMeaning] = useState("");
  const [etymMeaning2, setEtymMeaning2] = useState("");
  const [etymMeaning3, setEtymMeaning3] = useState("");
  const [wordStories, setWordStories] = useState([]);
  const [oldStory, setOldStory] = useState("");
  const [oldSource, setOldSource] = useState("");
  const [story, setStory] = useState("");
  const [source, setSource] = useState("");
  const [chapter, setChapter] = useState("");
  const [sentencePOS, setSentencePOS] = useState("");
  const [showSentencePOS, setShowSentencePOS] = useState("");
  const [wordCousins, setWordCousins] = useState([]);
  const [openCousinsDialog, setOpenCousinsDialog] = useState(false);
  //Oxford dic
  const [oxfordSenses, setOxfordSenses] = useState([]);
  const [oxfordSynonyms, setOxfordSynonyms] = useState([]);
  const [oxfordSentences, setOxfordSentences] = useState([]);
  const [oxfordPronunciation, setOxfordPronunciation] = useState("");
  const [oxfordEtymologies, setOxfordEtymologies] = useState("");
  const [englishPeriod, setEnglishPeriod] = useState({});
  const [borrowing, setBorrowing] = useState({});
  const [oxfordAntonyms, setOxfordAntonyms] = useState([]);
  const [bookScanLists, setBookScanLists] = useState([]);
  const [wordlistbyBookName, setWordlistByBookName] = useState("");
  const [allBookScanLists, setAllBookScanLists] = useState([]);
  const [bookScanListsArr, setBookScanListsArr] = useState([]);
  const [bookScanListsTableArr, setBookScanListsTableArr] = useState([]);
  const [ascendingBookscanSort, setAscendingBookscanSort] = useState(true);
  const [bookScanListsTableArrSaved, setBookScanListsTableArrSaved] = useState([]);

  const [radioSelectDictionary, setradioSelectDictionary] = useState("oxford");
  //end
  const [selectNumberOfWordDetail, setSelectNumberOfWordDetail] = useState(0);
  const [openModalStudyList, setOpenModalStudyList] = useState(false);
  const [studyListWordArr, setStudyListWordArr] = useState([]);
  const [globalStudyListWordArr, setGlobalStudyListWordArr] = useState([]);
  const [publishedData, setPublishedData] = useState({});
  const [studyListName, setStudyListName] = useState("");
  const [bookscanTitle, setBookscanTitle] = useState("");
  const [name, setName] = useState("");
  const [isStudyList, setIsStudyList] = useState(false);
  const [openModalWordList, setOpenModalWordList] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [openRootFamilyStory, setOpenRootFamilyStory] = useState(false);
  const [openDeleteModalRoot, setOpenDeleteModalRoot] = useState(false);
  const [openAddWordModalCognateWordlist, setOpenAddWordModalCognateWordlist] = useState(false);
  const [openAddWordModalLegacyWordlist, setOpenAddWordModalLegacyWordlist] = useState(false);
  const [openAddWordModalBookscanWordlist, setOpenAddWordModalBookscanWordlist] = useState(false);
  const [selectedItemToDelete, setSelectedItemToDelete] = useState("");
  const [selectedItemToAdd, setSelectedItemAdd] = useState("");
  const [itemToAddWordModal, setItemToAddWordModal] = useState({});
  const [newRootFamilyStory, setNewRootFamilyStory] = useState("");
  const [rootFamilyStory, setrootFamilyStory] = useState([]);
  const [legacyLiteratureStatus, setLegacyLiteratureStatus] = useState(0);
  const [
    legacyLiteratureWord_list_id,
    setLegacyLiteratureWord_list_id,
  ] = useState("");
  const [legacyLiteraturetitle, setLegacyLiteraturetitle] = useState("");
  const [addWordLegacyWordlist, setAddWordLegacyWorlist] = useState("");
  const [fetchOtherFormsSentences, setFetchOtherFormsSentences] = useState(
    false
  );
  const [addWordDialog, setAddWordDialog] = useState(false);
  const [newWord, setNewWord] = useState("");
  const [newFrequency, setNewFrequency] = useState("");
  const [newSyllables, setNewSylables] = useState("");
  const [newDefinition, setNewDefinition] = useState("");
  const [isNewWordToform, setIsNewWordToform] = useState(false);
  const [macroRootsReport, setMacroRootsReport] = useState([]);
  const [loadMacroReport, setLoadMacroReport] = useState(false);
  const [filteredBookscanLists, setFilteredBookscanLists] = useState([]);

  const [newLessonWordlistName, setNewLessonWordlistName] = useState("");
  const [newLessonWordlistTitle, setNewLessonWordlistTitle] = useState("");
  const [newLessonWordlistAuthor, setNewLessonWordlistAuthor] = useState("");
  const [openModalModifyLessonWordlist, setOpenModalModifyLessonWordlist] = useState(false);

  // Part to remove word in a specific wordlist
  const initialWordlistState = wordsObjToDisplayChecked.reduce(
    (o, key) => ({ ...o, [key]: false }),
    {}
  );
  const [audioWordSpiner, setAudioWordSpiner] = useState(false);
  const [voiceName, setVoiceName] = useState({ label: 'American', value: 'en-US' });
  const [audioStatus, setAudioStatus] = useState({});
  const fileInputRef = useRef(null);
  const handleFileBtnClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (e) => {
    const bookfile = e.target.files && e.target.files[0];
    if (!bookfile) {
      return toast.error("Please add a book file", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    // else if(!(bookfile.includes(".txt"))){
    //   return toast.error("The file must be in .txt format", {
    //     position: toast.POSITION.TOP_CENTER,
    //   });
    // } 
    setFile(e.target.files[0]);
  };

  const handleFileUpload = (e) => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      e.preventDefault();
      if (file === "") {
        return toast.error("Please add a file", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        let myformData = new FormData();
        myformData.append("book", file);
        myformData.append("uploadBookauthor", uploadBookauthor);
        // myformData.append("bookNumber", bookNumber);
        // myformData.append("startPage", startPage);
        // myformData.append("endPage", endPage);
        // myformData.append("bookPriority", bookPriority);
        let bookinfo = {};
        for (var [key, value] of myformData.entries()) {
          bookinfo[key] = value;
        }
        axios
          .post(process.env.REACT_APP_API_URL + "adminDashboard/upload-file", myformData, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              toast.success("Successfully uploaded", {
                position: toast.POSITION.TOP_CENTER,
              });
              setUploadBookauthor("");
              window.location.reload(false);
            } else {
              return toast.error(response.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }

          })
          .catch((error) => {
            if (file === "") {
              toast.error("please add a book file");
            } else {
              toast.error("upload fail");
            }
          });
      }
      setFile("");
    }
  };

  //   if (new Date() > new Date(currentUser.expiresInTime)) {
  //     toast.error("Unauthorized User. Please log in", {
  //       position: toast.POSITION.TOP_CENTER,
  //     });
  //     localStorage.removeItem("user");
  //     window.location.href = "/";
  //   } else if (currentUser.role !== "Super Admin") {
  //     toast.error("Unauthorized User. Only the super admin has access", {
  //       position: toast.POSITION.TOP_CENTER,
  //     });
  //   } else {
  //     const headers = {
  //       Authorization: "Bearer " + currentUser.accessToken,
  //     };

  //     const formData = new FormData();
  //     formData.append("file", selectedFile); // Assuming `selectedFile` contains the file to upload

  //     axios
  //       .post(
  //         `${process.env.REACT_APP_API_URL}adminDashboard/upload-file`,
  //         formData,
  //         { headers }
  //       )
  //       .then((res) => {
  //         if (res.data.code !== 200) {
  //           return toast.error(res.data.message, {
  //             position: toast.POSITION.TOP_CENTER,
  //           });
  //         } else {
  //           const data = res.data;
  //           setFile(null); // Clear the file input
  //           return toast.success(data.message, {
  //             position: toast.POSITION.TOP_CENTER,
  //           });
  //         }
  //       })
  //       .catch(() => {
  //         toast.error("Something went wrong. Please try again.", {
  //           position: toast.POSITION.TOP_CENTER,
  //         });
  //       });
  //   }

  // };


  const addIDToWordFunction = () => {
    if (word == "") {
      return toast.error("Please fill out a word field.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };

        axios
          .post(
            process.env.REACT_APP_API_URL + "adminDashboard/add-word-id",
            { word },
            {
              headers,
            }
          )
          .then((res) => {
            if (res.data.code !== 200) {
              return toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
              window.location.reload(false);

            }
          })
          .catch((error) => {
            toast.error("Something went wrong. Please try again.", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      }
    }

  }
  const addNewWord = () => {
    if (isNewWordToform == false) {
      if (newWord == "" || newFrequency == "" || newSyllables == "" || newDefinition == "") {
        return toast.error("Please fill out all required fields.", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (!/^\d+$/.test(newFrequency)) {
        return toast.error("Frequency must contain only numbers.", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        if (new Date() > new Date(currentUser.expiresInTime)) {
          toast.error("Unautorized User. Please log In", {
            position: toast.POSITION.TOP_CENTER,
          });
          localStorage.removeItem("user");
          window.location.href = "/";
        } else if (currentUser.role !== "Super Admin") {
          toast.error("Unautorized User. Only the super admin has access", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + currentUser.accessToken,
          };

          axios
            .post(
              process.env.REACT_APP_API_URL + "adminDashboard/add-new-word",
              { newWord, newFrequency, newSyllables, newDefinition },
              {
                headers,
              }
            )
            .then((res) => {
              if (res.data.code !== 200) {
                return toast.error(res.data.message, {
                  position: toast.POSITION.TOP_CENTER,
                });
              } else {
                const data = res.data
                setAddWordDialog(false);
                setNewDefinition("");
                setNewWord("");
                setNewFrequency("");
                setNewSylables("");
                return toast.success(data.message, {
                  position: toast.POSITION.TOP_CENTER,
                });
              }
            })
            .catch((error) => {
              toast.error("Something went wrong. Please try again.", {
                position: toast.POSITION.TOP_CENTER,
              });
            });
        }
      }
    } else {
      if (newWord == "") {
        return toast.error("Please add a word", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (word == "") {
        return toast.error("Please add a headword", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        if (new Date() > new Date(currentUser.expiresInTime)) {
          toast.error("Unautorized User. Please log In", {
            position: toast.POSITION.TOP_CENTER,
          });
          localStorage.removeItem("user");
          window.location.href = "/";
        } else if (currentUser.role !== "Super Admin") {
          toast.error("Unautorized User. Only the super admin has access", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + currentUser.accessToken,
          };
          const headword = word;
          axios
            .post(
              process.env.REACT_APP_API_URL + "adminDashboard/add-word-to-form",
              { newWord, headword },
              {
                headers,
              }
            )
            .then((res) => {
              if (res.data.code !== 200) {
                return toast.error(res.data.message, {
                  position: toast.POSITION.TOP_CENTER,
                });
              } else {
                const data = res.data
                setWordFamily(wordFamily.concat([{ word: newWord }]))
                setAddWordDialog(false);
                setNewWord("");
                return toast.success(data.message, {
                  position: toast.POSITION.TOP_CENTER,
                });
              }
            })
            .catch((error) => {
              toast.error("Something went wrong. Please try again.", {
                position: toast.POSITION.TOP_CENTER,
              });
            });
        }
      }
    }
  }
  const updateWordOrigins = (e) => {
    if (word === "") {
      return toast.error("Please enter a word", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (englishPeriodSelect == "" || borrowingLanguageSelect == "" || borrowingName?.trim() == "" || borrowingMeaning?.trim() == "") {
      return toast.error("Please fill out all required fields.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };
        let englishPeriodSelectDescription1 = englishPeriodOrigins.filter(el => el.value == englishPeriodSelect);
        let englishPeriodSelectDescription = englishPeriodSelectDescription1[0].description;
        let englishPeriodSelectName = englishPeriodSelectDescription1[0].label;

        let borrowingLanguageSelectName1 = borrowingLanguage.filter(el => el.value == borrowingLanguageSelect);
        let borrowingLanguageSelectName = borrowingLanguageSelectName1[0].label;
        axios
          .post(
            process.env.REACT_APP_API_URL + "adminDashboard/update-word-origins/" + word,
            { englishPeriodSelect, borrowingLanguageSelect, borrowingName, borrowingMeaning, englishPeriodSelectName, englishPeriodSelectDescription, borrowingLanguageSelectName },
            {
              headers,
            }
          )
          .then((res) => {
            if (res.data.code !== 200) {
              return toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              const data = res.data
              // setEnglishPeriod(data.english_period);
              // setBorrowing(data.borrowing);
              // setBorrowingMeaning(data.borrowing?.borrowingMeaning);
              // setBorrowingName(data.borrowing?.borrowingName);
              // let filter1 = borrowingLanguage.filter(el => el.label?.toLowerCase() == data.borrowing?.borrowingLanguage?.toLowerCase());
              // if (filter1.length > 0) {
              //   setBorrowingLanguageSelect(filter1[0].value)
              // }
              // let filter2 = englishPeriodOrigins.filter(el => el.label?.toLowerCase() == data.english_period?.name?.toLowerCase());
              // if (filter2.length > 0) {
              //   setEnglishPeriodSelect(filter2[0].value)
              // }
              return toast.success(data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          })
          .catch((error) => {
            toast.error("Something went wrong. Please try again.", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      }
    }
  }
  const loadAudio = (word, word_id) => {
    var au = document.createElement("audio");
    au.src = `${process.env.REACT_APP_S3_BASE_URL}${word[0]}/${word}_${word_id}.mp3`;
    au.addEventListener(
      "loadedmetadata",
      function () {
        setAudioStatus((prevStatus) => ({
          ...prevStatus,
          [word]: true,
        }));
      },
      false
    );

    au.addEventListener(
      "error",
      function () {
        setAudioStatus((prevStatus) => ({
          ...prevStatus,
          [word]: false,
        }));
      },
      false
    );

    au.load(); // This triggers the loading of the audio

    var au_sentence = document.createElement("audio");
    au_sentence.src = `${process.env.REACT_APP_S3_BASE_URL}${word[0]}/${word}_s_${word_id}.mp3`;

    au_sentence.addEventListener(
      "loadedmetadata",
      function () {
        setAudioStatus((prevStatus) => ({
          ...prevStatus,
          [word + "_s"]: true,
        }));
      },
      false
    );

    au_sentence.addEventListener(
      "error",
      function () {
        setAudioStatus((prevStatus) => ({
          ...prevStatus,
          [word + "_s"]: false,
        }));
      },
      false
    );

    au_sentence.load(); // This triggers the loading of the audio

    // Optionally, you can play the audio immediately
    // au.play();

    // Optionally, you can clean up the audio element when the component unmounts
    // return () => {
    //   au.remove();
    // };
  };
  // const handleLoadAllAudio = () => {
  //   literatureListsTableArr.forEach((word) => {
  //     loadAudio(word);
  //   });
  // };
  const handleRootFamiliesSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);

    if (searchTerm.trim() === '') {
      setFilteredData([]);
    } else {
      const filteredData = allRootList.filter((item) =>
        item.meaning.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filteredData);
    }
  };
  const [checkedWordlist, setCheckedWordlist] = useState(initialWordlistState);
  const toggleCheckWordlist = (inputName) => {
    setCheckedWordlist((prevState) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };
  const handleSearchBookscanListChange = (event) => {
    setBookscanTitle(event.target.value);
    // Filter the sentences based on the search term (case-sensitive)
    const filteredBookscanLists = allBookScanLists.filter((sentence) => {
      const regex = new RegExp(event.target.value, 'i');
      return regex.test(sentence);
    });
    setFilteredBookscanLists(filteredBookscanLists)
  };
  // const handleSearchBookscanListChange = (event) => {
  //   setSearchTerm(event.target.value);
  //     const filteredBookscanLists = allBookScanLists.filter((sentence) =>
  //   sentence.includes(event.target.value)
  // );
  // setFilteredBookscanLists(filteredBookscanLists)
  // };

  const voiceOptions = [
    { label: 'American', value: 'en-US' },
    { label: 'British', value: 'en-GB' },
    { label: 'Canada', value: 'en-CA' },
    { label: 'Australia', value: 'en-AU' },
    { label: 'New Zealand', value: 'en-NZ' },
    // Add more options as needed
  ];
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: 200, // Set your desired width here
      marginLeft: 20
    }),
  };


  const [audioBlob, setAudioBlob] = useState(null);
  const [audioUrl, setAudioUrl] = useState('');
  const [audioSentenceUrl, setAudioSentenceUrl] = useState('');
  const handleMoveQuestion = (index, direction) => {
    const newQuestions = [...posExercise.quiz_questions];
    const [movedQuestion] = newQuestions.splice(index, 1);
    newQuestions.splice(index + direction, 0, movedQuestion);
    setPosExercise({
      ...posExercise,
      quiz_questions: newQuestions
    });
  };
  const handleInputChangePosExercise = (e, index, field) => {
    const newPosExercise = { ...posExercise };
    newPosExercise.quiz_questions[index][field] = e.target.value;
    setPosExercise(newPosExercise);
  };

  const handleOptionChangePosExercise = (e, questionIndex, optionIndex) => {
    const newPosExercise = { ...posExercise };
    newPosExercise.quiz_questions[questionIndex].options[optionIndex] = e.target.value;
    setPosExercise(newPosExercise);
  };

  const handleAddQuestionPosExercise = () => {
    if (posExercise.quiz_questions.length < 10) {
      setPosExercise({
        ...posExercise,
        quiz_questions: [...posExercise.quiz_questions, initialQuestion]
      });
    }
  };

  const handleDeleteQuestionPosExercise = (index) => {
    if (posExercise.quiz_questions.length > 1) {
      const newPosExercise = { ...posExercise };
      newPosExercise.quiz_questions.splice(index, 1);
      setPosExercise(newPosExercise);
    }
  };

  const handleOptionClickPosExercise = (questionIndex, optionValue) => {
    const newPosExercise = { ...posExercise };
    newPosExercise.quiz_questions[questionIndex].answer = optionValue;
    setPosExercise(newPosExercise);
  };

  const handleSavePosExercise = async () => {
    const invalidQuestion = posExercise.quiz_questions.find(question => (
      !question.question.trim() ||
      !question.word.trim() ||
      !question.answer.trim() ||
      question.options.length !== 4 ||
      !question.options.every(option => option.trim())
    ));

    if (!posExercise.text.trim()) {
      return toast.error("Output text cannot be empty.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    if (invalidQuestion) {
      return toast.error("Please ensure all questions have a question, an answer, and exactly 4 options filled.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unauthorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unauthorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      if (word === "") {
        return toast.error("Please enter a word", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      if (posExercise.text.trim() === '' || posExercise.quiz_questions.length === 0) {
        return toast.error("Please ensure there is at least one question and the output is filled out.", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {


        axios
          .post(
            process.env.REACT_APP_API_URL + "save-quiz-pos-exercise",
            { posExercise, word },
            { headers }
          )
          .then((res) => {
            if (res.data.code !== 200) {
              return toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              return toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          })
          .catch((error) => {
            toast.error("Something went wrong. Please try again.", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      }
    }
  };

  const uploadTTSForPOSExercise = () => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unauthorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unauthorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      if (word === "") {
        return toast.error("Please enter a word", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (wordId === "") {
        setWordWithoutID(true);
        return toast.error("This word doesn't have ID", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      else {
        axios
          .post(
            process.env.REACT_APP_API_URL + "adminDashboard/upload-tts-audio-pos-exercise",
            { wordId, word },
            { headers }
          )
          .then((res) => {
            if (res.data.code !== 200) {
              return toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              setAudioTtsPOSExerciseurlSaved(true);
              return toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          })
          .catch((error) => {
            toast.error("Something went wrong. Please try again.", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      }
    }
  }
  const handleGenerateQuestions = () => {
    if (!posExercise.text.trim()) {
      return toast.error("Please enter the output text.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (word === "") {
      return toast.error("Please enter a word.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    setLoadingPOSExercise(true);

    try {
      const outputText = posExercise.text;

      // Function to merge word and array of objects into a single array
      function mergeWordAndArray(word, arrayOfObjects) {
        // Extract words from array of objects
        let wordsArray = arrayOfObjects.map(obj => obj.word);

        // Add the single word to the array
        wordsArray.unshift(word);

        return wordsArray;
      }

      // Call the function and store the result
      let mergedArray = mergeWordAndArray(word, wordFamily);

      const wordsToGenerate = wordFamily && wordFamily.length > 0 ? mergedArray : [word];
      const newQuestions = [];

      wordsToGenerate.forEach((word) => {
        const regex = new RegExp(`\\b[^.]*\\b${word}\\b[^.]*\\.?`, 'gi');
        let match;
        while ((match = regex.exec(outputText)) !== null) {
          const sentence = match[0];
          newQuestions.push({
            // question: `What part of speech is "${word}" in the sentence: ${sentence.trim()}?`,
            question: sentence.trim(),
            options: ['Noun', 'Verb', 'Adjective', 'Adverb'],
            answer: '',
            word: word
          });
        }
      });

      setPosExercise({
        ...posExercise,
        quiz_questions: newQuestions.length ? newQuestions : [initialQuestion] // If no questions generated, retain the initial question
      });
    } catch (error) {
      console.error('There was a problem generating questions:', error);
    } finally {
      setLoadingPOSExercise(false);
    }
  };

  const getChatGPTPOS = () => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      if (promptChatGPTPOS === "") {
        return toast.error("Please enter the text", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        let prompt = promptChatGPTPOS
        axios
          .post(
            process.env.REACT_APP_API_URL + "chatgpt-quiz-pos",
            { prompt },
            {
              headers,
            }
          )
          .then((res) => {
            if (res.data.code !== 200) {
              return toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              console.log(res.data.data);
              setChatGPTPOS(res.data.data)
              return toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          })
          .catch((error) => {
            toast.error("Something went wrong. Please try again.", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      }
    }
  }

  const uploadTTSAudio = (isWord) => {
    setAudioUrl('')
    setAudioSentenceUrl('')
    // 1 for word, 2 for sentence
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      if (word === "") {
        return toast.error("Please enter a word", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (wordId === "") {
        setWordWithoutID(true);
        return toast.error("This word doesn't have ID", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        let location = isWord == 1 ? `${word}_${wordId}` : `${word}_s_${wordId}`
        let first_letter = word[0];
        axios
          .post(
            process.env.REACT_APP_API_URL + "adminDashboard/upload-tts-audio",
            { location, first_letter },
            {
              headers,
            }
          )
          .then((res) => {
            if (res.data.code !== 201) {
              return toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              if (isWord == 1) {
                setAudioStatus((prevStatus) => ({
                  ...prevStatus,
                  [word]: true,
                }));

              } else {
                setAudioStatus((prevStatus) => ({
                  ...prevStatus,
                  [word + "_s"]: true,
                }));
                // localStorage.setItem("wordParams", JSON.stringify(word));
                // window.location.reload(false);
                fetchwordDetail(3, word);
              }
              // loadAudio(word, wordId)
              return toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          })
          .catch((error) => {
            toast.error("Something went wrong. Please try again.", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      }
    }
  }
  const speakTextPOSExercise = (a) => {
    setAudioTtsPOSExerciseurl('');
    setSpinnerTtsPOSExercise(true);
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      if (word === "") {
        return toast.error("Please enter a word", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (wordId === "") {
        setWordWithoutID(true);
        return toast.error("This word doesn't have ID", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        let language = voiceName.value
        let textToSpeak = posExercise.text;
        let location = `${word}_pos_exercise_${wordId}`
        axios
          .post(
            process.env.REACT_APP_API_URL + "adminDashboard/synthesize-speech-pos-exercise",
            { language, textToSpeak, location },
            {
              headers,
            }
          )
          .then((res) => {
            if (res.data.code !== 200) {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              setAudioTtsPOSExerciseurl(res.data.dataURI);
              const audioElement = document.getElementById("playaudioTtsPOSExercise");
              if (audioElement) {
                audioElement.play();
              } else {
                console.error("Audio element not found");
              }
              // setSentenceToUpload(text);
            }
            setSpinnerTtsPOSExercise(false);
          })
          .catch((error) => {
            setSpinnerTtsPOSExercise(false);
            console.log(error);
            toast.error("Something went wrong. Please try again.", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      }
    }
  }
  const speakTextWord = (text, wordSentence) => {
    setAudioWordSpiner(true)
    // 1 for word, 2 for sentence
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      if (word === "") {
        return toast.error("Please enter a word", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (wordId === "") {
        setWordWithoutID(true);
        return toast.error("This word doesn't have ID", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        let language = voiceName.value
        let textToSpeak = text;
        let location = wordSentence == 1 ? `${word}_${wordId}` : `${word}_s_${wordId}`
        axios
          .post(
            process.env.REACT_APP_API_URL + "adminDashboard/synthesize-speech",
            { language, textToSpeak, location },
            {
              headers,
            }
          )
          .then((res) => {
            if (res.data.code !== 200) {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              if (wordSentence == 1) {
                setAudioUrl(res.data.dataURI);
                document.getElementById("playaudioTts").play();
              } else {
                setAudioSentenceUrl(res.data.dataURI);
                document.getElementById("playaudioTtsSentence").play();
                setSentenceToUpload(text);
              }


            }
            setAudioWordSpiner(false)
          })
          .catch((error) => {
            setAudioWordSpiner(false)
            toast.error("Something went wrong. Please try again.", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      }
    }
  }
  const speakText = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = voiceName.value;
    window.speechSynthesis.speak(utterance);
  }


  const changeHeadword = (headWord, newHeadword) => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      if (newHeadword === "") {
        return toast.error("Pleae select a new headword", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        axios
          .post(
            process.env.REACT_APP_API_URL + "admin/change-headword/" + word,
            { headWord, newHeadword },
            {
              headers,
            }
          )
          .then((res) => {
            if (res.data.code !== 204) {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              let data = res.data.data;
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
              if (data.allOtherForms) {
                setWordFamily(data.allOtherForms);
                let arrays = []
                data.allOtherForms.forEach(element => {
                  arrays.push({ value: element.word, label: element.word })
                });
                setOtherForms(arrays);
              }
              setHeadWord(data.headWord);
              setHeadWordSpeechPart(data.headWordSpeechPart)
              setHeadWordSpeechPart2(data.headWordSpeechPart2)
              setHeadWordSpeechPart3(data.headWordSpeechPart3)
            }
          })
          .catch((error) => {
            toast.error("Something went wrong. Please try again.", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      }
    }
  }
  // Function to handle radio input change
  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleMouseEnter = (e) => {
    e.target.style.color = "#ff9100";
  };

  const handleMouseLeave = (e) => {
    e.target.style.color = "#000";
  };
  //  allBookSentences
  let allBookSentencesData = [];
  allBookSentences.map((element, index) => {
    let subhash = {};
    if (fetchOtherFormsSentences == true) {
      subhash["headword"] =
        element?.isHeadWord != undefined && element?.isHeadWord == true
          ? "✓"
          : "";
      subhash["word"] = element?.word;
      subhash["title"] = element?.title;
      subhash["sentence"] = element?.sentence;
      subhash["select"] = (
        <input
          type="checkbox"
          className="selectedCheckboxBookSentences"
          value={JSON.stringify(element)}
        />
      );
      allBookSentencesData.push(subhash);
    } else {
      if (element.exactWord !== undefined && element.exactWord == true) {
        subhash["headword"] =
          element?.isHeadWord != undefined && element?.isHeadWord == true
            ? "✓"
            : "";
        subhash["word"] = element?.word;
        subhash["title"] = element?.title;
        subhash["sentence"] = element?.sentence;
        subhash["select"] = (
          <input
            type="checkbox"
            className="selectedCheckboxBookSentences"
            value={JSON.stringify(element)}
          />
        );
        allBookSentencesData.push(subhash);
      }
    }
  });
  const addAllBookSentences = (e) => {
    var selectedCheckboxBookSentences = [];
    var inputElements = document.getElementsByClassName(
      "selectedCheckboxBookSentences"
    );
    for (var i = 0; inputElements[i]; ++i) {
      if (inputElements[i].checked) {
        selectedCheckboxBookSentences.push(JSON.parse(inputElements[i].value));
      }
    }

    if (selectedCheckboxBookSentences.length == 0) {
      return toast.error("Please select sentences", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (word == "") {
      return toast.error("Please select a word", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };
        axios
          .post(
            process.env.REACT_APP_API_URL + "admin/add-allBooks-sentences-words/" + word,
            { selectedCheckboxBookSentences },
            {
              headers,
            }
          )
          .then((res) => {
            if (res.data.code !== 200) {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              for (var i = 0; inputElements[i]; ++i) {
                inputElements[i].checked = false;
              }
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
              setWordVoyageSentences(res.data.data.acceptedSentences);
              setWordVoyageOtherFormSentences(res.data.data.otherFormSentences);
              setAudioSentenceUrl('');
              // Update audioStatus state by creating a new object
              setAudioStatus(prevState => ({
                ...prevState, // Spread the previous state
                [word + "_s"]: false // Update the specific key to false
              }));
            }
          })
          .catch((error) => {
            toast.error("Something went wrong. Please try again.", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      }
    }
  };
  const columnsAllBookSentencesData = React.useMemo(
    () => [
      {
        Header: "Headword",
        accessor: "headword",
      },
      {
        Header: "Word",
        accessor: "word",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Sentences",
        accessor: "sentence",
      },
      {
        Header: "Select",
        accessor: "select",
      },
    ],
    []
  );
  const columns = React.useMemo(
    () => [
      {
        Header: "Root Spelling",
        accessor: "root_spelling",
      },
      {
        Header: "Root Meaning",
        accessor: "root_meaning",
      },
      {
        Header: "#Headwords",
        accessor: "numberOfHeadwords",
      },
      {
        Header: "Avg Freq Of Headords",
        accessor: "averageFrequencyHeadwords",
      },
      {
        Header: "#Words",
        accessor: "numberOfWords",
      },
    ],
    []
  );
  const getMacroRootsReport = (newData) => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      setLoadMacroReport(true);
      fetch(
        process.env.REACT_APP_API_URL + "create_macro_roots_report/" + newData,
        {
          headers,
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setMacroRootsReport(data.data);
          setLoadMacroReport(false);
        })
        .catch((error) => {
          setLoadMacroReport(false);
          toast.error("Something went wrong. Please try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  const SaveRootFamilyStory = (e) => {
    e.preventDefault();
    if (newRootFamilyStory == "") {
      return toast.error("Root family story can't be empty", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (wordlistname.length == 0) {
      return toast.error("Wordlist name can't be empty", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };
        axios
          .post(
            process.env.REACT_APP_API_URL +
            "save-root-familyStory/" +
            wordlistname,
            { newRootFamilyStory },
            {
              headers,
            }
          )
          .then((res) => {
            if (res.data.code !== 201) {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
            setOpenRootFamilyStory(false);
            setNewRootFamilyStory(res.data.data);
          })
          .catch((error) => {
            toast.error("Something went wrong. Please try again", {
              position: toast.POSITION.TOP_CENTER,
            });
            setOpenRootFamilyStory(false);
          });
      }
    }
  };

  const updateRootFromRootFamilies = (e) => {
    e.preventDefault();
    toast.info("Processing", {
      position: toast.POSITION.TOP_CENTER,
    });
    setIsEditRootFamilies(false);
    if (newRootFamilyFromRootFamilies == "") {
      return toast.error("Root family can't be empty", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (newRootMeaningFromRootFamilies == "") {
      return toast.error("Root meaning can't be empty", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };
        const data = {
          newRootFamilyFromRootFamilies,
          newRootMeaningFromRootFamilies,
        };
        axios
          .patch(
            process.env.REACT_APP_API_URL +
            "update/root_from_root_families/" +
            rootFamily.meaning,
            { data, rootFamily },
            {
              headers,
            }
          )
          .then((res) => {
            if (res.data.code !== 204) {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
              fetchRootsAndRootDescription();
              fetchAllWordlists();
              setRootFamily({});
              setRootWordsArrToDisplay([]);
            }
            setIsEditRootFamilies(false);
          })
          .catch((error) => {
            toast.error("Something went wrong. Please try again", {
              position: toast.POSITION.TOP_CENTER,
            });
            setIsEditRootFamilies(false);
          });
      }
    }
  };
  const AddSynonymsAntonyms = (e) => {
    if (addAntonyms === "" && addSynonyms === "") {
      return toast.error("Please add synonyms or antonyms.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (word === "") {
      return toast.error("Please enter the word.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };

        axios
          .post(
            process.env.REACT_APP_API_URL + "add-synonyms-antonyms/" + word,
            { synonyms: addSynonyms, antonyms: addAntonyms },
            {
              headers,
            }
          )
          .then((res) => {
            if (res.data.code !== 200) {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
              setOpenModalSynonymsAntonyms(false);
            } else {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
              setAddSynonyms("");
              setAddAntonyms("");
              setPublishedData(res.data.data);
              setOpenModalSynonymsAntonyms(false);
            }
          })
          .catch((error) => {
            toast.error("Something went wrong. Please try again", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      }
    }
  };
  const saveSynonymsAndAntonyms = (e) => {
    var synonyms = [];
    var inputElements = document.getElementsByClassName("synonymsCheckbox");
    for (var i = 0; inputElements[i]; ++i) {
      if (inputElements[i].checked) {
        synonyms.push(inputElements[i].value);
      }
    }

    var antonyms = [];
    var inputElements1 = document.getElementsByClassName("antonymsCheckbox");
    for (var i = 0; inputElements1[i]; ++i) {
      if (inputElements1[i].checked) {
        antonyms.push(inputElements1[i].value);
      }
    }
    if (word === "") {
      return toast.error("Please enter the word.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (antonyms.length === 0 && synonyms.length === 0) {
      return toast.error("Please select a word.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };
        axios
          .post(
            process.env.REACT_APP_API_URL + "save-synonyms-antonyms/" + word,
            { synonyms, antonyms },
            {
              headers,
            }
          )
          .then((res) => {
            if (res.data.code !== 200) {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });

              setPublishedData(res.data.data);
            }
          })
          .catch((error) => {
            toast.error("Something went wrong. Please try again", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      }
    }
  };

  const addWordBookScanList = (e) => {
    if (addWordWordlist === "") {
      return toast.error("Please enter the word.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (bookScanListsArr.length === 0) {
      return toast.error("Please enter the title.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      let words = [];
      let inputElements = document.getElementsByClassName("studyListCheckbox");
      for (let i = 0; inputElements[i]; ++i) {
        if (inputElements[i].checked) {
          words.push(inputElements[i].value);
        }
      }
      const lastWordChecked = words[words.length - 1];
      const title = bookScanListsArr[0][0];
      const word = addWordWordlist;
      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };
        axios
          .post(
            process.env.REACT_APP_API_URL + "add-bookscan-wordlists/" + title,
            { word, lastWordChecked },
            {
              headers,
            }
          )
          .then((res) => {
            if (res.data.code == 200) {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
              handleSubmitBookScanWordlistByTitle(e, title);
            } else if (res.data.code == 404) {
              setOpenAddWordModalBookscanWordlist(true);
              setSelectedItemAdd("book_scan_lists")
              const data = {
                wordlist: title,
                word
              }
              setItemToAddWordModal(data)
            } else {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          })
          .catch((error) => {
            toast.error("Something went wrong. Please try again", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      }
    }
  };

  const removeBookScanList = (e) => {
    var wordlist = [];
    var inputElements = document.getElementsByClassName("studyListCheckbox");
    for (var i = 0; inputElements[i]; ++i) {
      if (inputElements[i].checked) {
        wordlist.push(inputElements[i].value);
      }
    }
    if (wordlist.length > 0) {
      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };

        axios
          .post(
            process.env.REACT_APP_API_URL +
            "remove-bookscan-wordlists/" +
            bookScanListsArr[0][0],
            { wordlist },
            {
              headers,
            }
          )
          .then((res) => {
            if (res.data.code !== 200) {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });

              var get = document.getElementsByClassName("studyListCheckbox");

              for (var i = 0; i < get.length; i++) {
                get[i].checked = false;
              }

              setSelectNumberOfWordDetail(0);
              handleSubmitBookScanWordlistByTitle(e, bookScanListsArr[0][0]);
            }
          })
          .catch((error) => {
            toast.error("Something went wrong. Please try again", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      }
    }
  };
  const saveStudyList = (e) => {
    if (studyListName === "") {
      setBookscanWordlistSpiner(true);
      var words = [];
      var inputElements = document.getElementsByClassName("studyListCheckbox");
      // for (var i = 0; inputElements[i]; ++i) {
      //   if (inputElements[i].checked) {
      //     let filter = bookScanListsTableArr.filter((el) =>
      //       el?.word_id !== undefined && el?.word_id !== "" &&
      //       el?.word?.toLowerCase() === inputElements[i]?.value?.toLowerCase() &&
      //       el?.syllables == true &&  el?.speech_part_name == true && 
      //       el?.definition == true && 
      //       el?.sentences1 !== undefined && el?.sentences1 !== "" && el?.sentences1 > 0 &&
      //       el?.synonyms == true&&
      //       audioStatus[inputElements[i].value] == true &&
      //       audioStatus[inputElements[i].value + "_s"] === true &&
      //       el?.root_classicMean_origin==true)

      //     if(filter.length==0){
      //       return toast.error(`The word ${inputElements[i].value} must have complete data.`, {
      //         position: toast.POSITION.TOP_CENTER,
      //       });
      //     }
      //     words.push(inputElements[i].value);
      //   }

      // }
      let errorFlag = false;
      function checkDataAndShowErrors(inputElements, bookScanListsTableArr, audioStatus) {
        for (var i = 0; inputElements[i]; ++i) {
          if (inputElements[i].checked) {
            let filter = bookScanListsTableArr.filter(
              (element) => element?.word?.toLowerCase() === inputElements[i]?.value?.toLowerCase()
            );
            if (filter.length === 0) {
              toast.error(`The word ${inputElements[i].value} must have complete data.`, {
                position: toast.POSITION.TOP_CENTER,
              });
              errorFlag = true;
              break; // Exit the loop if an error occurred
            } else {
              let el = filter[0]; // Access the first element directly

              if (
                audioStatus[inputElements[i].value] !== true &&
                audioStatus[inputElements[i].value + "_s"] !== true
              ) {
                toast.error(`The word ${inputElements[i].value} must have complete data. Missing audio`, {
                  position: toast.POSITION.TOP_CENTER,
                });
                errorFlag = true;
              } else if (el?.synonyms !== true) {
                toast.error(`The word ${inputElements[i].value} must have complete data. Missing synonyms`, {
                  position: toast.POSITION.TOP_CENTER,
                });
                errorFlag = true;
              } else if (!(el?.sentences1 !== undefined && el?.sentences1 !== "" && el?.sentences1 > 0)) {
                toast.error(`The word ${inputElements[i].value} must have complete data. Missing sentences`, {
                  position: toast.POSITION.TOP_CENTER,
                });
                errorFlag = true;
              } else if (el?.definition !== true) {
                toast.error(`The word ${inputElements[i].value} must have complete data. Missing definition`, {
                  position: toast.POSITION.TOP_CENTER,
                });
                errorFlag = true;
              } else if (el?.speech_part_name !== true) {
                toast.error(`The word ${inputElements[i].value} must have complete data. Missing part of speech`, {
                  position: toast.POSITION.TOP_CENTER,
                });
                errorFlag = true;
              } else if (el?.syllables !== true) {
                toast.error(`The word ${inputElements[i].value} must have complete data. Missing syllables`, {
                  position: toast.POSITION.TOP_CENTER,
                });
                errorFlag = true;
              } else if (el?.root_classicMean_origin !== true) {
                toast.error(
                  `The word ${inputElements[i].value} must have complete data. Missing roots, classic meaning, or origin`,
                  {
                    position: toast.POSITION.TOP_CENTER,
                  }
                );
                errorFlag = true;
              }

              if (errorFlag) {
                break; // Exit the loop if an error occurred
              }
            }
            words.push(inputElements[i].value);
          }
        }
      }

      // Call the function
      checkDataAndShowErrors(inputElements, bookScanListsTableArr, audioStatus);

      if (errorFlag) {
        return;
      } else {

        if (words.length > 60) {
          return toast.error("Sorry we can only select 60 words.", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (words.length == 0) {
          return toast.error("Please select a word.", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          setStudyListWordArr(words);
          setOpenModalStudyList(true);
        }
      }

      setBookscanWordlistSpiner(false);
    } else {
      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        if (studyListName === "") {
          return toast.error("Please enter the study list name.", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + currentUser.accessToken,
          };
          let studyListAuthor = bookScanListsArr[0][1];
          let title = bookScanListsArr[0][0]
          console.log(studyListWordArr);
          axios
            .post(
              process.env.REACT_APP_API_URL + "save-master-sublists",
              { studyListName, studyListWordArr, studyListAuthor, title },
              {
                headers,
              }
            )
            .then((res) => {
              if (res.data.code !== 200) {
                toast.error(res.data.message, {
                  position: toast.POSITION.TOP_CENTER,
                });
              } else {
                toast.success(res.data.message, {
                  position: toast.POSITION.TOP_CENTER,
                });

                var get = document.getElementsByClassName("studyListCheckbox");

                for (var i = 0; i < get.length; i++) {
                  get[i].checked = false;
                }
                let arr;
                if (bookScanListsTableArrSaved?.length > 0) {
                  arr = bookScanListsTableArrSaved.concat(studyListWordArr)
                } else {
                  arr = studyListWordArr
                }

                // setGlobalStudyListWordArr(arr);
                setBookScanListsTableArrSaved(arr)
                setOpenModalStudyList(false);
                setStudyListName("");
                setSelectNumberOfWordDetail(0);
                handleSubmitLiteratureWordListByTitle(studyListName)
                fetchAllLiteratureWordlists();
                setCheckedCheckboxes([])
              }
            })
            .catch((error) => {
              toast.error("Something went wrong. Please try again", {
                position: toast.POSITION.TOP_CENTER,
              });
            });
        }
      }
    }
  };
  const handleOnChangeNumberOfWordDetail = (e) => {
    const word = e.target.value;

    if (e.target.checked) {
      if (checkedCheckboxes.length >= 60) {
        return toast.error("Sorry, we can only select up to 60 words.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }

      const updatedCheckboxes = [...checkedCheckboxes, word];
      setCheckedCheckboxes(updatedCheckboxes);
    } else {
      const updatedCheckboxes = checkedCheckboxes.filter((item) => item !== word);
      setCheckedCheckboxes(updatedCheckboxes);
    }
  };

  const handleOnchangeCheckedCheckboxes = (e) => {
    const value = parseInt(e.target.value, 10);
    const selectedCheckboxes = bookScanListsTableArr.slice(0, value).map((item) => item.word);
    setCheckedCheckboxes(selectedCheckboxes);
  };
  const removeLegacyWordWordlist = (e) => {
    var words = [];
    var inputElements = document.getElementsByClassName("deleteLegacyCheckbox");
    for (var i = 0; inputElements[i]; ++i) {
      if (inputElements[i].checked) {
        words.push(inputElements[i].value);
      }
    }
    if (words.length !== 0) {
      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };
        const wordlist = literatureListsArr[0][0].replace(
          /[`~!@#$%^&*()/_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
          ""
        );
        axios
          .post(
            process.env.REACT_APP_API_URL + `remove-word-legacy/${wordlist}`,
            { words },
            { headers }
          )
          .then((res) => {
            if (res.data.code === 200) {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
              setLiteratureListsTableArr(res.data.data.wordTables);
            } else {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          })
          .catch((error) => {
            toast.error("Something went wrong while removing words", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      }
    }
  };

  const removeWordlist = (e) => {
    setWordsObjToDisplay([]);
    setAverageWordFrequency(0);
    var words = [];
    var inputElements = document.getElementsByClassName("deleteCheckbox");
    for (var i = 0; inputElements[i]; ++i) {
      if (inputElements[i].checked) {
        words.push(inputElements[i].value);
      }
    }
    if (words.length !== 0) {
      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };

        axios
          .post(
            process.env.REACT_APP_API_URL +
            `remove-word-cognatelist/${wordlistname}`,
            { words },
            { headers }
          )
          .then((res) => {
            if (res.data.code === 200) {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
              const wordlist = wordlistname;
              fetch(
                process.env.REACT_APP_API_URL + "cognatelists/" + wordlist,
                {
                  headers,
                }
              )
                .then((res) => res.json())
                .then((data) => {
                  setWordsObjToDisplay(data.output);
                  setAverageWordFrequency(data.averageWordFrequency);
                  // setWordListWords(data.words);
                  setWordListName(wordlist);
                  let arr1 = [wordlist];
                  // setWordsArrToDisplay(data.words);
                  // setHeadWordsArrToDisplay(data.headwords);
                  setWordListNameArr(arr1);
                  setSearchWordList("");
                  setWordList("");
                  setWordSyllables([]);
                });
            }
          })
          .catch((error) => {
            toast.error("Something went wrong while removing words", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      }
    }
  };

  // End
  // the search result
  const [foundRoots, setFoundRoots] = useState([]);
  const initialState = wordVoyageSentences?.reduce(
    (o, key) => ({ ...o, [key]: false }),
    {}
  );
  const [checked, setChecked] = useState(initialState);
  const toggleCheck = (inputName) => {
    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };
  const options = fetchOtherFormsSentences ? otherFormBookList : booklist;

  let arr = [];
  allBookScanLists.map((book) => {
    let value = book?.title;
    let label = book?.title + `, ${book?.type}`;
    arr.push({ value, label });
  });
  const bookScanListOptions = arr;
  const addOrEditRoot = (e) => {
    resetEditRootPage();
    let dataset = e.currentTarget.dataset.id;
    if (dataset === "") {
      setModal1(false);
    } else {
      setModal1(true);
      setRootNumber(e.currentTarget.dataset.id);
      setReplaceRoot({
        root: e.currentTarget.dataset.root,
        meaning: e.currentTarget.dataset.meaning,
        root_id: e.currentTarget.dataset.root_id,
        root_type: e.currentTarget.dataset.root_type,
        exact_root: e.currentTarget.dataset.exact_root,
        sequence: e.currentTarget.dataset.sequence,
        sequence_2: e.currentTarget.dataset.sequence_2
      });
    }
  };

  const fetchwordDetail = (number, wordParams) => {
    // 1 cognate, 2 literature, 3 booscan wordlist
    if (number == 1) {
      localStorage.removeItem("literatureWordListByName");
      localStorage.removeItem("bookscanWordListByName");
    } else if (number == 2) {
      localStorage.removeItem("wordListNameArr");
      localStorage.removeItem("bookscanWordListByName");
    } else if (number == 3) {
      localStorage.removeItem("wordListNameArr");
      localStorage.removeItem("literatureWordListByName");
    }
    localStorage.setItem("wordParams", JSON.stringify(wordParams));
    window.location.reload(false);
  };
  const optionPOS = [
    { value: "Noun", label: "Noun" },
    { value: "Verb", label: "Verb" },
    { value: "Adjective", label: "Adjective" },
    { value: "Adverb", label: "Adverb" },
    { value: "Proper Noun", label: "Proper Noun" },
    { value: "Preposition", label: "Preposition" },
    { value: "Pronoun", label: "Pronoun" },
    { value: "Conjunction", label: "Conjunction" },
    { value: "Interjection", label: "Interjection" },
    { value: "Article", label: "Article" },
  ];

  const optionFamilies = [
    { value: "1", label: "Make it a seperate head word" },
    { value: "2", label: "Remove from word families" },
  ];

  const optionVoice = [
    { value: "", label: "Select the language)" },
    { value: "us", label: "English (United States)" },
    { value: "uk", label: "English (United Kingdom)" },
  ];

  const getSentencePOS = () => {
    if (sentencePOS) {
      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };
        axios
          .post(
            process.env.REACT_APP_API_URL + "admin/getPOS/",
            { sentencePOS },
            { headers }
          )
          .then((res) => {
            if (res.data.code === 200) {
              setShowSentencePOS(res.data.data);
            }
          })
          .catch((error) => {
            toast.error(
              "Something went wrong while fetching the Part of speech",
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );
          });
      }
    }
  };

  function resetEditRootPage() {
    setRootType("prefix");
    setNewRootType("");
    setNewRootDescription("");
    setNewRootMeaning("");
    setEditRoot("");
    setEditingRoot("");
    setNewEditRootMeaning("");
    setName("");
    setReplaceRoot({});
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + currentUser.accessToken,
    };
    fetch(process.env.REACT_APP_API_URL + "rootsDescription", {
      headers,
    })
      .then((res) => res.json())
      .then((data) => {
        setAllRootsObject(data.roots);
        let sortedRoots = [];
        for (const key in data.roots["prefix"]) {
          sortedRoots.push([data.roots["prefix"][key]["description"], key]);
        }
        sortedRoots.sort((a, b) => {
          if (a[0].toLowerCase() > b[0].toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setAllRoots(sortedRoots);
        setFoundRoots(sortedRoots);
      });
    // setFoundRoots([]);
    // setAllRoots([])
  }

  function resetEditRootPage1() {
    editRoot["description"] = newEditRootDescription || editRoot["description"];
    editRoot["meaning"] = newEditRootMeaning || editRoot["meaning"];
    // setRootType("prefix");
    setNewRootType("");
    setNewRootDescription("");
    setNewRootMeaning("");
    // setEditRoot("");
    // setEditingRoot('')
    setNewEditRootMeaning("");
    setName("");
    setReplaceRoot({});
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + currentUser.accessToken,
    };
    fetch(process.env.REACT_APP_API_URL + "rootsDescription", {
      headers,
    })
      .then((res) => res.json())
      .then((data) => {
        setAllRootsObject(data.roots);
        let sortedRoots = [];
        for (const key in data.roots["prefix"]) {
          sortedRoots.push([data.roots["prefix"][key]["description"], key]);
        }
        sortedRoots.sort((a, b) => {
          if (a[0].toLowerCase() > b[0].toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setAllRoots(sortedRoots);
        setFoundRoots(sortedRoots);
      });
    // setFoundRoots([]);
    // setAllRoots([])
  }

  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword != "") {
      const results = allRoots.filter((user) => {
        return user[0].toLowerCase().startsWith(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });

      setFoundRoots(results);
    } else {
      setFoundRoots(allRoots);
      // If the text field is empty, show all AallRoots
    }

    setName(keyword);
  };

  const addWordLegacyWorlistFunction = (e) => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      if (addWordLegacyWordlist === "") {
        toast.error("Word field is empty", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (literatureListsArr.length < 1) {
        toast.error("Wordlist field is empty", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };

        const wordlist = literatureListsArr[0][0];
        const word = addWordLegacyWordlist;
        axios
          .post(
            process.env.REACT_APP_API_URL +
            `add-word-legacyWordlist/${wordlist}`,
            { word },
            { headers }
          )
          .then((res) => {
            if (res.data.code == 201) {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
              setLiteratureListsTableArr(res.data.data.wordTables);
              res?.data?.data?.wordTables?.forEach((word) => {
                loadAudio(word.word, word.word_id);
              });
              setAddWordLegacyWorlist("");
            } else if (res.data.code == 404) {
              setOpenAddWordModalLegacyWordlist(true);
              setSelectedItemAdd("literature-wordlists")
              const data = {
                wordlist,
                word
              }
              setItemToAddWordModal(data)
            } else {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          });
      }
    }
  };
  const addWordWordlistFunction = (e) => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      if (addWordWordlist === "") {
        toast.error("Word field is empty", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (wordlistname.length < 1) {
        toast.error("Wordlist field is empty", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };

        const wordlist = wordlistname;
        const word = addWordWordlist;
        axios
          .post(
            process.env.REACT_APP_API_URL + `add-word-cognatelist/${wordlist}`,
            { word },
            { headers }
          )
          .then((res) => {
            if (res.data.code == 200) {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            } else if (res.data.code == 404) {
              setOpenAddWordModalCognateWordlist(true);
              setSelectedItemAdd("wordlists")
              const data = {
                wordlist,
                word
              }
              setItemToAddWordModal(data)
            } else {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          });

        fetch(process.env.REACT_APP_API_URL + "cognatelists/" + wordlist, {
          headers,
        })
          .then((res) => res.json())
          .then((data) => {
            setWordsObjToDisplay(data.output);
            data?.output.forEach((word) => {
              loadAudio(word.word, word.word_id);
            });
            setAverageWordFrequency(data.averageWordFrequency);
            // setWordListWords(data.words);
            setWordListName(wordlist);
            let arr1 = [wordlist];
            // setWordsArrToDisplay(data.words);
            // setHeadWordsArrToDisplay(data.headwords);
            setWordListNameArr(arr1);
            setSearchWordList("");
            setWordList("");
            setWordSyllables([]);
          });
      }
    }
  };

  const removeStory = (story) => {
    setStory(story);
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      if (word === "") {
        toast.error("Word field is empty", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };

        axios
          .patch(
            process.env.REACT_APP_API_URL +
            `admin/remove-story/${word}`, { story },
            { headers }
          )
          .then((res) => {
            if (res.data.code !== 200) {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
              setOpenModalStoryEdit(false);
            } else {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
              setWordStories(res.data.data);
              setOpenModalStoryEdit(false);
            }
          });
      }
    }
  };
  const addStory = () => {
    if (word === "") {
      toast.error("Word field is empty", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      setSource("");
      setStory("");
      setOpenModalStoryEdit(true);
      setUpdateStory(false);
    }
  };

  const editStory = (source, story) => {
    setOldStory(story);
    setStory(story);
    setOldSource(source);
    setSource(source);
    setOpenModalStoryEdit(true);
    setUpdateStory(true);
  };
  const addNewStory = (e) => {
    e.preventDefault();
    if (updateStory) {
      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        if (story === "") {
          toast.error("Story field is empty", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (source === "") {
          toast.error("Source field is empty", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + currentUser.accessToken,
          };

          axios
            .put(
              process.env.REACT_APP_API_URL + `admin/update-story/${word}`,
              { story, source, oldSource, oldStory },
              { headers }
            )
            .then((res) => {
              if (res.data.code !== 200) {
                toast.error(res.data.message, {
                  position: toast.POSITION.TOP_CENTER,
                });
                setOpenModalStoryEdit(false);
              } else {
                toast.success(res.data.message, {
                  position: toast.POSITION.TOP_CENTER,
                });
                setWordStories(res.data.data);
                setOpenModalStoryEdit(false);
              }
            });
        }
      }
    } else {
      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        if (story === "") {
          toast.error("Story field is empty", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (source === "") {
          toast.error("Source field is empty", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + currentUser.accessToken,
          };

          axios
            .post(
              process.env.REACT_APP_API_URL + `admin/add-story/${word}`,
              { story, source },
              { headers }
            )
            .then((res) => {
              if (res.data.code !== 200) {
                toast.error(res.data.message, {
                  position: toast.POSITION.TOP_CENTER,
                });
                setOpenModalStoryEdit(false);
              } else {
                toast.success(res.data.message, {
                  position: toast.POSITION.TOP_CENTER,
                });
                setWordStories(res.data.data);
                setOpenModalStoryEdit(false);
              }
            });
        }
      }
    }
  };
  const changeClassicMeaning = (e) => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      if (word === "") {
        toast.error("Word field is empty", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };

        axios
          .post(
            process.env.REACT_APP_API_URL + "admin/change-classic-meaning/" + word,
            { etymMeaning, etymMeaning2, etymMeaning3 },
            { headers }
          )
          .then((res) => {
            if (res.data.code !== 200) {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          });
      }
    }
  };
  const editSentence = (sentence, authorName, sourceSentence) => {
    setOldSentence(sentence);
    setNewSentence(sentence);
    setSourceSentence(sourceSentence);
    setOldAuthorName(authorName);
    setAuthorNameSentence(authorName)
    setOpenModalEdit(true);
    setUpdateSentence(true);
  };
  const changeWordPOS = (wordForm, e) => {
    let POS = e.value;
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      if (POS === "") {
        toast.error("Please select POS", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (wordForm === "") {
        toast.error("Word field is empty", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };
        axios
          .post(
            process.env.REACT_APP_API_URL + "coreData/word-pos/" + word,
            { POS, wordForm },
            { headers }
          )
          .then((res) => {
            if (res.data.code !== 204) {
              return toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
              const newArray = wordFamily.map((obj) => {
                if (obj.word === wordForm) {
                  return {
                    ...obj,
                    speech_part_name: POS,
                  };
                }
                return obj;
              });

              setWordFamily(newArray);
            }
          });
      }
    }
  };
  const changePOS = (sentence, e) => {
    let POS = e.value;
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      if (POS === "") {
        toast.error("Please select POS", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (word === "") {
        toast.error("Word field is empty", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };

        axios
          .post(
            process.env.REACT_APP_API_URL + "admin/add-pos/words/" + word,
            { sentence, POS },
            { headers }
          )
          .then((res) => {
            if (res.data.code !== 200) {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });

              setWordVoyageSentences(res.data.data.acceptedSentences);
              setWordVoyageOtherFormSentences(res.data.data.otherFormSentences);
            }
          });
      }
    }
  };

  const updateWordJson = () => {
    if (word === "") {
      return toast.error("Please type in a word", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (wordMeaning == "" || syllable == "" || frequency == "") {
        return toast.error("Please fill out all required fields.", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (!/^\d+$/.test(frequency)) {
        return toast.error("Frequency must contain only numbers.", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (speechPart == "") {
        return toast.error("Please select a part of the speech", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };
        axios
          .post(
            process.env.REACT_APP_API_URL + "updatewordmeaning/" + `${word}`,
            {
              wordMeaning,
              wordMeaning2,
              wordMeaning3,
              syllable,
              frequency
            },
            { headers }
          )
          .then((res) => {
            if (res.data.code == 200) {
              return toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              return toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          })
          .catch((error) => { });
      }
    }
  };

  const joinGoogleDataMeaning = (arr) => {
    let join = "";
    let i = 1;
    arr.forEach((ele) => {
      if (arr.length == 1) {
        join += ele;
      } else {
        join += i + ".) " + ele + " ";
        i += 1;
      }
    });
    return join;
  };

  const addNewRootFunction = () => {
    if (newRootDescription == "" || newRootMeaning == "" || newRootType == "") {
      toast.error(
        "Please select the root type, enter both the root meaning and description for the root you want to add",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    } else {
      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };
        axios
          .post(
            process.env.REACT_APP_API_URL + "new/roots/" + `${newRootMeaning}`,
            {
              newRoot: {
                root_id: newRootMeaning,
                meaning: newRootMeaning,
                description: newRootDescription,
                root_type:
                  newRootType.toLowerCase() == "prefix"
                    ? "P"
                    : newRootType.toLowerCase() == "suffix"
                      ? "S"
                      : "R",
              },
            },
            { headers }
          )
          .then((res) => {
            if (res.data.code == 201) {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
              setModal4(false);
              fetchRootsAndRootDescription();
              resetEditRootPage();
              window.location.reload(false);
              fetchAllWordlists();
            } else {
              return toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          })
          .catch((error) => {
            return toast.error("Something went wrong. Please try again", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      }
    }
  };
  const editRootFunction = () => {
    // e.preventDefault();
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "update/roots/" +
          `${editRoot.meaning}`,
          {
            newEditRoot: {
              root_id: editRoot["root_id"] || "",
              meaning: newEditRootMeaning || editRoot.meaning,
              description: newEditRootDescription || editRoot.description,
              oldroot: editRoot.description || "",
              oldrootmeaning: editRoot.meaning || "",
              root_type: editRoot.root_type
            },
          },
          { headers }
        )
        .then((res) => {
          toast.success("Root successfully updated!");
          // window.location.reload(false);
          setModal3(false);
          setModal1(false);
          fetchRootsAndRootDescription();
          handleSubmitWord(word);
          fetchAllWordlists();
          resetEditRootPage1();
          fetchAllWordlists();
        })
        .catch((error) => { });
    }
  };
  // const handleOnchangeCheckedCheckboxes=(e)=>{
  //   setCheckedCheckboxes(parseInt(e.target.value))
  //   setBookScanListsTableArr(bookScanListsTableArr)
  // }

  const addSentence = () => {
    if (word === "") {
      toast.error("Word field is empty", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      setNewSentence("");
      setSourceSentence("");
      setAuthorNameSentence("")
      setOpenModalEdit(true);
      setUpdateSentence(false);
    }
  };
  const addNewSentence = (e) => {
    e.preventDefault();
    if (updateSentence) {
      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        if (newSentence === "") {
          toast.error("Edit sentence field is invalid", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (sourceSentence === "") {
          toast.error("Source field is empty", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (authorNameSentence === "") {
          toast.error("author name field is empty", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + currentUser.accessToken,
          };
          const sentence = oldSentence;
          axios
            .post(
              process.env.REACT_APP_API_URL + "admin/edit-sentence/words/" + word,
              { sentence, newSentence, sourceSentence, oldAuthorName, authorNameSentence },
              { headers }
            )
            .then((res) => {
              if (res.data.code !== 200) {
                toast.error(res.data.message, {
                  position: toast.POSITION.TOP_CENTER,
                });
              } else {
                toast.success(res.data.message, {
                  position: toast.POSITION.TOP_CENTER,
                });
                setWordVoyageSentences(res.data.data.acceptedSentences);
                setWordVoyageOtherFormSentences(
                  res.data.data.otherFormSentences
                );
                setOpenModalEdit(false);
                setAudioSentenceUrl('');
                // Update audioStatus state by creating a new object
                setAudioStatus(prevState => ({
                  ...prevState, // Spread the previous state
                  [word + "_s"]: false // Update the specific key to false
                }));

              }
            });
        }
      }
    } else {
      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        if (newSentence === "") {
          toast.error("Add sentence field is empty", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (sourceSentence === "") {
          toast.error("Source field is empty", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (authorNameSentence === "") {
          toast.error("author name field is empty", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + currentUser.accessToken,
          };
          const sentence = newSentence;
          axios
            .post(
              process.env.REACT_APP_API_URL + "admin/add-sentence-words/" + word,
              { sentence, sourceSentence, authorNameSentence },
              { headers }
            )
            .then((res) => {
              if (res.data.code !== 200) {
                toast.error(res.data.message, {
                  position: toast.POSITION.TOP_CENTER,
                });
              } else {
                toast.success(res.data.message, {
                  position: toast.POSITION.TOP_CENTER,
                });
                setWordVoyageSentences(res.data.data.acceptedSentences);
                setWordVoyageOtherFormSentences(
                  res.data.data.otherFormSentences
                );
                setOpenModalEdit(false);
                setAudioSentenceUrl('');
                // Update audioStatus state by creating a new object
                setAudioStatus(prevState => ({
                  ...prevState, // Spread the previous state
                  [word + "_s"]: false // Update the specific key to false
                }));
              }
            });
        }
      }
    }
  };
  const handleSubmit = (e) => {
    // e.preventDefault();
    setPosExercise({
      text: '',
      quiz_questions: [initialQuestion]
    });
    setAudioTtsPOSExerciseurlSaved(false)
    setAudioTtsPOSExerciseurlSavedRead('');
    setAudioUrl("")
    setAudioSentenceUrl("")
    setSyllable("")
    setSelectedPOSArrays([]);
    setWordCousins([]);
    setOpenCousinsDialog(false);
    setOxfordPronunciation("");
    setOxfordSentences([]);
    setOxfordSynonyms([]);
    setOxfordSenses([]);
    setOxfordEtymologies("");
    setOxfordAntonyms([]);
    setAudio(false);
    setAudioIsFound(false);
    setSentenceAudioRead("");
    setFirstLegacySentence("");
    setWordStories([]);
    setOldStory("");
    setOldSource("");
    setStory("");
    setSource("");
    setOrtherFormsWithPOS([]);
    setMeaningChange(false);
    setGoogleWordMeaning1("");
    setGoogleWordMeaning2("");
    setGoogleWordMeaning3("");
    setGoogleWordMeaning4("");
    setPos("");
    setEtymMeaning("");
    setEtymMeaning2("");
    setEtymMeaning3("");
    setWordVoyageSentences([]);
    setAllSentences([]);
    setGoogleDataSentences([]);
    setSentenceArray([]);
    setAuthorName("");
    setGoogleSyllable("");
    setSyllable("");
    setHeadWord("");
    setWordFamily([]);
    setFrequency("");
    setSpeechPart("");
    setWordMeaning("");
    setWordMeaning2("");
    setWordMeaning3("");
    setFirstSpeechPart("");
    setSecondSpeechPart("");
    setThirdSpeechPart("");
    setGoogleData({});
    setBooklist([]);
    setOtherFormBookList([]);
    setRoots([]);
    setPublishedData({});
    setEnglishPeriod({});
    setBorrowing({});
    setHeadWordSpeechPart("");
    setHeadWordSpeechPart2("");
    setHeadWordSpeechPart3("");
    setWordVoyageOtherFormSentences([]);
    setAllBookSentences([]);
    setSelectedPOS("");
    setLoadingSentences(true)
    setBorrowingMeaning("");
    setBorrowingName("");
    setEnglishPeriodSelect("");
    setBorrowingLanguageSelect("")
    const wordParams = JSON.parse(localStorage.getItem("wordParams"));
    if (wordParams !== null) {
      setWord(wordParams);
      setLoading(true);
      if (wordParams === "" || wordParams.replace(/\s/g, "") === "") {
        setLoading(false);
        toast.error("Please enter a word", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      if (!/[a-zA-Z]/.test(wordParams)) {
        setLoading(false);
        return toast.error(`Please enter a valid word`);
      }

      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };
        fetch(
          process.env.REACT_APP_API_URL +
          "admin/allBooks-sentences/" +
          wordParams,
          {
            headers,
          }
        )
          .then((res) => res.json())
          .then((data1) => {
            if (data1.code !== 200) {
              toast.error(data1.message, {
                position: toast.POSITION.TOP_CENTER,
              });
              setLoading(false);
            } else {
              const data = data1.data;
              setAllBookSentences(data);
              setLoadingSentences(false)
            }
          })
          .catch((error) => {
            setLoadingSentences(false)
          });

        fetch(
          process.env.REACT_APP_API_URL +
          "words/" +
          wordParams.replace(/\s/g, ""),
          {
            headers,
          }
        )
          .then((res) => res.json())
          .then((data1) => {
            if (data1.code == 200) {
              let data = data1.data;
              setAudioIsFound(true);
              setWordStories(data.wordStories);
              let selectPOS = [];
              if (data.word_id) {
                loadAudio(wordParams, data.word_id)
                setWordId(data.word_id);
                var au = document.createElement("audio");
                au.src =
                  process.env.REACT_APP_S3_BASE_URL +
                  word[0] +
                  "/" +
                  word +
                  "_" +
                  data.word_id +
                  ".mp3";
                au.addEventListener(
                  "loadedmetadata",
                  function () {
                    var duration = au.duration;
                    setAudio(true);
                  },
                  false
                );

                // Fetch audio for legacy sentence
                var auS = document.createElement("audio");
                auS.src =
                  process.env.REACT_APP_S3_BASE_URL +
                  word[0] +
                  "/" +
                  word +
                  "_" +
                  data.word_id +
                  ".mp3";
                auS.addEventListener(
                  "loadedmetadata",
                  function () {
                    var duration = au.duration;
                    setAudioSentence(true);
                  },
                  false
                );

                setSentenceAudioRead(
                  process.env.REACT_APP_S3_BASE_URL +
                  word[0] +
                  "/" +
                  word +
                  "_" +
                  "s" +
                  "_" +
                  data.word_id +
                  ".mp3"
                );
                if (data.sentence !== undefined) {
                  setFirstLegacySentence(data.sentences[0]);
                }
                // for POS
                var auPOS = document.createElement("audio");
                auPOS.src =
                  process.env.REACT_APP_S3_BASE_URL +
                  word[0] +
                  "/" +
                  word +
                  "_pos_exercise_" +
                  data.word_id +
                  ".mp3";
                auPOS.addEventListener(
                  "loadedmetadata",
                  function () {
                    var duration = auPOS.duration;
                    setAudioTtsPOSExerciseurlSaved(true);
                  },
                  false
                );

                setAudioTtsPOSExerciseurlSavedRead(
                  process.env.REACT_APP_S3_BASE_URL +
                  word[0] +
                  "/" +
                  word +
                  "_pos_exercise_" +
                  data.word_id +
                  ".mp3"
                );

              }
              if (data.posExercise) {
                setPosExercise(data.posExercise);
              }
              if (data.word_id2) {
                setWordId2(data.word_id2);
                var au = document.createElement("audio");
                au.src =
                  process.env.REACT_APP_S3_BASE_URL +
                  word[0] +
                  "/" +
                  word +
                  "_" +
                  data.word_id2 +
                  ".mp3";
                au.addEventListener(
                  "loadedmetadata",
                  function () {
                    var duration = au.duration;
                    setAudio2(true);
                  },
                  false
                );
              }
              if (data.word_id3) {
                setWordId3(data.word_id3);
                var au = document.createElement("audio");
                au.src =
                  process.env.REACT_APP_S3_BASE_URL +
                  word[0] +
                  "/" +
                  word +
                  "_" +
                  data.word_id3 +
                  ".mp3";
                au.addEventListener(
                  "loadedmetadata",
                  function () {
                    var duration = au.duration;
                    setAudio3(true);
                  },
                  false
                );
              }

              let arr = [];
              setEtymMeaning(data.etym_meaning);
              setEtymMeaning2(data.etym_meaning2);
              setEtymMeaning3(data.etym_meaning3);
              setPublishedData(data.publishedData);
              setEnglishPeriod(data.english_period);
              setBorrowing(data.borrowing);
              setBorrowingMeaning(data.borrowing?.borrowingMeaning);
              setBorrowingName(data.borrowing?.borrowingName);
              let filter1 = borrowingLanguage.filter(el => el.label?.toLowerCase() == data.borrowing?.borrowingLanguage?.toLowerCase());
              if (filter1.length > 0) {
                setBorrowingLanguageSelect(filter1[0].value)
              }
              let filter2 = englishPeriodOrigins.filter(el => el.label?.toLowerCase() == data.english_period?.name?.toLowerCase());
              if (filter2.length > 0) {
                setEnglishPeriodSelect(filter2[0].value)
              }

              setWordVoyageSentences(data.acceptedSentences);

              setWordVoyageOtherFormSentences(data.otherFormSentences);
              let rootarr = [];
              if (data.roots) {
                setRoots(data.roots);
              }
              if (data.headWord) {
                setHeadWord(data.headWord);
              }
              if (data.speech_part_name) {
                setSpeechPart(data.speech_part_name);
                // const filterPOS = selectedPOSArrays.filter(
                //   (el) =>
                //     el?.value?.toLowerCase() ==
                //     data.speech_part_name.toLowerCase()
                // );
                // if (filterPOS.length == 0) {
                selectPOS.push({
                  value: data.speech_part_name,
                  label: data.speech_part_name,
                });
                // }
              }
              if (data.speech_part_name2) {
                setSecondSpeechPart(data.speech_part_name2);
                // const filterPOS = selectedPOSArrays.filter(
                //   (el) =>
                //     el?.value?.toLowerCase() ==
                //     data.speech_part_name2.toLowerCase()
                // );
                // if (filterPOS.length == 0) {
                selectPOS.push({
                  value: data.speech_part_name2,
                  label: data.speech_part_name2,
                });
                // }
              }
              if (data.speech_part_name3) {
                setThirdSpeechPart(data.speech_part_name3);
                // const filterPOS = selectedPOSArrays.filter(
                //   (el) =>
                //     el?.value?.toLowerCase() ==
                //     data.speech_part_name3.toLowerCase()
                // );
                // if (filterPOS.length == 0) {
                selectPOS.push({
                  value: data.speech_part_name3,
                  label: data.speech_part_name3,
                });
                // }
              }
              // const mergedArray = selectedPOSArrays.concat(selectPOS);
              setSelectedPOSArrays(selectPOS);
              if (data.headWordSpeechPart) {
                setHeadWordSpeechPart(data.headWordSpeechPart);
              }
              if (data.headWordSpeechPart2) {
                setHeadWordSpeechPart2(data.headWordSpeechPart2);
              }
              if (data.headWordSpeechPart3) {
                setHeadWordSpeechPart3(data.headWordSpeechPart3);
              }
              if (data.otherForms) {
                setWordFamily(data.otherForms);
                let arrays = []
                data.otherForms.forEach(element => {
                  arrays.push({ value: element.word, label: element.word })
                });
                setOtherForms(arrays);
              }
              if (data.frequency) {
                setFrequency(data.frequency);
              }

              setWordMeaning(data.meaning);
              setWordMeaning2(data.meaning2);
              setWordMeaning3(data.meaning3);
              if (data.syllables) {
                setSyllable(data.syllables);
              }
              setLoading(false);
            } else {
              setLoading(false);
              toast.error(data1.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          })
          .catch((error) => {
            toast.error("Something went wrong. Please try again", {
              position: toast.POSITION.TOP_CENTER,
            });
            setLoading(false);
          });
        // fetch(
        //   process.env.REACT_APP_API_URL + "oxford-dictionary/" + wordParams,
        //   {
        //     headers,
        //   }
        // )
        //   .then((res) => res.json())
        //   .then((data1) => {
        //     if (data1.code !== 200) {
        //       toast.error(data1.message, {
        //         position: toast.POSITION.TOP_CENTER,
        //       });
        //       setLoading(false);
        //     } else {
        //       const data = data1.data;
        //       setOxfordPronunciation(data.pronunciation);
        //       setOxfordSentences(data.sentences);
        //       setOxfordSynonyms(data.synonymCategories);
        //       setOxfordSenses(data.senses);
        //       setOxfordEtymologies(data.etymologies);
        //       setOxfordAntonyms(data.antonymCategories);
        //       let selectPOS = [];
        //       if (data.oxordPOS) {
        //         for (let i = 0; i < data.oxordPOS.length; i++) {

        //             selectPOS.push({
        //               value: data.oxordPOS[i],
        //               label: data.oxordPOS[i],
        //             });

        //         }
        //         setSelectedPOSArrays(selectPOS);
        //       }
        //       setLoading(false);
        //     }
        //   })
        //   .catch((error) => {
        //     // toast.error("data format not supported", {
        //     //   position: toast.POSITION.TOP_CENTER,
        //     // });
        //     // setLoading(false);
        //   });
      }
      const literatureWordListByName = JSON.parse(
        localStorage.getItem("literatureWordListByName")
      );
      const wordListValue = JSON.parse(localStorage.getItem("wordListNameArr"));
      if (literatureWordListByName !== null) {
        handleSubmitLiteratureWordListByTitle(literatureWordListByName);
      }
      if (wordListValue !== null) {
        handleSearchSubmitWordList();
      }
      const bookscanwordListValue = JSON.parse(localStorage.getItem("bookscanWordListByName"));
      if (bookscanwordListValue !== null) {
        handleSubmitBookScanWordlistByTitle(e, bookscanwordListValue);
      }
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      const word = wordParams;
      axios
        .post(
          process.env.REACT_APP_API_URL + "admin/specificBooks",
          { word },
          { headers }
        )
        .then((res) => {
          if (res.data.code === 200) {
            let arr = [];
            res.data.exactBooks.map((book) => {
              let value = book;
              let label = book;
              arr.push({ value, label });
            });
            setBooklist(arr);
            let otherFormBookList = [];
            res.data.books.map((book) => {
              let value = book;
              let label = book;
              otherFormBookList.push({ value, label });
            });
            setOtherFormBookList(otherFormBookList);
          }
        });
      setWord(wordParams);
      localStorage.removeItem("wordParams");
    } else if (word !== "") {
      setLoading(true);
      if (word === "" || word.replace(/\s/g, "") === "") {
        setLoading(false);
        return toast.error("Please enter a word", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      if (!/[a-zA-Z]/.test(word)) {
        setLoading(false);
        return toast.error(`Please enter a valid word`);
      }

      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };
        fetch(
          process.env.REACT_APP_API_URL + "admin/allBooks-sentences/" + word,
          {
            headers,
          }
        )
          .then((res) => res.json())
          .then((data1) => {
            if (data1.code !== 200) {
              toast.error(data1.message, {
                position: toast.POSITION.TOP_CENTER,
              });
              setLoading(false);
            } else {
              const data = data1.data;
              setLoadingSentences(false);
              setAllBookSentences(data);
            }
          })
          .catch((error) => {
            setLoadingSentences(false)
          });

        fetch(
          process.env.REACT_APP_API_URL + "words/" + word,
          {
            headers,
          }
        )
          .then((res) => res.json())
          .then((data1) => {
            if (data1.code == 200) {
              let data = data1.data;
              setAudioIsFound(true);
              setWordStories(data.wordStories);
              let selectPOS = [];
              if (data.word_id) {
                loadAudio(word, data.word_id)
                setWordId(data.word_id);
                var au = document.createElement("audio");
                au.src =
                  process.env.REACT_APP_S3_BASE_URL +
                  word[0] +
                  "/" +
                  word +
                  "_" +
                  data.word_id +
                  ".mp3";
                au.addEventListener(
                  "loadedmetadata",
                  function () {
                    var duration = au.duration;
                    setAudio(true);
                  },
                  false
                );

                // Fetch audio for legacy sentence
                var auS = document.createElement("audio");
                auS.src =
                  process.env.REACT_APP_S3_BASE_URL +
                  word[0] +
                  "/" +
                  word +
                  "_" +
                  data.word_id +
                  ".mp3";
                auS.addEventListener(
                  "loadedmetadata",
                  function () {
                    var duration = au.duration;
                    setAudioSentence(true);
                  },
                  false
                );
                setSentenceAudioRead(
                  process.env.REACT_APP_S3_BASE_URL +
                  word[0] +
                  "/" +
                  word +
                  "_" +
                  "s" +
                  "_" +
                  data.word_id +
                  ".mp3"
                );
                if (data.sentence !== undefined) {
                  setFirstLegacySentence(data.sentences[0]);
                }
                // for POS
                var auPOS = document.createElement("audio");
                auPOS.src =
                  process.env.REACT_APP_S3_BASE_URL +
                  word[0] +
                  "/" +
                  word +
                  "_pos_exercise_" +
                  data.word_id +
                  ".mp3";
                auPOS.addEventListener(
                  "loadedmetadata",
                  function () {
                    var duration = auPOS.duration;
                    setAudioTtsPOSExerciseurlSaved(true);
                  },
                  false
                );

                setAudioTtsPOSExerciseurlSavedRead(
                  process.env.REACT_APP_S3_BASE_URL +
                  word[0] +
                  "/" +
                  word +
                  "_pos_exercise_" +
                  data.word_id +
                  ".mp3"
                );

              }
              if (data.posExercise) {
                setPosExercise(data.posExercise);
              }
              if (data.word_id2) {
                setWordId2(data.word_id2);
                var au = document.createElement("audio");
                au.src =
                  process.env.REACT_APP_S3_BASE_URL +
                  word[0] +
                  "/" +
                  word +
                  "_" +
                  data.word_id2 +
                  ".mp3";
                au.addEventListener(
                  "loadedmetadata",
                  function () {
                    var duration = au.duration;
                    setAudio2(true);
                  },
                  false
                );
              }
              if (data.word_id3) {
                setWordId3(data.word_id3);
                var au = document.createElement("audio");
                au.src =
                  process.env.REACT_APP_S3_BASE_URL +
                  word[0] +
                  "/" +
                  word +
                  "_" +
                  data.word_id3 +
                  ".mp3";
                au.addEventListener(
                  "loadedmetadata",
                  function () {
                    var duration = au.duration;
                    setAudio3(true);
                  },
                  false
                );
              }

              let arr = [];
              setEtymMeaning(data.etym_meaning);
              setEtymMeaning2(data.etym_meaning2);
              setEtymMeaning3(data.etym_meaning3);
              setPublishedData(data.publishedData);
              setEnglishPeriod(data.english_period);
              setBorrowing(data.borrowing);
              setBorrowingMeaning(data.borrowing?.borrowingMeaning);
              setBorrowingName(data.borrowing?.borrowingName);
              let filter1 = borrowingLanguage.filter(el => el.label?.toLowerCase() == data.borrowing?.borrowingLanguage?.toLowerCase());
              if (filter1.length > 0) {
                setBorrowingLanguageSelect(filter1[0].value)
              }
              let filter2 = englishPeriodOrigins.filter(el => el.label?.toLowerCase() == data.english_period?.name?.toLowerCase());
              if (filter2.length > 0) {
                setEnglishPeriodSelect(filter2[0].value)
              }
              setWordVoyageSentences(data.acceptedSentences);

              setWordVoyageOtherFormSentences(data.otherFormSentences);
              let rootarr = [];
              setRoots(data.roots);
              if (data.headWord) {
                setHeadWord(data.headWord);
              }
              if (data.speech_part_name) {
                setSpeechPart(data.speech_part_name);
                // const filterPOS = selectedPOSArrays.filter(
                //   (el) =>
                //     el?.value?.toLowerCase() ==
                //     data.speech_part_name.toLowerCase()
                // );
                // if (filterPOS.length == 0) {
                selectPOS.push({
                  value: data.speech_part_name,
                  label: data.speech_part_name,
                });
                // }
              }
              if (data.speech_part_name2) {
                setSecondSpeechPart(data.speech_part_name2);
                // const filterPOS = selectedPOSArrays.filter(
                //   (el) =>
                //     el?.value?.toLowerCase() ==
                //     data.speech_part_name2.toLowerCase()
                // );
                // if (filterPOS.length == 0) {
                selectPOS.push({
                  value: data.speech_part_name2,
                  label: data.speech_part_name2,
                });
                // }
              }
              if (data.speech_part_name3) {
                setThirdSpeechPart(data.speech_part_name3);
                // const filterPOS = selectedPOSArrays.filter(
                //   (el) =>
                //     el?.value?.toLowerCase() ==
                //     data.speech_part_name3.toLowerCase()
                // );
                // if (filterPOS.length == 0) {
                selectPOS.push({
                  value: data.speech_part_name3,
                  label: data.speech_part_name3,
                });
                // }
              }
              // const mergedArray = selectedPOSArrays.concat(selectPOS);
              setSelectedPOSArrays(selectPOS);
              if (data.headWordSpeechPart) {
                setHeadWordSpeechPart(data.headWordSpeechPart);
              }
              if (data.headWordSpeechPart2) {
                setHeadWordSpeechPart2(data.headWordSpeechPart2);
              }
              if (data.headWordSpeechPart3) {
                setHeadWordSpeechPart3(data.headWordSpeechPart3);
              }
              if (data.otherForms) {
                setWordFamily(data.otherForms);
                let arrays = []
                data.otherForms.forEach(element => {
                  arrays.push({ value: element.word, label: element.word })
                });
                setOtherForms(arrays);
              }
              if (data.frequency) {
                setFrequency(data.frequency);
              }

              setWordMeaning(data.meaning);
              setWordMeaning2(data.meaning2);
              setWordMeaning3(data.meaning3);
              if (data.syllables) {
                setSyllable(data.syllables);
              }
              setLoading(false);
            } else {
              setLoading(false);
              toast.error(data1.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          })
          .catch((error) => {
            toast.error("Something went wrong. Please try again", {
              position: toast.POSITION.TOP_CENTER,
            });
            setLoading(false);
          });
        // fetch(process.env.REACT_APP_API_URL + "oxford-dictionary/" + word, {
        //   headers,
        // })
        //   .then((res) => res.json())
        //   .then((data1) => {
        //     if (data1.code !== 200) {
        //       toast.error(data1.message, {
        //         position: toast.POSITION.TOP_CENTER,
        //       });
        //       setLoading(false);
        //     } else {
        //       const data = data1.data;
        //       setOxfordPronunciation(data.pronunciation);
        //       setOxfordSentences(data.sentences);
        //       setOxfordSynonyms(data.synonymCategories);
        //       setOxfordSenses(data.senses);
        //       setOxfordEtymologies(data.etymologies);
        //       setOxfordAntonyms(data.antonymCategories);
        //       let selectPOS = [];
        //       if (data.oxordPOS) {
        //         for (let i = 0; i < data.oxordPOS.length; i++) {
        //           selectPOS.push({
        //             value: data.oxordPOS[i],
        //             label: data.oxordPOS[i],
        //           });
        //         }
        //         setSelectedPOSArrays(selectPOS);
        //       }
        //       setLoading(false);
        //     }
        //   })
        //   .catch((error) => {
        //     // toast.error("data format not supported", {
        //     //   position: toast.POSITION.TOP_CENTER,
        //     // });
        //     // setLoading(false);
        //   });
      }

      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      axios
        .post(
          process.env.REACT_APP_API_URL + "admin/specificBooks",
          { word },
          { headers }
        )
        .then((res) => {
          if (res.data.code === 200) {
            let arr = [];
            res.data.exactBooks.map((book) => {
              let value = book;
              let label = book;
              arr.push({ value, label });
            });
            setBooklist(arr);
            let otherFormBookList = [];
            res.data.books.map((book) => {
              let value = book;
              let label = book;
              otherFormBookList.push({ value, label });
            });
            setOtherFormBookList(otherFormBookList);
          }
        });
    }
  };
  
  const allowSeperateWordFamilies = (word1,word2,e) => {
    let isHeadWord=e.value;
      if (word1 === "" || word1.replace(/\s/g, "") === "") {
        return toast.error("Please enter a word", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      if (!/[a-zA-Z]/.test(word1)) {
        return toast.error(`Please enter a valid word`);
      }
      if (word2 === "" || word2.replace(/\s/g, "") === "") {
        return toast.error("Please enter a word", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };
      
        axios
        .post(
          process.env.REACT_APP_API_URL + "adminDashboard/seperating-word",
          { word:word1, word2,isHeadWord },
          { headers }
        )
        .then((res) => {
          if (res.data.code !== 200) {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            handleSubmitWord(word);
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });

          }
        });
      }

    
  };
  // Endnnn
  const handleSubmitWord = (wordInput) => {
    if (wordInput === "" || wordInput.replace(/\s/g, "") === "") {
      return;
    }
    if (!/[a-zA-Z]/.test(wordInput)) {
      return;
    }

    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };


      fetch(
        process.env.REACT_APP_API_URL + "words/" + wordInput,
        {
          headers,
        }
      )
        .then((res) => res.json())
        .then((data1) => {
          if (data1.code == 200) {
            let data = data1.data;
            let rootarr = [];
            setRoots(data.roots); 
            if (data.otherForms) {
              setWordFamily(data.otherForms);
              let arrays = []
              data.otherForms.forEach(element => {
                arrays.push({ value: element.word, label: element.word })
              });
              setOtherForms(arrays);
            }           
          }
        })
        .catch((error) => {
        
        });

    }
  };

  const seeCousins = () => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (word === "" || word.replace(/\s/g, "") === "") {
      return toast.error("Please enter a word", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      fetch(process.env.REACT_APP_API_URL + "cousins/" + word)
        .then((res) => res.json())
        .then((data1) => {
          if (data1.code !== 200) {
            return toast.error(data1.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            setWordCousins(data1.data);
            setOpenCousinsDialog(true);
          }
        })
        .catch((error) => {
          return toast.error("Something went wrong. Please try again", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  const removeSentence = (sentence, authorName) => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };

      axios
        .post(
          process.env.REACT_APP_API_URL + "admin/remove-sentence/words/" + word,
          { sentence, authorName },
          { headers }
        )
        .then((res) => {
          if (res.data.code !== 200) {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
            setWordVoyageSentences(res.data.data.acceptedSentences);
            setWordVoyageOtherFormSentences(res.data.data.otherFormSentences);
            setAudioSentenceUrl('');
            // Update audioStatus state by creating a new object
            setAudioStatus(prevState => ({
              ...prevState, // Spread the previous state
              [word + "_s"]: false // Update the specific key to false
            }));
          }
        });
    }
  };
  const clearRoot = (root, meaning, root_id, root_type, exact_root) => {
    resetEditRootPage();
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      axios
        .post(
          process.env.REACT_APP_API_URL + "delete/roots/words/" + word,
          { root, meaning, root_id, root_type, exact_root },
          { headers }
        )
        .then((res) => {
          if (res.data.code == 200) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
            let data = res.data;
            let rootarr = [];
            setRoots(data.roots);
          } else {
            return toast.error(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((error) => {
          toast.error("Something went wrong. Please try again", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };
  // const clearRoot = (root, meaning, root_id, root_type, exact_root) => {
  //   resetEditRootPage();
  //   if (new Date() > new Date(currentUser.expiresInTime)) {
  //     toast.error("Unautorized User. Please log In", {
  //       position: toast.POSITION.TOP_CENTER,
  //     });
  //     localStorage.removeItem("user");
  //     window.location.href = "/";
  //   } else if (currentUser.role !== "Super Admin") {
  //     toast.error("Unautorized User. Only the super admin has access", {
  //       position: toast.POSITION.TOP_CENTER,
  //     });
  //   } else {
  //     const headers = {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + currentUser.accessToken,
  //     };
  //     axios
  //       .post(
  //         process.env.REACT_APP_API_URL + "delete/roots/words/" + word,
  //         { root, meaning, root_id, root_type, exact_root },
  //         { headers }
  //       )
  //       .then((res) => {
  //         if (res.data.code == 200) {
  //           toast.success(res.data.message, {
  //             position: toast.POSITION.TOP_CENTER,
  //           });
  //           let data = res.data;
  //           let rootarr = [];
  //           if (data.roots) {
  //             let i = 1;
  //             data.roots.forEach((eachroot) => {
  //               const root = [
  //                 <Tooltip
  //                   onClick={addOrEditRoot}
  //                   data-id={i}
  //                   data-root={eachroot.root}
  //                   data-meaning={eachroot.meaning}
  //                   data-root_id={eachroot.root_id}
  //                   data-root_type={eachroot.root_type}
  //                   data-exact_root={eachroot.exact_root}
  //                   data-sequence={eachroot.sequence}
  //                   data-sequence_2={eachroot.sequence_2}
  //                   title="Add/Edit Root"
  //                   placement="top"
  //                   classes={{ tooltip: classes1.tooltip }}
  //                 >
  //                   <IconButton
  //                     aria-label="Edit"
  //                     className={classes1.tableActionButton}
  //                   >
  //                     <Edit
  //                       className={
  //                         classes1.tableActionButtonIcon +
  //                         " " +
  //                         classes1.edit
  //                       }
  //                     />
  //                   </IconButton>
  //                 </Tooltip>,
  //                 <Button
  //                   style={{
  //                     backgroundColor: "transparent",
  //                     color: "black",
  //                   }}
  //                   onClick={(e) => {
  //                     clearRoot(eachroot.root, eachroot.meaning, eachroot.root_id, eachroot.root_type, eachroot.exact_root);
  //                   }}
  //                 >
  //                   {"Clear " + i}
  //                 </Button>,
  //                 eachroot["root_type"],
  //                 eachroot.root,
  //                 eachroot["exact_root"],
  //                 eachroot.meaning,
  //               ];
  //               rootarr.push(root);
  //               i = i + 1;
  //             });
  //           }
  //           setRoots(rootarr);
  //         } else {
  //           return toast.error(res.data.message, {
  //             position: toast.POSITION.TOP_CENTER,
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         toast.error("Something went wrong. Please try again", {
  //           position: toast.POSITION.TOP_CENTER,
  //         });
  //       });
  //   }
  // };
  const handleDelete = () => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };

      if (selectedItemToDelete?.toLowerCase() == "root") {
        if (editRoot === "") {
          return toast.error("Please select a root to delete", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          axios
            .post(process.env.REACT_APP_API_URL + "deactivate-root/" + editRoot.meaning, editRoot, {
              headers,
            })
            .then((res) => {
              if (res.data.code == 200) {
                setOpenDeleteModalRoot(false);
                // const filtered = foundRoots
                // .map(innerArray =>
                //   innerArray.filter(item => !item.includes(editRoot?.description))
                // )
                // .filter(innerArray => innerArray.length > 0).map(innerArray =>
                //   innerArray.filter(item => !item.includes(editRoot?.root_id))
                // )
                // .filter(innerArray => innerArray.length > 0);
                //   setFoundRoots(filtered);

                setEditRoot("");
                setModal1(true);
                toast.success(res.data.message, {
                  position: toast.POSITION.TOP_CENTER,
                });
                window.location.reload(false);
              } else {
                setOpenDeleteModalRoot(false);
                return toast.error(res.data.message, {
                  position: toast.POSITION.TOP_CENTER,
                });
              }
            })
            .catch((error) => {
              toast.error("Something went wrong. Please try again", {
                position: toast.POSITION.TOP_CENTER,
              });
            });
        }
      }
    }
  };
  const handleAddWordToWordlist = () => {
    if (selectedItemToAdd === "" || Object.keys(itemToAddWordModal).length === 0) {
      return toast.error("Please type a word to add", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };
        axios
          .post(
            process.env.REACT_APP_API_URL + "add-word-wordlists/",
            { selectedItemToAdd, itemToAddWordModal },
            { headers }
          )
          .then((res) => {
            if (res.data.code !== 201) {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
              window.location.reload()
            }
          }).catch((error) => { });
      }
    }
  }
  const handleCancel = () => {
    setOpenDeleteModalRoot(false);
    setOpenAddWordModalCognateWordlist(false);
    setOpenAddWordModalLegacyWordlist(false);
    setOpenAddWordModalBookscanWordlist(false)
  };
  const deleteRoot = (e) => {
    if (editRoot === "") {
      return toast.error("Please select a root to delete", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      setSelectedItemToDelete("root");
      setOpenDeleteModalRoot(true);
    }
  };

  // This part is for drag and drop roots start
  const onLayoutChange = (newLayout) => {
    setLayout(newLayout);
  };

  const handleUpdateRootOrder = () => {
    const sortedRoots = [...roots];

    // Sort roots based on their position in the layout
    sortedRoots.sort((a, b) => {
      const aLayout = layout.find((item) => item.i === a.root_id);
      const bLayout = layout.find((item) => item.i === b.root_id);

      return aLayout?.y - bLayout?.y || aLayout?.x - bLayout?.x;
    });

    // Update the "sequence" values starting from 1
    const updatedSortedData = sortedRoots.map((root, index) => ({
      ...root,
      sequence: index + 1,
      sequence_2: index + 1,
    }));

    if (word === "") {
      return toast.error("Please  select a word", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (updatedSortedData.length == 0) {
      return toast.error("There is no root to update", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };
        axios
          .post(
            process.env.REACT_APP_API_URL + "coreData/order-word-root/" + `${word}`,
            { updatedSortedData },
            { headers }
          )
          .then((res) => {
            if (res.data.code == 200) {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
              setRoots(data.roots);
              resetEditRootPage();
            } else {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          })
          .catch((error) => { });
      }
    }
  };

  //This part is for drag and drop roots end
  const editWordRoots = (e) => {
    e.preventDefault();
    if (editRoot === "") {
      return toast.error("Please  select a root", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (word === "") {
      return toast.error("Please  select a word", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      if (new Date() > new Date(currentUser.expiresInTime)) {
        toast.error("Unautorized User. Please log In", {
          position: toast.POSITION.TOP_CENTER,
        });
        localStorage.removeItem("user");
        window.location.href = "/";
      } else if (currentUser.role !== "Super Admin") {
        toast.error("Unautorized User. Only the super admin has access", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };
        axios
          .post(
            process.env.REACT_APP_API_URL + "update/words/" + `${word}`,
            { editRoot, replaceRoot },
            { headers }
          )
          .then((res) => {
            if (res.data.code == 200) {
              toast.success("Root successfully added!");
              setModal1(false);
              // handleSubmit()
              let data = res.data;
              let rootarr = [];
              setRoots(data.roots);
              resetEditRootPage();
            } else {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          })
          .catch((error) => { });
      }
    }
  };

  const addBookSentences = (e) => {
    // setChecked({});

    e.preventDefault();
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      var sentences = [];
      var inputElements = document.getElementsByClassName("addCheckbox");
      for (var i = 0; inputElements[i]; ++i) {
        if (inputElements[i].checked) {
          sentences.push(inputElements[i].value);
        }
      }
      if (sentences.length === 0) {
        setOpenModal(false);
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };
        const bookName = selectedOptionFunc.value;
        axios
          .post(
            process.env.REACT_APP_API_URL + "admin/add-book-sentences-words/" + word,
            { sentences, bookName },
            { headers }
          )
          .then((res) => {
            if (res.data.code !== 200) {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER,
              });
              setWordVoyageSentences(res.data.data.acceptedSentences);
              setWordVoyageOtherFormSentences(res.data.data.otherFormSentences);
              setAudioSentenceUrl('');
              // Update audioStatus state by creating a new object
              setAudioStatus(prevState => ({
                ...prevState, // Spread the previous state
                [word + "_s"]: false // Update the specific key to false
              }));
            }
          });

        setOpenModal(false);
      }
    }
  };

  const fetchSentences = (e) => {
    e.preventDefault();
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      if (
        selectedOptionFunc.value === "" ||
        selectedOptionFunc.value === undefined
      ) {
        toast.error("Please select a book title", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (word === "" || word === undefined) {
        toast.error("Please fetch a word first", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentUser.accessToken,
        };
        const booktoedit = selectedOptionFunc.value;
        // const word = wordBook;
        axios
          .post(
            process.env.REACT_APP_API_URL + "admin/sentences/book",
            { booktoedit, word, fetchOtherFormsSentences },
            { headers }
          )
          .then((res) => {
            if (res.data.code !== 200) {
              toast.error(
                `The word ${word} doesn't exist in this book ${booktoedit}`,
                {
                  position: toast.POSITION.TOP_CENTER,
                }
              );
            } else {
              setAuthorName(res.data.data.author);
              // This is to fetch all sentences from a particular book
              if (res?.data?.data?.sentenceArray?.length == 0) {
                toast.warning("This book does not contain the exact word.", {
                  position: toast.POSITION.TOP_CENTER,
                });
              } else {
                setOpenModal(true);
                setBookSentences(res.data.data.sentenceArray);
              }
            }
          });
      }
    }
  };
  const oxfordDictionary = (e) => {
    setOxfordPronunciation("");
    setOxfordSentences([]);
    setOxfordSynonyms([]);
    setOxfordSenses([]);
    setOxfordEtymologies("");
    setOxfordAntonyms([]);
    setAudio(false);
    setAudioIsFound(false);
    setWordStories([]);
    setOldStory("");
    setOldSource("");
    setStory("");
    setSource("");
    setOrtherFormsWithPOS([]);
    setMeaningChange(false);
    setGoogleWordMeaning1("");
    setGoogleWordMeaning2("");
    setGoogleWordMeaning3("");
    setGoogleWordMeaning4("");
    setPos("");
    setLoading(true);
    setEtymMeaning("");
    setWordVoyageSentences([]);
    setAllSentences([]);
    setGoogleDataSentences([]);
    setSentenceArray([]);
    setAuthorName("");
    setGoogleSyllable("");
    setSyllable("");
    setHeadWord("");
    setWordFamily([]);
    setFrequency("");
    setSpeechPart("");
    setWordMeaning("");
    setFirstSpeechPart({});
    setSecondSpeechPart({});
    setThirdSpeechPart({});
    setGoogleData({});
    setBooklist([]);
    setRoots([]);
    if (word === "" || word.replace(/\s/g, "") === "") {
      setLoading(false);
      return toast.error("Please enter a word", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (!/[a-zA-Z]/.test(word)) {
      setLoading(false);
      return toast.error(`Please enter a valid word`);
    }
    e.preventDefault();
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      fetch(
        process.env.REACT_APP_API_URL + "words/" + word.replace(/\s/g, ""),
        {
          headers,
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setAudioIsFound(true);
          setWordStories(data.wordStories);
          if (data.word_id) {
            setWordId(data.word_id);
            var au = document.createElement("audio");
            au.src =
              process.env.REACT_APP_S3_BASE_URL +
              word[0] +
              "/" +
              word +
              "_" +
              data.word_id +
              ".mp3";
            au.addEventListener(
              "loadedmetadata",
              function () {
                var duration = au.duration;
                setAudio(true);
              },
              false
            );
          }
          let arr = [];
          setEtymMeaning(data.etym_meaning);
          if (data.acceptedSentences === undefined) {
            for (let i = 0; i < data.sentences.length; i++) {
              let sentence = data.sentences[i];
              let authorName = "Word Voyage";
              arr.push({
                sentence,
                authorName,
              });
            }
            setWordVoyageSentences(arr);
          } else {
            setWordVoyageSentences(data.acceptedSentences);
          }
          if (
            data === "Error: no such file or directory" ||
            data.found === false
          ) {
            toast.error(`Sorry, the word, ${word}, does not exits!`);
          }
          let rootarr = [];
          if (data.roots) {
            let i = 1;
            data.roots.forEach((eachroot) => {
              const root = [
                <Tooltip
                  onClick={addOrEditRoot}
                  data-id={i}
                  data-root={eachroot.root}
                  data-meaning={eachroot.meaning}
                  data-root_id={eachroot.root_id}
                  data-root_type={eachroot.root_type}
                  data-exact_root={eachroot.exact_root}
                  data-sequence={eachroot.sequence}
                  data-sequence_2={eachroot.sequence_2}
                  title="Add/Edit Root"
                  placement="top"
                  classes={{ tooltip: classes1.tooltip }}
                >
                  <IconButton
                    aria-label="Edit"
                    className={classes1.tableActionButton}
                  >
                    <Edit
                      className={
                        classes1.tableActionButtonIcon + " " + classes1.edit
                      }
                    />
                  </IconButton>
                </Tooltip>,
                <Button
                  style={{ backgroundColor: "transparent", color: "black" }}
                  onClick={(e) => {
                    clearRoot(eachroot.root, eachroot.meaning, eachroot.root_id, eachroot.root_type, eachroot.exact_root);
                  }}
                >
                  {"Clear " + i}
                </Button>,
                eachroot["root_type"],
                eachroot.root,
                eachroot["exact_root"],
                eachroot.meaning,
              ];

              rootarr.push(root);
              i = i + 1;
            });
          }
          setRoots(rootarr);
          if (data.headWord) {
            fetch(process.env.REACT_APP_API_URL + "words/" + data.headWord, {
              headers,
            })
              .then((res) => res.json())
              .then((data1) => {
                setHeadWord(data.headWord);
                setFrequency(data1.frequency);
                if (data1.speech_part) {
                  setSpeechPart(data1.speech_part);
                } else if (data1[0]) {
                  let info = {
                    partOfSpeech: data1[0].speech_part,
                    meaning: data[0].meaning,
                  };
                  setFirstSpeechPart(info);
                } else if (data1[1]) {
                  let info = {
                    partOfSpeech: data1[1].speech_part,
                    meaning: data1[1].meaning,
                  };
                  setSecondSpeechPart(info);
                } else if (data1[2]) {
                  let info = {
                    partOfSpeech: data1[2].speech_part,
                    meaning: data1[2].meaning,
                  };
                  setThirdSpeechPart(info);
                }
                setWordMeaning(data.meaning);
                if (data.syllables) {
                  setSyllable(data.syllables);
                }
                setWordFamily(data1.otherForms);
              })
              .catch((error) => {
                toast.error(
                  `Sorry, the word, ${word}, does not exits in the database!`
                );
              });
          } else if (data.otherForms) {
            setHeadWord(word);
            setWordFamily(data.otherForms);
            setFrequency(data.frequency);

            if (data.speech_part) {
              setSpeechPart(data.speech_part);
            }
            if (data[0]) {
              let info = {
                partOfSpeech: data[0].speech_part,
                meaning: data[0].meaning,
              };
              setFirstSpeechPart(info);
            }
            if (data[1]) {
              let info = {
                partOfSpeech: data[1].speech_part,
                meaning: data[1].meaning,
              };
              setSecondSpeechPart(info);
            }
            if (data[2]) {
              let info = {
                partOfSpeech: data[2].speech_part,
                meaning: data[2].meaning,
              };
              setThirdSpeechPart(info);
            }
            setWordMeaning(data.meaning);
            if (data.syllables) {
              setSyllable(data.syllables);
            }
          } else {
            if (data.syllables) {
              setSyllable(data.syllables);
            }
            if (data.meaning) {
              setWordMeaning(data.meaning);
            }
            if (data.speech_part) {
              setSpeechPart(data.speech_part);
            }
            if (data[0]) {
              let info = {
                partOfSpeech: data[0].speech_part,
                meaning: data[0].meaning,
              };
              setFirstSpeechPart(info);
            }
            if (data[1]) {
              let info = {
                partOfSpeech: data[1].speech_part,
                meaning: data[1].meaning,
              };
              setSecondSpeechPart(info);
            }
            if (data[2]) {
              let info = {
                partOfSpeech: data[2].speech_part,
                meaning: data[2].meaning,
              };
              setThirdSpeechPart(info);
            }
            if (data.frequency) {
              setFrequency(data.frequency);
            }
          }
        })
        .catch((error) => {
          // if (booktoedit === '') {
          //   toast.error('please choose book to scan')
          // } else {
          //   toast.error('scan fail')
          // }
        });
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + currentUser.accessToken,
    };
    axios
      .post(
        process.env.REACT_APP_API_URL + "admin/specificBooks",
        { word },
        { headers }
      )
      .then((res) => {
        if (res.data.code === 200) {
          let arr = [];
          res.data.books.map((book) => {
            let value = book;
            let label = book;
            arr.push({ value, label });
          });
          setBooklist(arr);
        }
      });

    fetch(process.env.REACT_APP_API_URL + "oxford-dictionary/" + word, {
      headers,
    })
      .then((res) => res.json())
      .then((data1) => {
        if (data1.code !== 200) {
          toast.error(data1.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setLoading(false);
        } else {
          const data = data1.data;
          setWordVoyageSentences(data.acceptedSentences);
          setOxfordPronunciation(data.pronunciation);
          setOxfordSentences(data.sentences);
          setOxfordSynonyms(data.synonymCategories);
          setOxfordSenses(data.senses);
          setOxfordEtymologies(data.etymologies);
          setOxfordAntonyms(data.antonymCategories);
          setLoading(false);
        }
      })
      .catch((error) => {
        // toast.error("data format not supported", {
        //   position: toast.POSITION.TOP_CENTER,
        // });
        setLoading(false);
      });
  };


  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };
  const playClipSentence = (e) => {
    document.getElementById("playClipSentence").play();
  };
  const playClip = (e) => {
    document.getElementById("playaudio").play();
  };
  const playClip1 = (e) => {
    document.getElementById("playaudio1").play();
  };
  const playaudioSpeech2 = (e) => {
    document.getElementById("playaudioSpeech2").play();
  };
  const playaudioSpeech3 = (e) => {
    document.getElementById("playaudioSpeech3").play();
  };

  const playClip2 = (e) => {
    document.getElementById("playaudio2").play();
  };
  const playClip3 = (e) => {
    document.getElementById("playaudio3").play();
  };
  const searchRootFunction = () => {
    var filter, i, txtValue;
    filter = searchRootList.toUpperCase() || "#";
    for (i = 0; i < allRootList.length; i++) {
      txtValue = allRootList[i];
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        filterRootsArr.push(txtValue.replace(".json", ""));
      }
    }
  };

  const searchFunction = () => {
    var filter, i, txtValue;
    filter = searchWordList.toUpperCase() || "#";
    for (i = 0; i < allWordList.length; i++) {
      txtValue = allWordList[i];
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        wordSyllables.push(txtValue.replace(".json", ""));
      }
    }
  };

  const searchWordListByTittleFunction = () => {
    var filter, i, txtValue;
    filter = wordlistbytitle.toLowerCase() || "##";
    for (i = 0; i < allLiteratureLists.length; i++) {
      let text = allLiteratureLists[i];
      txtValue = allLiteratureLists[i].toLowerCase();
      if (txtValue.indexOf(filter) > -1) {
        literatureLists.push(text);
      }
    }
  };

  // const searchBookScanWordListByTittleFunction = () => {
  //   var filter, i, txtValue;
  //   filter = wordlistbyBookName.toLowerCase() || "##";
  //   for (i = 0; i < allBookScanLists.length; i++) {
  //     let text = allBookScanLists[i];
  //     txtValue = allBookScanLists[i].toLowerCase();
  //     if (txtValue.indexOf(filter) > -1) {
  //       bookScanLists.push(text);
  //     }
  //   }
  // };

  const handleSubmitWordListBtn = (evt) => {
    evt.preventDefault();
    setWordsArrToDisplay([]);
    setHeadWordsArrToDisplay([]);
    setWordSyllables([]);
    if (searchWordList === "" || searchWordList.replace(/\s/g, "") === "") {
      return toast.error(`Please enter a word list`);
    }
    if (!/[a-zA-Z]/.test(searchWordList)) {
      return toast.error(`Please enter a valid word list`);
    }
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      fetch(process.env.REACT_APP_API_URL + "cognatelists/" + searchWordList, {
        headers,
      })
        .then((res) => res.json())
        .then((data) => {
          // ryisa's working phase
          if (data === "This wordlist does not exist") {
            setWordListWords([]);
            let arr = [searchWordList + ": This wordlist does not exist"];
            setWordListNameArr(arr);
            setSearchWordList("");
            setWordList("");
          } else {
            setWordListWords([]);
            let arr1 = [searchWordList];
            // setWordsArrToDisplay(emptyArr);
            setWordsObjToDisplay(data.output);
            setAverageWordFrequency(data.averageWordFrequency);
            // setWordsArrToDisplay(data.headwords);
            // setHeadWordsArrToDisplay(data.headwords)
            setWordListNameArr(arr1);
            setSearchWordList("");
            setWordList("");
            setWordSyllables([]);
          }
        });
    }
  };
  const handleSubmitSearchByRoot = (evt) => {
    const filteredData = allRootList.filter((item) =>
      item.exact_root.toLowerCase() == searchbyroot.toLowerCase()
    );
    setFilteredData(filteredData);

    return
    evt.preventDefault();
    setRootWordsArrToDisplay([]);
    setRootFamily({});
    setFilterRootsArr([]);
    if (searchbyroot === "" || searchbyroot.replace(/\s/g, "") === "") {
      return toast.error("Please enter a valid root list", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };

      axios
        .post(
          process.env.REACT_APP_API_URL + "root_family",
          { searchbyroot },
          { headers }
        )
        .then((res) => {
          if (res.data.code !== 200) {
            return toast.error(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            setFilterRootsArr(res.data.data);
          }
        })
        .then((res) => { });
    }
  };
  const handleSubmitRootListBtn = (evt) => {
    evt.preventDefault();
    setRootWordsArrToDisplay([]);
    setFilterRootsArr([]);
    if (searchRootList === "" || searchRootList.replace(/\s/g, "") === "") {
      return toast.error(`Please enter a root list`);
    }
    if (!/[a-zA-Z]/.test(searchRootList)) {
      return toast.error(`Please enter a valid root list`);
    }
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      fetch(process.env.REACT_APP_API_URL + "roots/" + searchRootList, {
        headers,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data === "This root meaning does not exist") {
            // setWordListWords([])
            let arr = [searchRootList + ": This root meaning does not exist"];
            // setWordListNameArr(arr)
            setSearchRootList("");
            // setWordList('')
          } else if (data.words) {
            // setWordListWords([])
            let arr1 = [searchRootList];
            setRootWordsArrToDisplay(data.words);
            // setWordListNameArr(arr1)
            setSearchRootList("");
            // setWordList('')
            // setWordSyllables([])
          }
        });
    }
  };
  const sortCognateListFunction = () => {
    if (Object.keys(wordsObjToDisplay).length > 0) {
      if (sortCognateList) {
        const arrayOfObjects = Object.keys(wordsObjToDisplay).sort((a, b) => {
          // Convert names to lowercase before comparison for case-insensitive sorting
          const nameA = a.word.toLowerCase();
          const nameB = b.word.toLowerCase();

          if (nameA < nameB) {
            return -1; // a should come before b
          } else if (nameA > nameB) {
            return 1; // b should come before a
          } else {
            return 0; // names are equal
          }
        });
        setWordsObjToDisplay(arrayOfObjects);
        setSortCognateList(!sortCognateList)
      }
    }
  }
  const handleSearchSubmitWordList = (evt) => {
    evt?.preventDefault();
    setWordsArrToDisplay([]);
    setHeadWordsArrToDisplay([]);
    // get the wordlistname
    let wordListNameStore;
    if (evt == undefined) {
      wordListNameStore = JSON.parse(localStorage.getItem("wordListNameArr"));
    } else {
      wordListNameStore = evt.target.textContent;
    }
    localStorage.setItem("wordListNameArr", JSON.stringify(wordListNameStore));
    if (
      wordListNameStore === "" ||
      wordListNameStore.replace(/\s/g, "") === ""
    ) {
      return toast.error(`Please enter a word list`);
    }
    if (!/[a-zA-Z]/.test(wordListNameStore)) {
      return toast.error(`Please enter a valid word list`);
    }
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      fetch(
        process.env.REACT_APP_API_URL + "cognatelists/" + wordListNameStore,
        { headers }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.code !== 200) {
            return toast.error(data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            setNewRootFamilyStory(data.rootFamilyStory);
            setWordsObjToDisplay(data.output);
            data?.output.forEach((word) => {
              loadAudio(word.word, word.word_id);
            });
            setAverageWordFrequency(data.averageWordFrequency);
            // setWordListWords(data.words);
            setWordListName(wordListNameStore);
            let arr1 = [wordListNameStore];
            // setWordsArrToDisplay(data.words);
            // setHeadWordsArrToDisplay(data.headwords);
            setWordListNameArr(arr1);
            setSearchWordList("");
            setWordList("");
            setWordSyllables([]);
          }
        });
    }
  };
  const handleSearchSubmitRoot = (item) => {
    // evt.preventDefault();
    setRootWordsArrToDisplay([]);
    setRootFamily({});
    if (
      Object.keys(item).length === 0
    ) {
      return toast.error(`Please enter a root list`);
    }
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      axios.post(process.env.REACT_APP_API_URL + "roots/root_families", item, {
        headers,
      })
        .then((res) => {
          if (res.data.code !== 200) {
            return toast.error(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          }
          let data = res.data.data
          setRootWordsArrToDisplay(data.words);
          // setWordListWords(data.words)
          // setWordListName(evt.target.textContent)
          // let arr1 = [evt.target.textContent]
          // setWordsArrToDisplay(data.words)
          // setWordListNameArr(arr1)
          setSearchRootList("");
          setFilterRootsArr([]);
          // setWordList('')
          setRootFamily(data);
          setFilteredData([]);
          setSearchTerm("")
        });
    }
  };

  const fetchAllWordlists = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + currentUser.accessToken,
    };
    fetch(process.env.REACT_APP_API_URL + "wordlists", { headers })
      .then((res) => res.json())
      .then((data) => {
        setAllWordList(data);
      });
  };

  const fetchAllLiteratureWordlists = () => {
    setliteratureWordlistSpiner(true)
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + currentUser.accessToken,
    };
    fetch(process.env.REACT_APP_API_URL + "coreData/literature/wordlists", {
      headers,
    })
      .then((res) => res.json())
      .then((data) => {
        setAllLiteratureLists(Object.keys(data));
        setliteratureWordlistSpiner(false)
      });
  };

  const fetchRootsAndRootDescription = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + currentUser.accessToken,
    };
    // fetch(process.env.REACT_APP_API_URL + "roots", { headers })
    //   .then((res) => res.json())
    //   .then((data) => {
    // setAllRootList(data);
    fetch(process.env.REACT_APP_API_URL + "rootsDescription", {
      headers,
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log('data.rootFamilies',data.rootFamilies);
        setAllRootList(data.rootFamilies);
        setAllRootsObject(data.roots);
        let sortedRoots = [];
        for (const key in data.roots["prefix"]) {
          sortedRoots.push([data.roots["prefix"][key]["description"], key]);
        }
        sortedRoots.sort((a, b) => {
          if (a[0].toLowerCase() > b[0].toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setAllRoots(sortedRoots);
        setFoundRoots(sortedRoots);
      });
    // });
  };
  const fetchBookScanLists = () => {
    // setAllRootList(data);
    let arrs = []
    fetch(process.env.REACT_APP_API_URL + "bookScanLists")
      .then((res) => res.json())
      .then((data1) => {
        const data = data1.data
        data.forEach(element => {
          if (element?.title !== undefined) {
            arrs.push(element?.title);
          }
        });
        setAllBookScanLists(arrs);
      });
  }

  const fetchOrigins = () => {
    // setAllRootList(data);
    let arrs = []
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + currentUser.accessToken,
    };
    fetch(process.env.REACT_APP_API_URL + "adminDashboard/origins", { headers })
      .then((res) => res.json())
      .then((data1) => {
        const data = data1.data
        let arr1 = []
        let arr2 = []
        data?.borrowingLanguage?.forEach(element => {
          arr1.push({ label: element.name, value: element.origin_language_id })
        });
        setBorrowingLanguage(arr1);

        data?.englishPeriodOrings?.forEach(element => {
          arr2.push({ label: element.name, value: element.english_period_id, description: element.description })
        });
        setEnglishPeriodOrigins(arr2);
      });
  }

  useEffect(() => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      handleSubmit();
      fetchRootsAndRootDescription();
      fetchAllWordlists();
      fetchAllLiteratureWordlists();
      fetchBookScanLists();
      fetchOrigins();
    }
  }, []);

  const handleSubmitWordListByWord = (evt) => {
    evt.preventDefault();
    setWordsArrToDisplay([]);
    setHeadWordsArrToDisplay([]);

    if (wordlistbyword === "" || wordlistbyword.replace(/\s/g, "") === "") {
      return toast.error(`Please enter a word`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (!/[a-zA-Z]/.test(wordlistbyword)) {
      return toast.error(`Please enter a valid word`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      fetch(
        process.env.REACT_APP_API_URL + "wordlist_by_word/" + wordlistbyword,
        {
          headers,
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.code !== 200) {
            return toast.error(data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            setWordSyllables(data.data);
          }
        });
    }
  };

  const handleSubmitWordListByTitle = (evt) => {
    evt.preventDefault();
    setWordsArrToDisplay([]);
    setHeadWordsArrToDisplay([]);
    setWordListByTitle("");
    if (wordlistbytitle === "" || wordlistbytitle.replace(/\s/g, "") === "") {
      return toast.error(`Please enter a word`);
    }
    if (!/[a-zA-Z]/.test(wordlistbytitle)) {
      return toast.error(`Please enter a valid word`);
    }
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      fetch(
        process.env.REACT_APP_API_URL + "literaturelists/" + wordlistbytitle,
        { headers }
      )
        .then((res) => res.json())
        .then((data) => {
          let arr = [];
          let edit = (
            <IconButton
              color="primary"
              aria-label="edit"
              onClick={() => {
                setNewLessonWordlistName(data["word_list_name"]);
                setNewLessonWordlistTitle(data["title"]);
                setNewLessonWordlistAuthor(data["author"]);
                setOpenModalModifyLessonWordlist(true);
                setOpenModalWordList(true);
              }}
            >
              <Edit />
            </IconButton>
          );
          arr.push([data["title"], data["author"], data["status"], edit]);
          setLiteratureListsArr(arr);
          setLiteratureLists([]);
          setLiteratureListsTableArr(data.words.wordTables);
        });
    }
  };
  const showFetchOtherFormsSentences = () => {
    let inputElements = document.getElementById("toggleSwitchSentences");
    let status = inputElements.checked ? true : false;
    setFetchOtherFormsSentences(status);
  };
  const showWordlistAttributes = (e) => {
    let inputElements = document.getElementById("bookscanStatus");
    let status = inputElements.checked ? 1 : 0;
    setLegacyLiteratureStatus(status);
    setLegacyLiteratureWord_list_id(inputElements.value);
    // if (status == 1) {
    //   return toast.warning("The list is already deactivated.", {
    //     position: toast.POSITION.TOP_CENTER,
    //   });
    // } else {
    setOpenModalWordList(true);
    // }
  };
  const handleOnChangeModifyLegacyLiterature = (e) => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      return toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (newLessonWordlistAuthor == "" || newLessonWordlistName == "" || newLessonWordlistTitle == "") {
      return toast.error("Please fill out all required fields.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      let oldWordlistName = literatureListsArr[0][0].replace(
        /[`~!@#$%^&*()/_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
        ""
      );
      axios
        .put(
          process.env.REACT_APP_API_URL +
          "update-legacyLiterature", { oldWordlistName, newLessonWordlistAuthor, newLessonWordlistName, newLessonWordlistTitle },
          {
            headers,
          }
        )
        .then((res) => {
          if (res.data.code !== 200) {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
            handleSubmitLiteratureWordListByTitle(newLessonWordlistName)
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("Something went wrong. Please try again", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
    setOpenModalWordList(false);
    setOpenModalModifyLessonWordlist(false);

  }
  const handleOnChangeLegacyLiteratureStatus = (e) => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      return toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      let name = literatureListsArr[0][0].replace(
        /[`~!@#$%^&*()/_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
        ""
      );
      axios
        .put(
          process.env.REACT_APP_API_URL +
          "update-legacyLiterature-status/" +
          name,
          {
            headers,
          }
        )
        .then((res) => {
          if (res.data.code !== 200) {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
            handleSubmitLiteratureWordListByTitle(name)
          }
        })
        .catch((error) => {
          toast.error("Something went wrong. Please try again", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
    setOpenModalWordList(false);

  };

  const handleOnChangeBookscanStatus = (e) => {
    let title = document.getElementById("bookscanStatus").value;
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      axios
        .put(
          process.env.REACT_APP_API_URL + "update-bookscan-status/" + title,
          {
            headers,
          }
        )
        .then((res) => {
          if (res.data.code !== 200) {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
            const data = res.data.data;
            let status = (
              <ToggleSwitch
                value={title}
                status={data["status"]}
                handleChange={handleOnChangeBookscanStatus}
              />
            );
            let arr = [];
            arr.push([title, data["author"], data["type"]]);
            setBookScanListsArr(arr);
          }
        })
        .catch((error) => {
          toast.error("Something went wrong. Please try again", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  const fetchCousinsInTheBook = (title, words) => {
    setIsFetchingBookCousinCouns(true);
    setBookCousinsCount({});
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "coreData/cousins-book", { title, words },
          {
            headers,
          }
        )
        .then((res) => {
          let data = res.data.data;
          setBookCousinsCount(data);
          setIsFetchingBookCousinCouns(false);
        })
        .catch((error) => {
          setIsFetchingBookCousinCouns(false);

        });
    }
  }

  const handleSubmitBookScanWordlistByTitle = (evt, title) => {
    // evt.preventDefault();
    setBookscanWordlistSpiner(true)
    setBookscanTitle("");
    setWordsArrToDisplay([]);
    setBookScanLists([]);
    setHeadWordsArrToDisplay([]);
    setWordlistByBookName("");
    setIsStudyList(false);
    setBookScanListsTableArrSaved([]);
    let input;
    if (title === undefined) {
      input = evt.target.textContent;
      localStorage.setItem(
        "bookscanWordListByName",
        JSON.stringify(evt.target.textContent)
      );
    } else {
      input = title;
    }
    if (
      input.replace(/#/g, "") === "" ||
      input.replace(/#/g, "").replace(/\s/g, "") === ""
    ) {
      return toast.error("Please enter the book title", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      fetch(
        process.env.REACT_APP_API_URL +
        "bookScanLists/" +
        input.replace(/#/g, ""),
        { headers }
      )
        .then((res) => res.json())
        .then((data1) => {
          if (data1.code !== 200) {
            return toast.error(data1.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            const data = data1.data;
            if (data.words?.length > 0) {
              const transformedArray = data.words.map(obj => ({
                word: obj.word,
                wordlists: obj.wordlists
              }));
              fetchCousinsInTheBook(input, transformedArray);
            }
            let status = (
              <ToggleSwitch
                value={data["title"]}
                status={data["status"]}
                handleChange={handleOnChangeBookscanStatus}
              />
            );
            let array = [];
            let arr = [];
            // for (const key in object) {
            arr.push([data["title"], data["author"], data["type"]]);
            // }
            let type = data["type"].toLowerCase() != "master" ? true : false;
            setIsStudyList(type);
            setBookScanListsArr(arr);
            setBookScanListsTableArr(data.words);
            data?.words.forEach((word) => {
              loadAudio(word.word, word.word_id);
            });
            setBookScanListsTableArrSaved(data.wordsSaved);
            setBookScanLists([]);
          }
          setAddWordWorlist("");
          setBookscanWordlistSpiner(false);
        });
    }
  };

  const handleSubmitLiteratureWordListByTitle = (literatureWordListByName) => {
    setWordsArrToDisplay([]);
    setLiteratureLists([]);
    setHeadWordsArrToDisplay([]);
    setWordListByTitle("");
    setLiteratureListsTableArr([]);
    setliteratureWordlistSpiner(true)
    // let literatureWordListByName;
    // if (evt == undefined) {
    //   literatureWordListByName = JSON.parse(
    //     localStorage.getItem("literatureWordListByName")
    //   );
    // } else {
    //   literatureWordListByName = evt.target.textContent;
    // }
    localStorage.setItem(
      "literatureWordListByName",
      JSON.stringify(literatureWordListByName)
    );
    if (
      literatureWordListByName.replace(/#/g, "") === "" ||
      literatureWordListByName.replace(/#/g, "").replace(/\s/g, "") === ""
    ) {
      return toast.error(`Please enter a word`);
    }
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      const inputValue = literatureWordListByName.replace(
        /[`~!@#$%^&*()/_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
        ""
      );
      fetch(process.env.REACT_APP_API_URL + "literaturelists/" + inputValue, {
        headers,
      })
        .then((res) => res.json())
        .then((data1) => {
          const hideStatus = data1.hideStatus;
          if (data1.code !== 200) {
            if (data1.hideStatus == false) {
              return toast.error(data1.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              let arr = [];
              let data2 = data1.data;
              let edit = (
                <IconButton
                  color="primary"
                  aria-label="edit"
                  onClick={() => {
                    setNewLessonWordlistName(data2["word_list_name"]);
                    setNewLessonWordlistTitle(data2["title"]);
                    setNewLessonWordlistAuthor(data2["author"]);
                    setOpenModalModifyLessonWordlist(true);
                    setOpenModalWordList(true);
                  }}
                >
                  <Edit />
                </IconButton>
              );
              let status =
                hideStatus == false ? (
                  <ToggleSwitch
                    value={data2["word_list_id"]}
                    name={data2["word_list_name"]}
                    status={data2.active == 1 ? true : false}
                    handleChange={showWordlistAttributes}
                  />
                ) : (
                  ""
                );
              arr.push([
                data2["word_list_name"],
                data2["title"],
                data2["author"],
                status,
                edit
              ]);
              setLiteratureListsTableArr(data2.words.wordTables);
              setLiteratureListsArr(arr);
              setLiteratureLists([]);
              // setLiteratureListsTableArr(data2.wordTables);
              return toast.error(data1.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          } else {
            const data = data1.data.words;
            const data2 = data1.data;
            let array = [];
            let count = 0;
            data.wordTables.forEach((word) => {
              count += 1;
              array.push([
                <input type="checkbox"></input>,
                count,
                <Link to={`/admin/words/${word}`}>{word}</Link>,
                "",
                "✓",
                "",
                "✓",
                "✓",
                "✓",
                "",
              ]);
            });
            let arr = [];
            // for (const key in object) {
            let status = (
              <ToggleSwitch
                value={data2["word_list_id"]}
                name={data2["title"]}
                status={data2.active == 1 ? true : false}
                handleChange={showWordlistAttributes}
              />
            );
            let edit = (
              <IconButton
                color="primary"
                aria-label="edit"
                onClick={() => {
                  setNewLessonWordlistName(data2["word_list_name"]);
                  setNewLessonWordlistTitle(data2["title"]);
                  setNewLessonWordlistAuthor(data2["author"]);
                  setOpenModalModifyLessonWordlist(true);
                  setOpenModalWordList(true);
                }}
              >
                <Edit />
              </IconButton>
            );
            arr.push([
              data2["word_list_name"],
              data2["title"],
              data2["author"],
              status,
              edit
            ]);
            // }
            setLiteratureWordlistWords(array);
            setLiteratureListsArr(arr);
            setLiteratureLists([]);
            setLiteratureListsTableArr(data.wordTables);
            data?.wordTables.forEach((word) => {
              loadAudio(word.word, word.word_id);
            });
          }
          setliteratureWordlistSpiner(false);
        });
    }
  };

  const classes = useStyles();
  const [open, setOpen] = React.useState(null);
  const handleToggle = (event) => {
    if (open && open.contains(event.target)) {
      setOpen(null);
    } else {
      setOpen(event.currentTarget);
    }
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <div>
      {/* <Header/> */}
      <div className="form-group">
        <ToastContainer />
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title=""
            headerColor="primary"
            tabs={[
              {
                tabName: "Words",
                tabIcon: Book,
                tabContent: (
                  <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.searchWrapper}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <div className={classes.coreSearchWrapper}>
                            <div style={{ width: "40%" }}>
                              <CustomInput
                                formControlProps={{
                                  className:
                                    classes.margin + " " + classes.search,
                                }}
                                labelText="Type in a word here"
                                id="float"
                                inputProps={{
                                  // placeholder: "Type in a word here",
                                  inputProps: {
                                    "aria-label": "Search",
                                  },
                                  value: word,
                                  onKeyPress: (e) => {
                                    if (e.charCode === 13) {
                                      handleSubmit(e);
                                    }
                                  },
                                  onChange: (e) => {
                                    setWord(e.target.value.toLocaleLowerCase());
                                    setWordBook(
                                      e.target.value.toLocaleLowerCase()
                                    );
                                  },
                                }}
                              />
                            </div>
                            <IconButton
                              style={{
                                color: "#FFCD00",
                              }}
                              onClick={handleSubmit}
                            >
                              <CheckCircleIcon />
                            </IconButton>
                            <span>
                              {loading ? (
                                <ClipLoader
                                  color={"#BB2BFF"}
                                  loading={loading}
                                  size={20}
                                />
                              ) : (
                                ""
                              )}
                            </span>
                            {selectedPOSArrays &&
                              selectedPOSArrays?.length > 1 ? (
                              <div style={{ zIndex: 100000 }}>
                                <span className={classes.titleColor}>
                                  Select the POS to display
                                </span>
                                <Select
                                  defaultValue={selectedPOS}
                                  onChange={(e) => {
                                    setSelectedPOS(e.value);
                                  }}
                                  options={selectedPOSArrays}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </GridItem>
                      </GridContainer>
                    </div>
                    <CustomTabs
                      title=""
                      headerColor="primary"
                      showShadow={true}
                      tabs={[
                        {
                          tabName: "Dictionary",
                          tabContent: (
                            <div
                              style={{
                                padding: "0px 15px",
                              }}
                            >
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                  <Card
                                    style={{
                                      boxShadow: "none",
                                      marginBottom: 0,
                                    }}
                                  >
                                    <CardHeader
                                      color="primary"
                                      style={{
                                        marginTop: "0px",
                                      }}
                                      className={classes.dictCardHeader}
                                    >
                                      <div
                                        className={
                                          classes.dictCardHeaderWrapper
                                        }
                                      >
                                        <div
                                          style={{}}
                                          className={classes.cardTitleWhite}
                                        >
                                          Head word: {headWord}
                                        </div>

                                        <div
                                          style={{
                                            fontSize: "11px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {/* <div className="wordfamilydiv"> */}
                                          <div className="wordfamilydiv">
                                            <a
                                              style={{ cursor: "pointer" }}
                                              color={
                                                window.innerWidth > 959
                                                  ? "transparent"
                                                  : "white"
                                              }
                                              aria-owns={
                                                open ? "menu-list-grow" : null
                                              }
                                              aria-haspopup="true"
                                              onClick={handleToggle}
                                              className={classes.buttonLink}
                                            >
                                              <span id="wordfamilypara">
                                                View other words in this word
                                                Family
                                              </span>
                                              <Hidden mdUp implementation="css">
                                                <p
                                                  onClick={handleClose}
                                                  className={classes.linkText}
                                                />
                                              </Hidden>
                                            </a>
                                            <Poppers
                                              open={Boolean(open)}
                                              anchorEl={open}
                                              transition
                                              disablePortal
                                              id="wordfamilydiv"
                                              className={
                                                classNames({
                                                  [classes.popperClose]: !open,
                                                }) +
                                                " " +
                                                classes.pooperNav
                                              }
                                            >
                                              {({
                                                TransitionProps,
                                                placement,
                                              }) => (
                                                <Grow
                                                  {...TransitionProps}
                                                  id="menu-list-grow"
                                                  style={{
                                                    transformOrigin:
                                                      placement === "bottom"
                                                        ? "center top"
                                                        : "center bottom",
                                                  }}
                                                >
                                                  <Paper>
                                                    <ClickAwayListener
                                                      onClickAway={handleClose}
                                                    >
                                                      <MenuList
                                                        role="menu"
                                                        style={{
                                                          textTransform: "none",
                                                        }}
                                                      >
                                                        {wordFamily.map(
                                                          function (item, i) {
                                                            return (
                                                              <MenuItem
                                                                key={i}
                                                                onClick={
                                                                  handleClose
                                                                }
                                                                className={
                                                                  classes.dropdownItem
                                                                }
                                                              >
                                                                {item.word}
                                                              </MenuItem>
                                                            );
                                                          }
                                                        )}
                                                        {/* </ul> */}
                                                      </MenuList>
                                                    </ClickAwayListener>
                                                  </Paper>
                                                </Grow>
                                              )}
                                            </Poppers>
                                          </div>
                                          {/* </div> */}
                                        </div>
                                      </div>
                                    </CardHeader>
                                    <CardBody>
                                      <GridContainer>
                                        <br />
                                        <GridItem xs={12} sm={12} md={8}>
                                          <div
                                            className={classes.dictFormWrapper}
                                          >
                                            <FormControl
                                              variant="standard"
                                              className={classes.dictFormText}
                                            >
                                              <Input
                                                id="component-helper"
                                                placeholder="Syllable"
                                                value={syllable}
                                                onChange={(e) => {
                                                  setSyllable(e.target.value);
                                                  setMeaningChange(true);
                                                }}
                                                aria-describedby="component-helper-text"
                                                style={{
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                }}
                                              />
                                              <FormHelperText
                                                id="component-helper-text"
                                                style={{
                                                  fontWeight: 500,
                                                  fontSize: "11px",
                                                  color: "#000000",
                                                }}
                                                className={
                                                  classes.speechTextInput
                                                }
                                              >
                                                Syllables
                                              </FormHelperText>
                                            </FormControl>
                                            {/* <div>
                                                some text
                                                {googleSyllable}
                                              </div> */}
                                            <FormControl
                                              variant="standard"
                                              className={classes.dictFormText}
                                            >
                                              <Input
                                                id="component-helper"
                                                placeholder="Frequency"
                                                value={frequency}
                                                onChange={(e) => {
                                                  setFrequency(e.target.value);
                                                  setMeaningChange(true);
                                                }}
                                                aria-describedby="component-helper-text"
                                                style={{
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                }}
                                              />
                                              <FormHelperText
                                                id="component-helper-text"
                                                style={{
                                                  fontWeight: 500,
                                                  fontSize: "11px",
                                                  color: "#000000",
                                                }}
                                                className={
                                                  classes.speechTextInput
                                                }
                                              >
                                                Frequency
                                              </FormHelperText>
                                            </FormControl>
                                          </div>
                                        </GridItem>
                                      </GridContainer>
                                      <br />

                                      <GridContainer>
                                        <GridItem
                                          xs={12}
                                          sm={12}
                                          md={8}
                                          style={{
                                            marginTop: "25px",
                                          }}
                                        >
                                          <div
                                            className={classes.dictRadioWrapper}
                                          >
                                            <Mradio
                                              checked={
                                                radioSelectDictionary ===
                                                  "legacy"
                                                  ? true
                                                  : false
                                              }
                                              value="legacy"
                                              name="radio-buttons"
                                              inputProps={{
                                                "aria-label": "Legacy Data",
                                              }}
                                              color="primary"
                                              onChange={(event) => {
                                                setradioSelectDictionary(
                                                  event.target.value
                                                );
                                              }}
                                              sx={{
                                                color: "#34566A",
                                                "&.Mui-checked": {
                                                  color: "#34566A",
                                                },
                                              }}
                                            />
                                            <Input
                                              id="standard-adornment-legacyData"
                                              defaultValue="Legacy Data"
                                              inputProps={{
                                                readOnly: true,
                                                name: "def",
                                                "aria-label": "legacy data",
                                              }}
                                              endAdornment={
                                                audioIsFound && (
                                                  <InputAdornment position="end">
                                                    {audio ? (
                                                      <span>
                                                        <audio
                                                          id="playaudio1"
                                                          src={
                                                            process.env
                                                              .REACT_APP_S3_BASE_URL +
                                                            word[0] +
                                                            "/" +
                                                            word +
                                                            "_" +
                                                            wordId +
                                                            ".mp3"
                                                          }
                                                        />
                                                        <IconButton
                                                          id="playaudio1"
                                                          aria-label="volume"
                                                          size="small"
                                                          onClick={playClip1}
                                                        >
                                                          <VolumeUpIcon
                                                            sx={{
                                                              color: "#34566A",
                                                            }}
                                                          />
                                                        </IconButton>
                                                      </span>
                                                    ) : (<>
                                                      {audioWordSpiner && <BeatLoader />}
                                                      <audio
                                                        id="playaudioTts"
                                                        src={
                                                          audioUrl
                                                        }
                                                      />
                                                      <IconButton
                                                        id="playaudio1"
                                                        aria-label="volume"
                                                        size="small"
                                                        onClick={() => speakTextWord(word, 1)}
                                                      >
                                                        <VolumeUpIcon
                                                          sx={{
                                                            color: "#34566A",
                                                          }}
                                                        />
                                                      </IconButton>
                                                      <Select
                                                        value={voiceName}
                                                        onChange={(selectedOption) => {
                                                          setVoiceName(selectedOption);
                                                        }}
                                                        styles={customStyles}
                                                        options={voiceOptions}
                                                      /><br />
                                                      {audioUrl !== '' ?
                                                        <Button
                                                          styles={{ marginLeft: "20px" }}
                                                          color="secondary"
                                                          onClick={() => uploadTTSAudio(1)}
                                                        >
                                                          upload Audio
                                                        </Button> : ''}
                                                    </>)}
                                                  </InputAdornment>
                                                )
                                              }
                                            />
                                          </div>
                                          {wordWithoutID &&
                                            <GridContainer>
                                              <GridItem
                                                xs={12}
                                                sm={12}
                                                md={3}

                                              >
                                                <Button
                                                  color="primary"
                                                  onClick={addIDToWordFunction}
                                                >
                                                  Add ID to this word
                                                </Button>
                                              </GridItem>
                                            </GridContainer>}
                                          <GridContainer className="dictSpeechWrapper">
                                            {
                                              (selectedPOS == "" ||
                                                speechPart.toLowerCase() ==
                                                selectedPOS?.toLowerCase()) ? (
                                                <>

                                                  <span
                                                    className={
                                                      classes.speechpartText
                                                    }
                                                  >
                                                    <FormControl
                                                      disabled
                                                      variant="standard"
                                                    >
                                                      <Input
                                                        id="last-name"
                                                        value={speechPart}
                                                        style={{
                                                          fontSize: "12px",
                                                        }}
                                                      />
                                                    </FormControl>
                                                  </span>
                                                  {word !== "" ?
                                                    (<Grid
                                                      container
                                                      spacing={1}
                                                    >
                                                      <Grid
                                                        item
                                                        xs={4}
                                                        md={4}
                                                        sm={4}
                                                      >
                                                        {/* <Select
                                                      value={speechPart}
                                                      onChange={(e) =>{
                                                        setSpeechPart(
                                                          e.value
                                                        )
                                                        setMeaningChange(true);
                                                      }}
                                                      options={optionPOS}
                                                    /> */}
                                                        <Select
                                                          value={speechPart}
                                                          onChange={(e) => {
                                                            changeWordPOS(
                                                              word,
                                                              e
                                                            )
                                                            setSpeechPart(
                                                              e.value
                                                            )
                                                          }
                                                          }
                                                          options={optionPOS}
                                                        />
                                                      </Grid>
                                                    </Grid>
                                                    ) : ""}


                                                  <GridItem
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                  >
                                                    <InputLabel
                                                      style={{
                                                        color: "#AAAAAA",
                                                      }}
                                                    >
                                                      <br />
                                                      Meaning
                                                    </InputLabel>
                                                    {/* <p>{wordMeaning}</p> */}
                                                    <textarea
                                                      rows={4}
                                                      style={{
                                                        border: "none",
                                                        width: "100%",
                                                        fontSize: "large",
                                                      }}
                                                      name="meaning"
                                                      value={wordMeaning}
                                                      onChange={(e) => {
                                                        setMeaningChange(true);
                                                        setWordMeaning(
                                                          e.target.value
                                                        );
                                                      }}
                                                    />
                                                  </GridItem>

                                                </>
                                              ) : (
                                                ""
                                              )}
                                            {secondSpeechPart &&
                                              (selectedPOS == "" ||
                                                secondSpeechPart.toLowerCase() ==
                                                selectedPOS?.toLowerCase()) ? (
                                              <>
                                                {" "}
                                                {wordMeaning2 ? (
                                                  <GridItem
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                  >
                                                    {secondSpeechPart ? (
                                                      <span
                                                        className={
                                                          classes.speechpartText
                                                        }
                                                      >
                                                        <FormControl
                                                          disabled
                                                          variant="standard"
                                                        >
                                                          <Input
                                                            id="last-name"
                                                            value={
                                                              secondSpeechPart
                                                            }
                                                            style={{
                                                              fontSize: "12px",
                                                            }}
                                                          />
                                                        </FormControl>
                                                      </span>
                                                    ) : (
                                                      <span></span>
                                                    )}
                                                    <InputLabel
                                                      style={{
                                                        color: "#AAAAAA",
                                                      }}
                                                    >
                                                      <br />
                                                      Meaning
                                                    </InputLabel>
                                                    {/* <p>{wordMeaning}</p> */}
                                                    <textarea
                                                      rows={4}
                                                      style={{
                                                        border: "none",
                                                        width: "100%",
                                                        fontSize: "large",
                                                      }}
                                                      name="meaning"
                                                      value={wordMeaning2}
                                                      onChange={(e) => {
                                                        setMeaningChange(true);
                                                        setWordMeaning2(
                                                          e.target.value
                                                        );
                                                      }}
                                                    />
                                                  </GridItem>
                                                ) : (
                                                  <span></span>
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            {thirdSpeechPart &&
                                              (selectedPOS == "" ||
                                                thirdSpeechPart.toLowerCase() ==
                                                selectedPOS?.toLowerCase()) ? (
                                              <>
                                                {" "}
                                                {wordMeaning3 ? (
                                                  <GridItem
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                  >
                                                    {thirdSpeechPart ? (
                                                      <span
                                                        className={
                                                          classes.speechpartText
                                                        }
                                                      >
                                                        <FormControl
                                                          disabled
                                                          variant="standard"
                                                        >
                                                          <Input
                                                            id="last-name"
                                                            value={
                                                              thirdSpeechPart
                                                            }
                                                            style={{
                                                              fontSize: "12px",
                                                            }}
                                                          />
                                                        </FormControl>
                                                      </span>
                                                    ) : (
                                                      <span></span>
                                                    )}
                                                    <InputLabel
                                                      style={{
                                                        color: "#AAAAAA",
                                                      }}
                                                    >
                                                      <br />
                                                      Meaning
                                                    </InputLabel>
                                                    {/* <p>{wordMeaning}</p> */}
                                                    <textarea
                                                      rows={4}
                                                      style={{
                                                        border: "none",
                                                        width: "100%",
                                                        fontSize: "large",
                                                      }}
                                                      name="meaning"
                                                      value={wordMeaning3}
                                                      onChange={(e) => {
                                                        setMeaningChange(true);
                                                        setWordMeaning3(
                                                          e.target.value
                                                        );
                                                      }}
                                                    />
                                                  </GridItem>
                                                ) : (
                                                  <span></span>
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            {/* {firstSpeechPart.partOfSpeech ? (
                                              <span
                                                className={
                                                  classes.speechpartText
                                                }
                                              >
                                                <FormControl
                                                  disabled
                                                  variant="standard"
                                                >
                                                  <InputLabel htmlFor="component-helper">
                                                    1st Part of Speech
                                                  </InputLabel>
                                                  <Input
                                                    id="last-name"
                                                    value={
                                                      firstSpeechPart.partOfSpeech
                                                    }
                                                    style={{ fontSize: "11px" }}
                                                  />
                                                </FormControl>
                                              </span>
                                            ) : (
                                              <span></span>
                                            )}
                                            {firstSpeechPart.meaning ? (
                                              <div
                                                className={
                                                  classes.wordMeaningWrapper
                                                }
                                              >
                                                <Grid item xs={12} md={10}>
                                                  <List>
                                                    <ListItem>
                                                      <ListItemText
                                                        primary={
                                                          firstSpeechPart.meaning
                                                        }
                                                        name="meaning"
                                                        style={{
                                                          fontSize: "12px",
                                                        }}
                                                      />
                                                    </ListItem>
                                                  </List>
                                                </Grid>
                                              </div>
                                            ) : (
                                              <span></span>
                                            )}

                                            {secondSpeechPart.partOfSpeech ? (
                                              <span
                                                className={
                                                  classes.speechpartText
                                                }
                                              >
                                                <FormControl
                                                  disabled
                                                  variant="standard"
                                                >
                                                  <InputLabel htmlFor="component-helper">
                                                    2nd Part of Speech
                                                  </InputLabel>
                                                  <Input
                                                    id="last-name"
                                                    value={
                                                      secondSpeechPart.partOfSpeech
                                                    }
                                                    style={{ fontSize: "11px" }}
                                                  />
                                                </FormControl>
                                              </span>
                                            ) : (
                                              <span></span>
                                            )}
                                            {secondSpeechPart.meaning ? (
                                              <div
                                                className={
                                                  classes.wordMeaningWrapper
                                                }
                                              >
                                                <Grid item xs={12} md={10}>
                                                  <List>
                                                    <ListItem>
                                                      <ListItemText
                                                        primary={
                                                          secondSpeechPart.meaning
                                                        }
                                                        name="meaning"
                                                        style={{
                                                          fontSize: "12px",
                                                        }}
                                                      />
                                                    </ListItem>
                                                  </List>
                                                </Grid>
                                              </div>
                                            ) : (
                                              <span></span>
                                            )}
                                            {thirdSpeechPart.partOfSpeech ? (
                                              <span
                                                className={
                                                  classes.speechpartText
                                                }
                                              >
                                                <FormControl
                                                  disabled
                                                  variant="standard"
                                                >
                                                  <InputLabel htmlFor="component-helper">
                                                    3rd Part of Speech
                                                  </InputLabel>
                                                  <Input
                                                    id="last-name"
                                                    value={
                                                      thirdSpeechPart.partOfSpeech
                                                    }
                                                    style={{ fontSize: "11px" }}
                                                  />
                                                </FormControl>
                                              </span>
                                            ) : (
                                              <span></span>
                                            )}
                                            {thirdSpeechPart.meaning ? (
                                              <div
                                                className={
                                                  classes.wordMeaningWrapper
                                                }
                                              >
                                                <Grid item xs={12} md={10}>
                                                  <List>
                                                    <ListItem>
                                                      <ListItemText
                                                        primary={
                                                          thirdSpeechPart.meaning
                                                        }
                                                        name="meaning"
                                                        style={{
                                                          fontSize: "12px",
                                                        }}
                                                      />
                                                    </ListItem>
                                                  </List>
                                                </Grid>
                                              </div>
                                            ) : (
                                              <span></span>
                                            )} */}
                                          </GridContainer>
                                        </GridItem>

                                        <GridItem
                                          xs={12}
                                          sm={12}
                                          md={3}
                                          style={{
                                            marginTop: "25px",
                                          }}
                                        >
                                          {audio && (
                                            <Button
                                              styles={{ marginLeft: "20px" }}
                                              color="primary"
                                              onClick={() => setAudio(false)}
                                            >
                                              Edit Audio
                                            </Button>)}
                                        </GridItem>


                                        <br />
                                        <br />
                                      </GridContainer>
                                    </CardBody>
                                    <CardFooter>
                                      <GridContainer>
                                        <GridItem
                                          xs={12}
                                          sm={12}
                                          md={7}

                                        >

                                          {(wordMeaning && meaningChange) ||
                                            meaningChange ? (
                                            <Button
                                              color="secondary"
                                              onClick={updateWordJson}
                                            >
                                              Update Word
                                            </Button>
                                          ) : (
                                            <Button color="secondary" disabled>
                                              Update Word
                                            </Button>
                                          )}
                                        </GridItem>
                                        <GridItem
                                          xs={12}
                                          sm={12}
                                          md={3}

                                        >
                                          <Button
                                            color="primary"
                                            onClick={(e) => {
                                              setAddWordDialog(true);
                                              setIsNewWordToform(false);
                                            }}
                                          >
                                            Add new Word
                                          </Button>
                                        </GridItem>
                                      </GridContainer>
                                    </CardFooter>
                                  </Card>
                                </GridItem>
                              </GridContainer>

                            </div>
                          ),
                        },
                        {
                          tabName: "Word Family",
                          tabContent: (
                            <div style={{ paddingTop: "3rem" }}>
                              <Grid container spacing={2}>
                                <Grid item xs={8} md={8} sm={8}>
                                  <div>
                                    <FormControl
                                      variant="standard"
                                      sx={{ m: 1, width: "60%" }}
                                      className={classes.dictFormText}
                                    >
                                      <Input
                                        disabled
                                        readOnly={true}
                                        id="component-helper"
                                        defaultValue="Head Word"
                                        aria-describedby="component-helper-text"
                                        style={{
                                          fontWeight: 400,
                                          fontSize: "15px",
                                        }}
                                      />
                                      <FormHelperText
                                        id="component-helper-text"
                                        style={{
                                          fontWeight: 600,
                                          fontSize: "15px",
                                          color: "#34566A",
                                          marginTop: "10px",
                                        }}
                                        className={classes.speechTextInput}
                                      >
                                        {headWord && headWord}
                                      </FormHelperText>
                                      {otherForms?.length > 0 &&
                                        <>
                                          <span className={classes.titleColor}>
                                            Replace Headword
                                          </span>

                                          <Select
                                            defaultValue={pos}
                                            onChange={(e) =>
                                              changeHeadword(
                                                headWord,
                                                e.value
                                              )
                                            }
                                            options={otherForms}
                                          /></>
                                      }
                                    </FormControl>
                                  </div>

                                  <div>
                                    <FormControl
                                      variant="standard"
                                      fullWidth
                                      sx={{ m: 1, width: "60%" }}
                                      className={classes.dictFormText}
                                    >
                                      <Input
                                        disabled
                                        readOnly={true}
                                        id="component-helper"
                                        defaultValue="Other Forms"
                                        aria-describedby="component-helper-text"
                                        style={{
                                          fontWeight: 400,
                                          fontSize: "15px",
                                        }}
                                      />
                                      <MenuList
                                        role="menu"
                                        style={{
                                          marginTop: "-2px",
                                        }}
                                      >
                                        {wordFamily &&
                                          wordFamily.map(function (item, i) {
                                            return (
                                              <Grid
                                                container
                                                spacing={1}
                                                key={i}
                                              ><Grid
                                              item
                                              xs={12}
                                              md={3}
                                              sm={3}
                                            >
                                              <Select
                                                defaultValue={separateWordFamilies}
                                                onChange={(e) =>
                                                  allowSeperateWordFamilies(word,item.word,e)
                                              
                                                }
                                                options={optionFamilies}
                                              />
                                            </Grid>
                                                <Grid item xs={12} md={6} sm={6}>
  
                                                  <MenuItem
                                                    // onClick={(e) =>
                                                    //   allowSeperateWordFamilies(item.word,word,1) }
                                                    className={
                                                      classes.dropdownItem
                                                    }
                                                    style={{
                                                      fontWeight: 600,
                                                      fontSize: "15px",
                                                      color: "#34566A",
                                                    }}
                                                  >
                                                    {item.word} |{" "}
                                                    {item.speech_part_name}
                                                    {item.speech_part_name2 &&
                                                      `| ${item.speech_part_name2}`}
                                                    {item.speech_part_name3 &&
                                                      `| ${item.speech_part_name3}`}
                                                  </MenuItem>
                                                 
                                                </Grid>

                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={3}
                                                  sm={3}
                                                >
                                                  <Select
                                                    defaultValue={pos}
                                                    onChange={(e) =>
                                                      changeWordPOS(
                                                        item.word,
                                                        e
                                                      )
                                                    }
                                                    options={optionPOS}
                                                  />
                                                </Grid>

                                              </Grid>
                                            );
                                          })}
                                      </MenuList>
                                    </FormControl>
                                  </div>
                                </Grid>
                                <Grid item xs={2} md={2} sm={2}>
                                  <FormControl
                                    variant="standard"
                                    fullWidth
                                    sx={{ m: 1, width: "60%" }}
                                    className={classes.dictFormText}
                                  >
                                    <Input
                                      disabled
                                      readOnly={true}
                                      id="component-helper"
                                      defaultValue="Part of Speach"
                                      aria-describedby="component-helper-text"
                                      style={{
                                        fontWeight: 400,
                                        fontSize: "15px",
                                      }}
                                    />
                                    <FormHelperText
                                      id="component-helper-text"
                                      style={{
                                        fontWeight: 600,
                                        fontSize: "15px",
                                        color: "#34566A",
                                        marginTop: "10px",
                                      }}
                                      className={classes.speechTextInput}
                                    >
                                      {/* {speechPart
                                        ? speechPart
                                        : googleData["data"] &&
                                          googleData["data"]["partOfSpeech"]
                                        ? Object.keys(
                                            googleData["data"]["partOfSpeech"]
                                          ).map(function (item, i) {
                                            return (
                                              <div key={i}>
                                                <p>
                                                  {
                                                    googleData["data"][
                                                      "partOfSpeech"
                                                    ][item]["speechPart"]
                                                  }
                                                </p>
                                              </div>
                                            );
                                          })
                                        : oxfordSenses
                                        ? oxfordSenses.map((item, i) => {
                                            return (
                                              <p key={i}>
                                                {item.definitions.speechPart}
                                              </p>
                                            );
                                          })
                                        : ""} */}
                                      {headWordSpeechPart && headWordSpeechPart}{" "}
                                      {headWordSpeechPart2 &&
                                        `| ${headWordSpeechPart2}`}
                                      {headWordSpeechPart3 &&
                                        `| ${headWordSpeechPart3}`}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Button
                                  color="primary"
                                  onClick={(e) => {
                                    setAddWordDialog(true);
                                    setIsNewWordToform(true);
                                  }}
                                >
                                  Add word to this form
                                </Button>
                              </Grid>
                            </div>
                          ),
                        },
                        {
                          tabName: "POS Exercise",
                          // tabIcon: Code,setShowSentencePOS
                          tabContent: (
                            // <Grid container spacing={3}>
                            //   <Grid item xs={12} sm={10} md={10}>
                            //     {/* <Grid item xs={12} sm={11} md={10}>
                            //     <TextField
                            //       id="outlined-multiline-static"
                            //       label="Query............"
                            //       multiline
                            //       rows={5}
                            //       fullWidth
                            //       value={promptChatGPTPOS}
                            //       onChange={(e) => setPromptChatGPTPOS(e.target.value)}
                            //     />
                            //   </Grid>
                            //   <Grid item xs={12} sm={11} md={10}>
                            //     <Button
                            //       color={promptChatGPTPOS ? "secondary" : "white"}
                            //       onClick={getChatGPTPOS}
                            //     >
                            //       Submit
                            //     </Button>
                            //   </Grid> */}
                            //     <Typography variant="p" gutterBottom>Other forms : {wordFamily && wordFamily.map((item, index) => (
                            //       <span key={index} style={{ marginRight: '5px' }}>
                            //         {item.word},
                            //       </span>
                            //     ))}</Typography>
                            //     <Typography variant="h6" gutterBottom>Quiz</Typography>
                            //     <Grid container spacing={3}>
                            //       <Grid item xs={12} sm={10} md={10}>
                            //         <TextField
                            //           id="outlined-multiline-static"
                            //           label="Output"
                            //           multiline
                            //           rows={5}
                            //           fullWidth
                            //           variant="outlined"
                            //           value={posExercise.text}
                            //           onChange={(e) => setPosExercise({ ...posExercise, text: e.target.value })}
                            //         />
                            //       </Grid>
                            //       <Grid item xs={12} sm={2} md={2}>
                            //         <Button
                            //           variant="contained"
                            //           color="secondary"
                            //           onClick={handleGenerateQuestions}
                            //           disabled={loadingPOSExercise}
                            //         >
                            //           {loadingPOSExercise ? <CircularProgress size={24} /> : 'Generate Questions'}
                            //         </Button>
                            //       </Grid>
                            //       <Grid item xs={12} sm={1} md={1}>

                            //         <audio
                            //           id="playaudioTtsPOSExercise"
                            //           src={
                            //             audioTtsPOSExerciseurl
                            //           }
                            //         />
                            //         <IconButton
                            //           id="playaudioPOS"
                            //           aria-label="volume"
                            //           size="small"
                            //           // onClick={speakTextPOSExercise}
                            //           onClick={() => speakTextPOSExercise(1)}
                            //         >
                            //           <VolumeUpIcon
                            //             sx={{
                            //               color: "#34566A",
                            //             }}
                            //           />
                            //         </IconButton>
                            //       </Grid>
                            //       <Grid item xs={12} sm={3} md={3}>
                            //         {spinnerTtsPOSExercise ? <CircularProgress size={24} /> : <>
                            //           <Select
                            //             value={voiceName}
                            //             onChange={(selectedOption) => {
                            //               setVoiceName(selectedOption);
                            //             }}
                            //             styles={customStyles}
                            //             options={voiceOptions}
                            //             placeholder="Select a voice"
                            //           />
                            //         </>}
                            //       </Grid>


                            //       {audioTtsPOSExerciseurl &&
                            //         <Grid item xs={6}>
                            //           <Button
                            //             variant="contained"
                            //             color="primary"
                            //             onClick={uploadTTSForPOSExercise}
                            //           >
                            //             Upload audio
                            //           </Button>
                            //         </Grid>}
                            //       {posExercise.quiz_questions.map((question, qIndex) => (
                            //         <Grid item xs={12} key={qIndex}>
                            //           <Box mb={3}>

                            //             <Box mb={2} display="flex" alignItems="center">
                            //               <TextField
                            //                 id={`question-${qIndex}`}
                            //                 label={`Sentence ${qIndex + 1}`}
                            //                 multiline
                            //                 rows={3}
                            //                 fullWidth
                            //                 variant="outlined"
                            //                 value={question.question}
                            //                 onChange={(e) => handleInputChangePosExercise(e, qIndex, 'question')}
                            //               />
                            //               {posExercise.quiz_questions.length > 1 && (
                            //                 <IconButton
                            //                   aria-label="delete"
                            //                   color="secondary"
                            //                   onClick={() => handleDeleteQuestionPosExercise(qIndex)}
                            //                 >
                            //                   <Delete />
                            //                 </IconButton>
                            //               )}
                            //             </Box>
                            //             <Box mb={2} display="flex" alignItems="center">
                            //               <TextField
                            //                 id={`word-${qIndex}`}
                            //                 label={`Word ${qIndex + 1}`}
                            //                 fullWidth
                            //                 variant="outlined"
                            //                 value={question.word}
                            //                 onChange={(e) => handleInputChangePosExercise(e, qIndex, 'word')}
                            //               />
                            //               {/* {posExercise.quiz_questions.length > 1 && (
                            //                 <IconButton
                            //                   aria-label="delete"
                            //                   color="secondary"
                            //                   onClick={() => handleDeleteQuestionPosExercise(qIndex)}
                            //                 >
                            //                   <Delete />
                            //                 </IconButton>
                            //               )} */}
                            //             </Box>
                            //             <Box mb={2}>
                            //               <Grid container spacing={1}>
                            //                 {question.options.map((option, oIndex) => (
                            //                   <Grid item xs={12} sm={6} md={3} key={oIndex}>
                            //                     <TextField
                            //                       id={`option-${qIndex}-${oIndex}`}
                            //                       label={`Option ${oIndex + 1}`}
                            //                       fullWidth
                            //                       variant="outlined"
                            //                       value={option}
                            //                       onChange={(e) => handleOptionChangePosExercise(e, qIndex, oIndex)}
                            //                       onClick={() => handleOptionClickPosExercise(qIndex, option)} // Add onClick handler
                            //                       InputProps={{
                            //                         style: { cursor: 'pointer' }
                            //                       }}
                            //                       title="Click to set as answer" // Add hover message
                            //                     />
                            //                   </Grid>
                            //                 ))}
                            //               </Grid>
                            //             </Box>
                            //             <Box mb={3}>
                            //               <TextField
                            //                 id={`answer-${qIndex}`}
                            //                 label="Answer"
                            //                 fullWidth
                            //                 variant="outlined"
                            //                 value={question.answer}
                            //                 onChange={(e) => handleInputChangePosExercise(e, qIndex, 'answer')}
                            //               />
                            //             </Box>
                            //           </Box>
                            //         </Grid>
                            //       ))}
                            //       <Grid item xs={12}>
                            //         <Button
                            //           variant="contained"
                            //           color="secondary"
                            //           onClick={handleAddQuestionPosExercise}
                            //           disabled={posExercise.quiz_questions.length >= 10}
                            //         >
                            //           Add Question
                            //         </Button>
                            //       </Grid>
                            //       <Grid item xs={12}>
                            //         <Button
                            //           variant="contained"
                            //           color="primary"
                            //           onClick={handleSavePosExercise}
                            //           disabled={loadingPOSExercise}
                            //         >
                            //           {loadingPOSExercise ? <CircularProgress size={24} /> : 'Save'}
                            //         </Button>
                            //       </Grid>
                            //     </Grid>
                            //   </Grid>
                            // </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={10} md={10}>
                                <Typography variant="h6" gutterBottom>Quiz</Typography>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={10} md={10}>
                                    <TextField
                                      id="outlined-multiline-static"
                                      label="Output"
                                      multiline
                                      rows={5}
                                      fullWidth
                                      variant="outlined"
                                      value={posExercise.text}
                                      onChange={(e) => setPosExercise({ ...posExercise, text: e.target.value })}
                                    />
                                    <Typography variant="p" style={{ backgroundColor: 'yellow' }} gutterBottom>{audioTtsPOSExerciseurlSaved && "TTS Audio is available"}</Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={2} md={2}>
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      onClick={handleGenerateQuestions}
                                      disabled={false} // Adjust according to your loading state
                                    >
                                      Generate Questions
                                    </Button>
                                  </Grid>
                                  <Grid item xs={12} sm={1} md={1}>

                                    <audio
                                      id="playaudioTtsPOSExercise"
                                      src={
                                        audioTtsPOSExerciseurl
                                      }
                                    />
                                    <IconButton
                                      id="playaudioPOS"
                                      aria-label="volume"
                                      size="small"
                                      // onClick={speakTextPOSExercise}
                                      onClick={() => speakTextPOSExercise(1)}
                                    >
                                      <VolumeUpIcon
                                        sx={{
                                          color: "#34566A",
                                        }}
                                      />
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs={12} sm={3} md={3}>
                                    {spinnerTtsPOSExercise ? <CircularProgress size={24} /> : <>
                                      <Select
                                        value={voiceName}
                                        onChange={(selectedOption) => {
                                          setVoiceName(selectedOption);
                                        }}
                                        styles={customStyles}
                                        options={voiceOptions}
                                        placeholder="Select a voice"
                                      />
                                    </>}
                                  </Grid>


                                  {audioTtsPOSExerciseurl &&
                                    <Grid item xs={6}>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={uploadTTSForPOSExercise}
                                      >
                                        Upload audio
                                      </Button>
                                    </Grid>}
                                  {posExercise.quiz_questions.map((question, qIndex) => (
                                    <Grid
                                      item
                                      xs={12}
                                      key={qIndex}
                                      style={{
                                        padding: '16px',
                                        marginBottom: '8px',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        backgroundColor: '#f9f9f9'
                                      }}
                                    >
                                      <Box mb={3}>
                                        <Box mb={2} display="flex" alignItems="center">
                                          <IconButton
                                            aria-label="move-up"
                                            disabled={qIndex === 0}
                                            onClick={() => handleMoveQuestion(qIndex, -1)}
                                          >
                                            <ArrowUpward />
                                          </IconButton>
                                          <TextField
                                            id={`question-${qIndex}`}
                                            label={`Sentence ${qIndex + 1}`}
                                            multiline
                                            rows={3}
                                            fullWidth
                                            variant="outlined"
                                            value={question.question}
                                            onChange={(e) => handleInputChangePosExercise(e, qIndex, 'question')}
                                          />
                                          {posExercise.quiz_questions.length > 1 && (
                                            <IconButton
                                              aria-label="delete"
                                              color="secondary"
                                              onClick={() => handleDeleteQuestionPosExercise(qIndex)}
                                            >
                                              <Delete />
                                            </IconButton>
                                          )}
                                          <IconButton
                                            aria-label="move-down"
                                            disabled={qIndex === posExercise.quiz_questions.length - 1}
                                            onClick={() => handleMoveQuestion(qIndex, 1)}
                                          >
                                            <ArrowDownward />
                                          </IconButton>
                                        </Box>
                                        <Box mb={2} display="flex" alignItems="center">
                                          <TextField
                                            id={`word-${qIndex}`}
                                            label={`Word ${qIndex + 1}`}
                                            fullWidth
                                            variant="outlined"
                                            value={question.word}
                                            onChange={(e) => handleInputChangePosExercise(e, qIndex, 'word')}
                                          />
                                        </Box>
                                        <Box mb={2}>
                                          <Grid container spacing={1}>
                                            {question.options.map((option, oIndex) => (
                                              <Grid item xs={12} sm={6} md={3} key={oIndex}>
                                                <TextField
                                                  id={`option-${qIndex}-${oIndex}`}
                                                  label={`Option ${oIndex + 1}`}
                                                  fullWidth
                                                  variant="outlined"
                                                  value={option}
                                                  onChange={(e) => handleOptionChangePosExercise(e, qIndex, oIndex)}
                                                  onClick={() => handleOptionClickPosExercise(qIndex, option)}
                                                  InputProps={{
                                                    style: { cursor: 'pointer' }
                                                  }}
                                                  title="Click to set as answer"
                                                />
                                              </Grid>
                                            ))}
                                          </Grid>
                                        </Box>
                                        <Box mb={3}>
                                          <TextField
                                            id={`answer-${qIndex}`}
                                            label="Answer"
                                            fullWidth
                                            variant="outlined"
                                            value={question.answer}
                                            onChange={(e) => handleInputChangePosExercise(e, qIndex, 'answer')}
                                          />
                                        </Box>
                                      </Box>
                                    </Grid>
                                  ))}
                                  <Grid item xs={12}>
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      onClick={handleAddQuestionPosExercise}
                                      disabled={posExercise.quiz_questions.length >= 10}
                                    >
                                      Add Question
                                    </Button>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={handleSavePosExercise}
                                      disabled={false} // Adjust according to your loading state
                                    >
                                      Save
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          ),
                        },
                        {
                          tabName: "Root Family",
                          tabContent: (
                            <div
                              style={{
                                padding: "2rem 2rem 0rem 2rem",
                                overflowX: "auto"
                              }}
                            >
                              <Grid
                                container
                                spacing={3}
                                alignItems="center"
                                columnSpacing={{ xs: 2, sm: 2, md: 2 }}
                              >
                                {
                                  (selectedPOS == "" ||
                                    speechPart.toLowerCase() ==
                                    selectedPOS?.toLowerCase()) ? (
                                    <Grid item xs={8} sm={8} md={6}>
                                      <CustomInput
                                        id="etymMeaning"
                                        labelText={
                                          speechPart &&
                                          `Etym Meaning (${speechPart})`
                                        }
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        inputProps={{
                                          value: etymMeaning,
                                          onChange: (e) =>
                                            setEtymMeaning(e.target.value),
                                        }}
                                      />
                                    </Grid>
                                  ) : (
                                    ""
                                  )}
                                {secondSpeechPart &&
                                  (selectedPOS == "" ||
                                    secondSpeechPart.toLowerCase() ==
                                    selectedPOS?.toLowerCase()) ? (
                                  <Grid item xs={8} sm={8} md={6}>
                                    <CustomInput
                                      id="etymMeaning"
                                      labelText={
                                        secondSpeechPart &&
                                        `Etym Meaning (${secondSpeechPart})`
                                      }
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      inputProps={{
                                        value: etymMeaning2,
                                        onChange: (e) =>
                                          setEtymMeaning2(e.target.value),
                                      }}
                                    />
                                  </Grid>
                                ) : (
                                  ""
                                )}
                                {thirdSpeechPart &&
                                  (selectedPOS == "" ||
                                    thirdSpeechPart.toLowerCase() ==
                                    selectedPOS?.toLowerCase()) ? (
                                  <Grid item xs={8} sm={8} md={6}>
                                    <CustomInput
                                      id="etymMeaning"
                                      labelText={
                                        thirdSpeechPart &&
                                        `Etym Meaning (${thirdSpeechPart})`
                                      }
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      inputProps={{
                                        value: etymMeaning3,
                                        onChange: (e) =>
                                          setEtymMeaning3(e.target.value),
                                      }}
                                    />
                                  </Grid>
                                ) : (
                                  ""
                                )}
                                <Grid item xs={4} sm={4} md={4}>
                                  <Button
                                    color="secondary"
                                    onClick={changeClassicMeaning}
                                  >
                                    save
                                  </Button>
                                </Grid>
                              </Grid>
                              {/* <Table
                                tableHeaderColor="black"
                                tableHead={[
                                  "Add/Edit Root",
                                  "Clear",
                                  "Type",
                                  "Root",
                                  "Exact root",
                                  "Meaning",
                                ]}
                                tableData={roots}
                              /> */}
                              {roots && roots.length > 0 ?
                                <div>
                                  {/* Header */}
                                  <Grid container spacing={2} sx={{ borderBottom: '1px solid #ccc !important', marginBottom: '15px !important', width: '1000px !important' }}>
                                    <Grid item xs={1} md={1} sm={1} sx={{ fontWeight: 'bold' }}>
                                      Edit
                                    </Grid>
                                    <Grid item xs={1} md={1} sm={1} sx={{ fontWeight: 'bold' }}>
                                      Clear
                                    </Grid>
                                    <Grid item xs={1} md={1} sm={1} sx={{ fontWeight: 'bold' }}>
                                      Root Type
                                    </Grid>
                                    <Grid item xs={3} md={3} sm={3} sx={{ fontWeight: 'bold' }}>
                                      Root
                                    </Grid>
                                    <Grid item xs={2} md={2} sm={2} sx={{ fontWeight: 'bold' }}>
                                      Exact Root
                                    </Grid>
                                    <Grid item xs={4} md={4} sm={2} sx={{ fontWeight: 'bold' }}>
                                      Meaning
                                    </Grid>
                                  </Grid>

                                  <GridLayout
                                    className="layout"
                                    layout={layout}
                                    cols={1} // Single column for vertical display
                                    rowHeight={70} // Adjusted row height
                                    width={1000} // Adjusted width
                                    isResizable={false}
                                    draggableHandle=".draggable-handle"
                                    draggableCancel=".non-draggable"
                                    onLayoutChange={onLayoutChange}
                                    sx={{ overflowX: 'auto' }}
                                  >

                                    {/* Mapped Elements */}
                                    {roots && roots.length > 0 ? roots.map((root, index) => (
                                      <div key={root.root_id} >
                                        <div className="draggable-handle" >
                                          <div className="non-draggable">

                                          </div>
                                          <Grid container spacing={2} sx={{ borderBottom: '1px solid #ccc !important' }}>
                                            <Grid item xs={1} md={1} sm={1}>
                                              <div className="non-draggable">
                                                <Tooltip
                                                  onClick={addOrEditRoot}
                                                  data-id={index + 1}
                                                  data-root={root.root}
                                                  data-meaning={root.meaning}
                                                  data-root_id={root.root_id}
                                                  data-root_type={root.root_type}
                                                  data-exact_root={root.exact_root}
                                                  data-sequence={root.sequence}
                                                  data-sequence_2={root.sequence_2}
                                                  title="Add/Edit Root"
                                                  placement="top"
                                                  classes={{ tooltip: classes1.tooltip }}
                                                >
                                                  <IconButton
                                                    aria-label="Edit"
                                                    className={classes1.tableActionButton}
                                                  >
                                                    <Edit
                                                      className={
                                                        classes1.tableActionButtonIcon + " " + classes1.edit
                                                      }
                                                    />
                                                  </IconButton>
                                                </Tooltip></div>

                                            </Grid>
                                            <Grid item xs={1} md={1} sm={1}>
                                              <div className="non-draggable">
                                                <Button
                                                  style={{ backgroundColor: "transparent", color: "black" }}
                                                  onClick={(e) => {
                                                    clearRoot(root.root, root.meaning, root.root_id, root.root_type, root.exact_root);
                                                  }}
                                                >
                                                  {"Clear "}
                                                </Button></div>
                                            </Grid>
                                            <Grid item xs={1} md={1} sm={1} sx={{ textAlign: 'center !important' }}>
                                              {root.root_type}
                                            </Grid>
                                            <Grid item xs={3} md={3} sm={3}>
                                              {root.root}
                                            </Grid>
                                            <Grid item xs={2} md={2} sm={2} sx={{ textAlign: 'center !important' }}>
                                              {root.exact_root}
                                            </Grid>
                                            <Grid item xs={4} md={4} sm={4}>
                                              {root.meaning}
                                            </Grid>
                                          </Grid>

                                        </div>
                                      </div>
                                    )) : ""}
                                  </GridLayout>
                                </div> : ""}
                              <Dialog
                                style={{
                                  fontSize: "12px",
                                }}
                                classes={{
                                  root: classes.center,
                                  paper: classes.modal,
                                }}
                                open={modal1}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={() => setModal1(false)}
                                aria-labelledby="modal-slide-title"
                                aria-describedby="modal-slide-description"
                              >
                                <DialogTitle
                                  id="classic-modal-slide-title"
                                  disableTypography
                                  className={classes.modalHeader}
                                >
                                  <IconButton
                                    style={{
                                      position: "absolute",
                                      right: "0",
                                      top: "0",
                                    }}
                                    className={classes.modalCloseButton}
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    onClick={() => {
                                      setModal1(false);
                                      resetEditRootPage();
                                      setRootType("prefix");
                                    }}
                                  >
                                    <Close className={classes.modalClose} />
                                  </IconButton>
                                  <h4 className={classes.modalTitle}>
                                    Identify Prefixs, Base Roots, and Suffixes
                                  </h4>
                                </DialogTitle>
                                <DialogContent
                                  id="modal-slide-description"
                                  className={classes.modalBody}
                                >
                                  <h5
                                    style={{
                                      color: "#34566A",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Root{" "}
                                    {rootNumber
                                      ? rootNumber
                                      : roots
                                        ? roots.length + 1
                                        : ""}{" "}
                                    <small style={{ color: "gray" }}>for</small>{" "}
                                    {word}
                                  </h5>
                                  <hr />

                                  <div>
                                    <FormControl
                                      sx={{
                                        fontFamily: "Poppins",
                                        fontSize: "12px",
                                      }}
                                    >
                                      <FormLabel
                                        sx={{
                                          fontFamily: "Poppins",
                                          fontSize: "13px",
                                          color: "#828282",
                                        }}
                                        id="demo-row-radio-buttons-group-label"
                                      >
                                        Select the Type of Root
                                      </FormLabel>
                                      <div
                                        style={{
                                          marginTop: "15px",
                                          display: "flex",
                                          justifyContent: "space-around",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <input
                                            type="radio"
                                            name="rootType"
                                            className={classes.rootRadioBtn}
                                            defaultChecked={
                                              rootType == "prefix"
                                                ? true
                                                : false
                                            }
                                            onClick={(e) => {
                                              let sortedRoots = [];
                                              for (const key in allRootsObject[
                                                "prefix"
                                              ]) {
                                                sortedRoots.push([
                                                  allRootsObject["prefix"][key][
                                                  "description"
                                                  ],
                                                  key,
                                                ]);
                                              }
                                              sortedRoots.sort((a, b) => {
                                                if (
                                                  a[0].toLowerCase() >
                                                  b[0].toLowerCase()
                                                ) {
                                                  return 1;
                                                } else {
                                                  return -1;
                                                }
                                              });
                                              setRootType("prefix");
                                              setAllRoots(sortedRoots);
                                              setFoundRoots(sortedRoots);
                                            }}
                                          />
                                          <label style={{ marginLeft: "7px" }}>
                                            prefix
                                          </label>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <input
                                            type="radio"
                                            name="rootType"
                                            className={classes.rootRadioBtn}
                                            onClick={(e) => {
                                              let sortedRoots = [];
                                              for (const key in allRootsObject[
                                                "base"
                                              ]) {
                                                sortedRoots.push([
                                                  allRootsObject["base"][key][
                                                  "description"
                                                  ],
                                                  key,
                                                ]);
                                              }
                                              sortedRoots.sort((a, b) => {
                                                if (
                                                  a[0].toLowerCase() >
                                                  b[0].toLowerCase()
                                                ) {
                                                  return 1;
                                                } else {
                                                  return -1;
                                                }
                                              });
                                              setRootType("base");
                                              setAllRoots(sortedRoots);
                                              setFoundRoots(sortedRoots);
                                            }}
                                          />
                                          <label style={{ marginLeft: "7px" }}>
                                            base
                                          </label>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <input
                                            type="radio"
                                            name="rootType"
                                            className={classes.rootRadioBtn}
                                            onClick={(e) => {
                                              let sortedRoots = [];
                                              for (const key in allRootsObject[
                                                "sufix"
                                              ]) {
                                                sortedRoots.push([
                                                  allRootsObject["sufix"][key][
                                                  "description"
                                                  ],
                                                  key,
                                                ]);
                                              }
                                              sortedRoots.sort((a, b) => {
                                                if (
                                                  a[0].toLowerCase() >
                                                  b[0].toLowerCase()
                                                ) {
                                                  return 1;
                                                } else {
                                                  return -1;
                                                }
                                              });
                                              setRootType("sufix");
                                              setAllRoots(sortedRoots);
                                              setFoundRoots(sortedRoots);
                                            }}
                                          />
                                          <label style={{ marginLeft: "7px" }}>
                                            suffix
                                          </label>
                                        </div>
                                      </div>
                                    </FormControl>
                                  </div>

                                  <hr />
                                  <p>Select the Root</p>
                                  <p>
                                    Type the first letters of the root to filter
                                    the list and then click in the list to
                                    highlight the root you want
                                  </p>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "80%",
                                    }}
                                  >
                                    <div>
                                      <div>
                                        <p>Filter Root:</p>
                                        <input
                                          type="search"
                                          value={name}
                                          onChange={filter}
                                          style={{ marginLeft: "0px" }}
                                        />
                                      </div>
                                      <br />

                                      <div>
                                        Selected Root: <br />{" "}
                                        <p
                                          style={{
                                            fontWeight: "bolder",
                                            fontSize: "larger",
                                          }}
                                        >
                                          {editRoot && editRoot["description"]
                                            ? editRoot["description"]
                                            : ""}
                                        </p>
                                      </div>
                                      <br />
                                      <div>
                                        Meaning:{" "}
                                        <p
                                          style={{
                                            fontWeight: "bolder",
                                            fontSize: "larger",
                                          }}
                                        >
                                          {editRoot && editRoot["meaning"]
                                            ? editRoot["meaning"]
                                            : ""}
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        width: "40%",
                                        float: "left",
                                        overflowY: "auto",
                                        height: "200px",
                                        border: "solid 1px black",
                                        padding: "10px",
                                      }}
                                    >
                                      {foundRoots && foundRoots.length > 0 ? (
                                        foundRoots.map((user, i) => (
                                          <p
                                            onClick={(e) => {
                                              setEditRoot(
                                                allRootsObject[rootType][
                                                user[1]
                                                ]
                                              );
                                            }}
                                            key={i}
                                            id={user[1]}
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            {user[0]}
                                          </p>
                                        ))
                                      ) : (
                                        <p>No roots found!</p>
                                      )}
                                    </div>
                                  </div>
                                  <br />
                                  <br />
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "80%",
                                    }}
                                  >
                                    {/* <button onClick={editWordRoots}>Add this Root to Word</button>
                                    <button onClick={e=>{
                                        if (editRoot) {
                                          setModal3(true)
                                          setEditingRoot(editRoot)
                                        } else {
                                          toast.error('Please select a root to edit')
                                        }
                                      }}>Edit Root</button> */}
                                    {/* <button>Delete Root</button> */}
                                  </div>
                                </DialogContent>
                                <DialogActions
                                  className={
                                    classes.modalFooter +
                                    " " +
                                    classes.modalFooterCenter
                                  }
                                  style={{ margin: "auto" }}
                                >
                                  <Button
                                    color="secondary"
                                    onClick={editWordRoots}
                                    style={{
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    Add this Root to Word
                                  </Button>
                                  <Button
                                    color="secondary"
                                    onClick={(e) => {
                                      if (editRoot) {
                                        setModal3(true);
                                        setEditingRoot(editRoot);
                                      } else {
                                        toast.error(
                                          "Please select a root to edit"
                                        );
                                      }
                                    }}
                                    style={{
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    Edit Root
                                  </Button>
                                  <Button
                                    color="secondary"
                                    onClick={(e) => {
                                      setModal4(true);
                                      resetEditRootPage();
                                    }}
                                    style={{
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    Add a New Root
                                  </Button>
                                  <Button
                                    color="danger"
                                    onClick={deleteRoot}
                                    style={{
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    Deactivate Root
                                  </Button>
                                </DialogActions>
                              </Dialog>
                              <Dialog
                                classes={{
                                  root: classes.center,
                                  paper: classes.modal,
                                }}
                                open={modal3}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={() => setModal3(false)}
                                aria-labelledby="modal-slide-title"
                                aria-describedby="modal-slide-description"
                              >
                                <DialogTitle
                                  id="classic-modal-slide-title"
                                  disableTypography
                                  className={classes.modalHeader}
                                >
                                  <IconButton
                                    style={{
                                      position: "absolute",
                                      right: "0",
                                      top: "0",
                                    }}
                                    className={classes.modalCloseButton}
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    onClick={() => setModal3(false)}
                                  >
                                    <Close className={classes.modalClose} />
                                  </IconButton>
                                  <h4 className={classes.modalTitle}>
                                    Edit Root
                                  </h4>
                                </DialogTitle>
                                <DialogContent
                                  id="modal-slide-description"
                                  className={classes.modalBody}
                                >
                                  <hr />
                                  Root:{" "}
                                  <input
                                    value={editingRoot.description}
                                    onChange={(e) => {
                                      setEditingRoot(e.target.value);
                                      setNewEditRootDescription(e.target.value);
                                    }}
                                  />
                                  <br />
                                  <br />
                                  Meaning:
                                  <br />{" "}
                                  <input
                                    style={{ width: "100%", marginLeft: "0" }}
                                    value={editingRoot.meaning}
                                    onChange={(e) => {
                                      setEditingRoot(e.target.value);
                                      setNewEditRootMeaning(e.target.value);
                                    }}
                                  />
                                </DialogContent>
                                <DialogActions
                                  className={
                                    classes.modalFooter +
                                    " " +
                                    classes.modalFooterCenter
                                  }
                                >
                                  <Button
                                    color="secondary"
                                    onClick={editRootFunction}
                                  >
                                    Save Changes
                                  </Button>
                                </DialogActions>
                              </Dialog>
                              <Dialog
                                classes={{
                                  root: classes.center,
                                  paper: classes.modal,
                                }}
                                open={modal4}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={() => setModal4(false)}
                                aria-labelledby="modal-slide-title"
                                aria-describedby="modal-slide-description"
                              >
                                <DialogTitle
                                  id="classic-modal-slide-title"
                                  disableTypography
                                  className={classes.modalHeader}
                                >
                                  <IconButton
                                    style={{
                                      position: "absolute",
                                      right: "0",
                                      top: "0",
                                    }}
                                    className={classes.modalCloseButton}
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    onClick={() => setModal4(false)}
                                  >
                                    <Close className={classes.modalClose} />
                                  </IconButton>
                                  <h4 className={classes.modalTitle}>
                                    Add a New Root
                                  </h4>
                                </DialogTitle>
                                <DialogContent
                                  id="modal-slide-description"
                                  className={classes.modalBody}
                                >
                                  <hr />
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "70%",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <DropdownButton
                                      variant="secondary"
                                      title="Root Type"
                                    >
                                      <Dropdown.Item
                                        onClick={(e) => {
                                          setNewRootType("Prefix");
                                        }}
                                      >
                                        Prefix
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={(e) => {
                                          setNewRootType("Base");
                                        }}
                                      >
                                        Base
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={(e) => {
                                          setNewRootType("Suffix");
                                        }}
                                      >
                                        Suffix
                                      </Dropdown.Item>
                                    </DropdownButton>
                                    <span>{newRootType}</span>
                                  </div>
                                  <br />
                                  Root:{" "}
                                  <input
                                    value={newRootDescription}
                                    onChange={(e) => {
                                      setNewRootDescription(e.target.value);
                                    }}
                                  />
                                  <br />
                                  <br />
                                  Meaning: <br />
                                  <input
                                    value={newRootMeaning}
                                    style={{ width: "100%", marginLeft: "0" }}
                                    onChange={(e) => {
                                      setNewRootMeaning(e.target.value);
                                    }}
                                  />
                                </DialogContent>
                                <DialogActions
                                  className={
                                    classes.modalFooter +
                                    " " +
                                    classes.modalFooterCenter
                                  }
                                >
                                  <Button
                                    color="secondary"
                                    onClick={addNewRootFunction}
                                  >
                                    Add
                                  </Button>
                                </DialogActions>
                              </Dialog>
                              <Button
                                color="secondary"
                                onClick={(e) => {
                                  // Comment this
                                  // resetEditRootPage();
                                  setRootNumber("");
                                  // setTimeout(() => setModal1(true), 2000)
                                  setModal1(true);
                                }}
                              >
                                Add Root
                              </Button>
                              <Button color="primary" onClick={handleUpdateRootOrder}>
                                Update root order

                              </Button>
                              <Button color="secondary" onClick={seeCousins}>
                                See cousins{" "}
                                {/* {wordCousins && `(${wordCousins.length})`} */}
                              </Button>
                              <Dialog
                                classes={{
                                  root: classes.center,
                                  paper: classes.modal,
                                }}
                                open={openCousinsDialog}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={() => setOpenCousinsDialog(false)}
                                aria-labelledby="modal-slide-title"
                                aria-describedby="modal-slide-description"
                              >
                                <DialogTitle
                                  id="classic-modal-slide-title"
                                  disableTypography
                                  className={classes.modalHeader}
                                >
                                  <IconButton
                                    style={{
                                      position: "absolute",
                                      right: "0",
                                      top: "0",
                                    }}
                                    className={classes.modalCloseButton}
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    onClick={() => setOpenCousinsDialog(false)}
                                  >
                                    <Close className={classes.modalClose} />
                                  </IconButton>
                                  <h3 className={classes.modalTitle}>
                                    <span style={{ margin: "0 10px" }}>
                                      Cousins of the word
                                    </span>
                                    {word && <strong> {word}</strong>}{" "}
                                    {wordCousins && ` (${wordCousins.length})`}
                                  </h3>
                                </DialogTitle>
                                <DialogContent
                                  id="modal-slide-description"
                                  className={classes.modalBody}
                                >
                                  <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                      {wordCousins &&
                                        wordCousins.map((item, index) => {
                                          return (
                                            <h5 key={index}>
                                              {index + 1}. {item.cousins}{" "}
                                            </h5>
                                          );
                                        })}
                                    </GridItem>
                                  </GridContainer>
                                </DialogContent>
                                <DialogActions
                                  className={
                                    classes.modalFooter +
                                    " " +
                                    classes.modalFooterCenter
                                  }
                                >
                                  <Button
                                    onClick={() => setOpenCousinsDialog(false)}
                                  >
                                    Close
                                  </Button>
                                </DialogActions>
                              </Dialog>
                              {openDeleteModalRoot && (
                                <DeleteConfirmationDialog
                                  itemName={selectedItemToDelete}
                                  onDelete={handleDelete}
                                  onCancel={handleCancel}
                                  item={editRoot}
                                />
                              )}
                            </div>
                          ),
                        },
                        {
                          tabName: "Origins",
                          tabContent: (
                            // Celles code
                            <div style={{ paddingTop: "2rem" }}>
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={12} sm={12}>
                                  <div>
                                    <Mradio
                                      name="radio-buttons"
                                      inputProps={{ "aria-label": "A" }}
                                      value="legacy"
                                      checked={selectedValue === "legacy"}
                                      onChange={handleRadioChange}
                                      sx={{
                                        color: "#FFCD00",
                                        backgroundImage:
                                          "radial-gradient(#fff,#fff 45%,transparent 32%)",
                                      }}
                                    />
                                    <span className={classes.originHeaderText}>
                                      Legacy
                                    </span>
                                  </div>

                                  {/* {borrowing && borrowing.borrowingName ? (
                                    <div>
                                      <strong> Borrowing:</strong>{" "}
                                      {borrowing.borrowingName}
                                    </div>
                                  ) : (
                                    " "
                                  )}
                                  {borrowing && borrowing.borrowingLanguage ? (
                                    <div>
                                      <strong> Borrowing's Language:</strong>{" "}
                                      {borrowing.borrowingLanguage}
                                    </div>
                                  ) : (
                                    " "
                                  )}
                                  {borrowing && borrowing.borrowingMeaning ? (
                                    <div>
                                      <strong> Borrowing's Meaning:</strong>{" "}
                                      {borrowing.borrowingMeaning}
                                    </div>
                                  ) : (
                                    " "
                                  )}
                                  {englishPeriod ? (
                                    <div>
                                      <strong> Legacy English Period:</strong>{" "}
                                      {englishPeriod?.name}
                                    </div>
                                  ) : (
                                    " "
                                  )} */}
                                </Grid>
                              </Grid>
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={4} sm={12}>
                                  <strong> Legacy English Period:</strong>
                                  <Select
                                    value={englishPeriodSelect != '' ? englishPeriodOrigins.find((option) => option.value == englishPeriodSelect) : englishPeriod && englishPeriod?.name ? englishPeriodOrigins.find((option) => option.label?.toLowerCase() === englishPeriod?.name?.toLowerCase()) : ""}
                                    // value={englishPeriod?.name}
                                    onChange={(e) => {
                                      setEnglishPeriodSelect((prevLabel) => e.value);
                                    }}
                                    options={englishPeriodOrigins}
                                  />
                                </Grid>
                                <Grid item xs={12} md={4} sm={12}>
                                  <strong> Borrowing's Language:</strong>
                                  <Select
                                    value={borrowingLanguageSelect != '' ? borrowingLanguage.find((option) => option.value == borrowingLanguageSelect) : borrowing && borrowing.borrowingLanguage ? borrowingLanguage.find((option) => option.label?.toLowerCase() === borrowing?.borrowingLanguage?.toLowerCase()) : ""}
                                    // value={borrowing.borrowingLanguage}
                                    onChange={(e) => {
                                      setBorrowingLanguageSelect(e.value);
                                    }}
                                    options={borrowingLanguage}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={4} sm={12}>
                                  <strong> Borrowing:</strong>
                                  <textarea
                                    cols="50"
                                    rows="1"
                                    value={borrowingName !== '' ? borrowingName : borrowing && borrowing.borrowingName ? borrowing?.borrowingName : ""}
                                    onChange={(e) => {
                                      setBorrowingName(e.target.value);
                                    }
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} md={4} sm={12}>
                                  <strong>Borrowing's Meaning:</strong>
                                  <textarea
                                    cols="50"
                                    rows="1"
                                    value={borrowingMeaning !== '' ? borrowingMeaning : borrowing && borrowing.borrowingMeaning ? borrowing?.borrowingMeaning : ""}
                                    onChange={(e) => {
                                      setBorrowingMeaning(e.target.value);
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container spacing={3}>
                                {oxfordEtymologies ? (
                                  <Grid item xs={12} md={12} sm={12}>
                                    <div>
                                      <Mradio
                                        name="radio-buttons"
                                        inputProps={{ "aria-label": "A" }}
                                        checked={selectedValue === ""}
                                        onChange={handleRadioChange}
                                        sx={{
                                          color: "#FFCD00",
                                          backgroundImage:
                                            "radial-gradient(#fff,#fff 45%,transparent 32%)",
                                        }}
                                      />
                                      <span
                                        className={classes.originHeaderText}
                                      >
                                        Oxford
                                      </span>
                                    </div>
                                    <div>{oxfordEtymologies}</div>
                                  </Grid>
                                ) : (
                                  " "
                                )}

                              </Grid><br />
                              <Button
                                color="secondary"
                                onClick={updateWordOrigins}
                              >
                                Update
                              </Button>
                            </div>
                          ),
                        },

                        {
                          tabName: "Synonyms/Antonyms",
                          tabContent: (
                            <div style={{ paddingTop: "3rem" }}>
                              <Grid
                                container
                                spacing={{ md: 2 }}
                                columnSpacing={{ sm: 1, md: 3 }}
                              >
                                <Grid item xs={12} sm={6} md={5}>
                                  <ol>
                                    <div className={classes.synonymsHeaderText}>
                                      {" "}
                                      Synonyms:
                                    </div>
                                    {googleData &&
                                      googleData.data &&
                                      googleData.data.synonyms ? (
                                      googleData.data.synonyms.map(function (
                                        item,
                                        i
                                      ) {
                                        return <li key={i}>{item}</li>;
                                      })
                                    ) : (
                                      <span></span>
                                    )}
                                    {oxfordSynonyms ? (
                                      oxfordSynonyms.map(function (ob, i) {
                                        return (
                                          <div
                                            key={i}
                                            className={classes.symListWrapper}
                                          >
                                            <div
                                              className={
                                                classes.synonymsSpeechText
                                              }
                                            >
                                              {" "}
                                              {ob.speechPart}
                                            </div>
                                            <ul>
                                              {ob.synonymArrs.map(
                                                (synonym, i) => {
                                                  return (
                                                    <Grid key={i} container>
                                                      <Grid
                                                        item
                                                        xs={10}
                                                        sm={10}
                                                        md={7}
                                                      >
                                                        <li>{synonym}</li>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        xs={2}
                                                        sm={2}
                                                        md={5}
                                                      >
                                                        <input
                                                          type="checkbox"
                                                          defaultChecked={
                                                            publishedData?.synonyms?.includes(
                                                              synonym
                                                            )
                                                              ? true
                                                              : false
                                                          }
                                                          value={synonym}
                                                          className="addCheckbox synonymsCheckbox"
                                                        />
                                                      </Grid>
                                                    </Grid>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        );
                                      })
                                    ) : (
                                      <span></span>
                                    )}
                                    {oxfordSynonyms &&
                                      oxfordSynonyms?.length == 0 &&
                                      publishedData ? (
                                      <div className={classes.symListWrapper}>
                                        <ul>
                                          {publishedData?.synonyms?.map(
                                            (synonym, i) => {
                                              return (
                                                <Grid key={i} container>
                                                  <Grid
                                                    item
                                                    xs={10}
                                                    sm={10}
                                                    md={7}
                                                  >
                                                    <li>{synonym}</li>
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={2}
                                                    sm={2}
                                                    md={5}
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      defaultChecked={
                                                        publishedData?.synonyms?.includes(
                                                          synonym
                                                        )
                                                          ? true
                                                          : false
                                                      }
                                                      value={synonym}
                                                      className="addCheckbox synonymsCheckbox"
                                                    />
                                                  </Grid>
                                                </Grid>
                                              );
                                            }
                                          )}
                                        </ul>
                                      </div>
                                    ) : (
                                      <span></span>
                                    )}
                                  </ol>
                                </Grid>
                                <Grid item xs={12} sm={6} md={5}>
                                  <ol>
                                    <div className={classes.synonymsHeaderText}>
                                      {" "}
                                      Antonyms:{" "}
                                    </div>
                                    {googleData &&
                                      googleData.data &&
                                      googleData.data.antonyms ? (
                                      googleData.data.antonyms.map(function (
                                        item,
                                        i
                                      ) {
                                        return <li key={i}>{item}</li>;
                                      })
                                    ) : (
                                      <span></span>
                                    )}
                                    {oxfordAntonyms ? (
                                      oxfordAntonyms.map(function (ob, i) {
                                        return (
                                          <div key={i}>
                                            <div
                                              className={
                                                classes.synonymsSpeechText
                                              }
                                            >
                                              {ob.speechPartAnt}
                                            </div>
                                            <ul>
                                              {ob.antonymArrs.map(
                                                (antonym, i) => {
                                                  return (
                                                    <Grid container key={i}>
                                                      <Grid
                                                        item
                                                        xs={10}
                                                        sm={10}
                                                        md={7}
                                                      >
                                                        <li>{antonym}</li>
                                                      </Grid>
                                                      <GridItem
                                                        xs={2}
                                                        sm={2}
                                                        md={5}
                                                      >
                                                        <input
                                                          defaultChecked={
                                                            publishedData?.antonyms?.includes(
                                                              antonym
                                                            )
                                                              ? true
                                                              : false
                                                          }
                                                          value={antonym}
                                                          type="checkbox"
                                                          className="addCheckbox antonymsCheckbox"
                                                        />
                                                      </GridItem>
                                                    </Grid>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        );
                                      })
                                    ) : (
                                      <span></span>
                                    )}
                                    {oxfordSynonyms &&
                                      oxfordSynonyms?.length == 0 &&
                                      publishedData ? (
                                      <div className={classes.symListWrapper}>
                                        <ul>
                                          {publishedData?.antonyms?.map(
                                            (antonym, i) => {
                                              return (
                                                <Grid key={i} container>
                                                  <Grid
                                                    item
                                                    xs={10}
                                                    sm={10}
                                                    md={7}
                                                  >
                                                    <li>{antonym}</li>
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={2}
                                                    sm={2}
                                                    md={5}
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      defaultChecked={
                                                        publishedData?.antonyms?.includes(
                                                          antonym
                                                        )
                                                          ? true
                                                          : false
                                                      }
                                                      value={antonym}
                                                      className="addCheckbox antonymsCheckbox"
                                                    />
                                                  </Grid>
                                                </Grid>
                                              );
                                            }
                                          )}
                                        </ul>
                                      </div>
                                    ) : (
                                      <span></span>
                                    )}
                                  </ol>
                                </Grid>
                              </Grid>
                              <Button
                                color="secondary"
                                onClick={saveSynonymsAndAntonyms}
                              >
                                Update
                              </Button>
                              <Button
                                color="secondary"
                                onClick={() =>
                                  setOpenModalSynonymsAntonyms(true)
                                }
                              >
                                Add Synonyms/Antonyms
                              </Button>
                              <Dialog
                                open={openModalSynonymsAntonyms}
                                onClose={() =>
                                  setOpenModalSynonymsAntonyms(false)
                                }
                                aria-labelledby="modal-slide-title"
                                aria-describedby="modal-slide-description"
                              >
                                <DialogTitle
                                  id="classic-modal-slide-title"
                                  style={{ textAlign: "center" }}
                                >
                                  <span className={classes.originHeaderText}>
                                    Make sure to separate each word with a
                                    comma.
                                  </span>
                                </DialogTitle>
                                <DialogContent>
                                  <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                      <textarea
                                        cols="60"
                                        rows="3"
                                        placeholder=" Add Synonyms......................................................................................"
                                        value={addSynonyms}
                                        onChange={(e) =>
                                          setAddSynonyms(e.target.value)
                                        }
                                      />
                                      <textarea
                                        cols="60"
                                        rows="3"
                                        placeholder=" Add Antonyms......................................................................................"
                                        value={addAntonyms}
                                        onChange={(e) =>
                                          setAddAntonyms(e.target.value)
                                        }
                                      />
                                    </GridItem>
                                  </GridContainer>
                                </DialogContent>

                                <DialogActions>
                                  <Button
                                    autoFocus
                                    color="primary"
                                    onClick={() =>
                                      setOpenModalSynonymsAntonyms(false)
                                    }
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    autoFocus
                                    onClose={() =>
                                      setOpenModalSynonymsAntonyms(true)
                                    }
                                    color="secondary"
                                    onClick={AddSynonymsAntonyms}
                                  >
                                    Add
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </div>
                          ),
                        },
                        {
                          tabName: "Word Story",
                          tabContent: (
                            <React.Fragment>
                              <Grid container spacing={{ md: 2, sm: 1 }}>
                                {/* <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={6}
                                >
                                  <div className={classes.storyHeaderText}>
                                    Story
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={4}
                                >
                                  <div className={classes.storyHeaderText}>
                                    Source
                                  </div>
                                </Grid> */}
                                {wordStories
                                  ? wordStories.map((array, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        borderBottom: "1px solid #ccc",
                                      }}
                                    >
                                      <br></br>
                                      <Grid
                                        container
                                        spacing={{ md: 2, sm: 1 }}
                                      >
                                        <Grid item xs={12} sm={12} md={6}>
                                          <div>{array.story}</div>
                                        </Grid>
                                        <Grid item xs={8} sm={6} md={4}>
                                          <div>{array.source}</div>
                                        </Grid>
                                        <Grid item xs={4} sm={6} md={2}>
                                          <div
                                            className={classes.alignButtons}
                                          >
                                            <IconButton
                                              color="primary"
                                              aria-label="edit"
                                              onClick={() =>
                                                editStory(
                                                  array.source,
                                                  array.story
                                                )
                                              }
                                            >
                                              <Edit />
                                            </IconButton>
                                            <IconButton
                                              color="secondary"
                                              aria-label="delete"
                                              onClick={() =>
                                                removeStory(array.story)
                                              }
                                            >
                                              <Delete />
                                            </IconButton>
                                          </div>
                                        </Grid>
                                      </Grid>
                                      <br />
                                    </div>
                                  ))
                                  : ""}
                              </Grid>
                              <br></br>
                              <Button color="secondary" onClick={addStory}>
                                Add Story
                              </Button>
                              {/* Edit sentence */}
                              <Dialog
                                open={openModalStoryEdit}
                                onClose={() => setOpenModalStoryEdit(false)}
                                aria-labelledby="modal-slide-title"
                                aria-describedby="modal-slide-description"
                              >
                                <DialogTitle
                                  id="classic-modal-slide-title"
                                  style={{ textAlign: "center" }}
                                >
                                  <span className={classes.originHeaderText}>
                                    {" "}
                                    {updateStory ? "Edit Story" : "Add Story"}
                                  </span>
                                </DialogTitle>
                                <DialogContent>
                                  <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                      <textarea
                                        cols="60"
                                        rows="8"
                                        placeholder="Story......................................................................................"
                                        value={story}
                                        onChange={(e) =>
                                          setStory(e.target.value)
                                        }
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                      <CustomInput
                                        labelText="Type in a source here"
                                        id="source"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        inputProps={{
                                          value: source,
                                          onChange: (e) =>
                                            setSource(e.target.value),
                                        }}
                                      />
                                    </GridItem>
                                  </GridContainer>
                                </DialogContent>

                                <DialogActions>
                                  <Button
                                    autoFocus
                                    color="primary"
                                    onClick={() => setOpenModalStoryEdit(false)}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    autoFocus
                                    onClose={() => setOpenModalStoryEdit(true)}
                                    color="secondary"
                                    onClick={addNewStory}
                                  >
                                    {updateStory ? "Update" : "Submit"}
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </React.Fragment>
                          ),
                        },
                        {
                          tabName: "Sentences",
                          tabContent: (
                            <React.Fragment>
                              <Grid container>
                                <br />
                                <Grid item xs={12} sm={12} md={4}>
                                  <DropdownButton
                                    variant="warning"
                                    title={tableSentencesHeading}
                                  >
                                    <Dropdown.Item
                                      onClick={() => {
                                        setIsTableSenetences(false);
                                        setTableSentencesHeading(
                                          "Sentences from a specific book"
                                        );
                                      }}
                                    >
                                      Sentences from a specific book
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setIsTableSenetences(true);
                                        setTableSentencesHeading(
                                          "Sentences from all books"
                                        );
                                      }}
                                    >
                                      Sentences from all books
                                    </Dropdown.Item>
                                  </DropdownButton>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3}>
                                  <Tooltip
                                    // onClick={addOrEditRoot}

                                    title={
                                      !fetchOtherFormsSentences
                                        ? "Switch on the toggle to also fetch sentences for the other forms of the word"
                                        : "Switch off the toggle to only fetch sentences for the exact word"
                                    }
                                    placement="top"
                                    classes={{ tooltip: classes1.tooltip }}
                                  >
                                    <IconButton aria-label="Edit">
                                      <SimpleToggleSwitch
                                        status={
                                          fetchOtherFormsSentences === true
                                            ? true
                                            : false
                                        }
                                        handleChange={
                                          showFetchOtherFormsSentences
                                        }
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                              {isTableSenetences === false ? (
                                <>
                                  <Grid
                                    container
                                    spacing={2}
                                    alignItems="center"
                                  >
                                    <Grid item xs={12} sm={12} md={8}>
                                      <Select
                                        defaultValue={selectedOption}
                                        onChange={setSelectedOptionFunc}
                                        options={options}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1}>
                                      <Button
                                        color="white"
                                        aria-label="search"
                                        round
                                        justIcon
                                        onClick={fetchSentences}
                                        style={{
                                          minWidth: "25px",
                                          width: "30px",
                                          height: "2px",
                                        }}
                                      >
                                        <Search />
                                      </Button>
                                    </Grid>
                                  </Grid>
                                  <br />
                                  <Grid
                                    container
                                    spacing={2}
                                    alignItems="center"
                                  >
                                    <Grid item xs={12} sm={12} md={3}>
                                      <Input
                                        id="standard-adornment-legacyData"
                                        defaultValue="Sentence Audio"
                                        inputProps={{
                                          readOnly: true,
                                          name: "def",
                                          "aria-label": "Sentence Audio",
                                        }}
                                        endAdornment={

                                          <InputAdornment position="end">
                                            {audioSentence &&
                                              <span>
                                                <audio
                                                  id="playClipSentence"
                                                  src={
                                                    process.env
                                                      .REACT_APP_S3_BASE_URL +
                                                    word[0] +
                                                    "/" +
                                                    word +
                                                    "_" +
                                                    "s" +
                                                    "_" +
                                                    wordId +
                                                    ".mp3"
                                                  }
                                                />
                                                <IconButton
                                                  id="playClipSentence"
                                                  aria-label="volume"
                                                  size="small"
                                                  onClick={playClipSentence}
                                                >
                                                  <VolumeUpIcon
                                                    sx={{
                                                      color: "#34566A",
                                                    }}
                                                  />
                                                </IconButton>
                                              </span>}                                                  </InputAdornment>
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                  <br />
                                  <Grid
                                    container
                                    spacing={2}
                                    alignItems="center"
                                  >
                                    <Grid item xs={12} sm={12} md={3}>
                                      <React.Fragment>
                                        <strong>Select a voice:</strong>
                                        <Select
                                          value={voiceName}
                                          onChange={(selectedOption) => {
                                            setVoiceName(selectedOption);
                                          }}
                                          styles={customStyles}
                                          options={voiceOptions}
                                          placeholder="Select a voice"
                                        />
                                        {audioWordSpiner && <BeatLoader />}
                                      </React.Fragment>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3}>
                                      {sentenceToUpload && wordVoyageSentences ?
                                        <><br />
                                          <Button
                                            styles={{ marginLeft: "20px" }}
                                            color="secondary"
                                            onClick={() => uploadTTSAudio(2)}
                                          >
                                            <span>upload Audio</span> <FileUpload />
                                          </Button></> : ""}
                                    </Grid>
                                  </Grid>
                                  <React.Fragment>
                                    <Grid item xs={12} sm={11} md={12}>
                                      <TableContainer>
                                        <MTable>
                                          <TableBody>
                                            {wordVoyageSentences
                                              ? wordVoyageSentences.map(
                                                (array, index) => (
                                                  <TableRow
                                                    key={index}
                                                    sx={{
                                                      "&:last-child td, &:last-child th": {
                                                        border: 0,
                                                      },
                                                    }}
                                                  >
                                                    <TableCell align="left"
                                                    >
                                                      {sentenceToUpload && wordVoyageSentences && sentenceToUpload?.toLowerCase() == array.sentence?.toLowerCase() ?
                                                        <FileUpload /> : ""}
                                                    </TableCell>
                                                    <TableCell
                                                      align="left"
                                                      style={{
                                                        color: "#808080",
                                                      }}
                                                    >
                                                      {array.sentence}{" "}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                      {sentenceAudioRead &&
                                                        array.sentence ===
                                                        firstLegacySentence &&
                                                        array.authorName ==
                                                        "Word Voyage" ? (
                                                        <span>
                                                          <audio
                                                            id="playaudio3"
                                                            src={
                                                              sentenceAudioRead
                                                            }
                                                          />
                                                          <IconButton
                                                            aria-label="volume"
                                                            size="small"
                                                            onClick={
                                                              playClip3
                                                            }
                                                          >
                                                            <VolumeUpIcon
                                                              sx={{
                                                                color:
                                                                  "#34566A",
                                                              }}
                                                            />
                                                          </IconButton>
                                                        </span>
                                                      ) : (<>


                                                        <audio
                                                          id="playaudioTtsSentence"
                                                          src={
                                                            audioSentenceUrl
                                                          }
                                                        />
                                                        <IconButton
                                                          id="playaudio1"
                                                          aria-label="volume"
                                                          size="small"
                                                          onClick={() => speakTextWord(array.sentence, 2)}
                                                        // onClick={() => speakText(array.sentence)}
                                                        >
                                                          <VolumeUpIcon
                                                            sx={{
                                                              color: "#34566A",
                                                            }}
                                                          />
                                                        </IconButton>
                                                      </>)}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        color: "#808080",
                                                      }}
                                                    >
                                                      {array.POS ? (
                                                        <b>
                                                          POS: {array.POS}
                                                        </b>
                                                      ) : (
                                                        ""
                                                      )}
                                                      <Select
                                                        defaultValue={pos}
                                                        onChange={(e) =>
                                                          changePOS(
                                                            array.sentence,
                                                            e
                                                          )
                                                        }
                                                        options={optionPOS}
                                                      />
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        color: "#808080",
                                                        textTransform: 'capitalize',
                                                      }}
                                                    >
                                                      {array.bookName &&
                                                        array.authorName
                                                        ? `${array.bookName} by ${array.authorName.toLowerCase()}`
                                                        : array.authorName ===
                                                          ""
                                                          ? `${array.bookName}`
                                                          : `${array.authorName.toLowerCase()}`}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                      <IconButton
                                                        color="primary"
                                                        aria-label="edit"
                                                        onClick={() =>
                                                          editSentence(
                                                            array.sentence,
                                                            array.authorName,
                                                            array.bookName
                                                          )
                                                        }
                                                      >
                                                        <Edit />
                                                      </IconButton>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                      <IconButton
                                                        color="secondary"
                                                        aria-label="delete"
                                                        onClick={() =>
                                                          removeSentence(
                                                            array.sentence,
                                                            array.authorName
                                                          )
                                                        }
                                                      >
                                                        <Delete />
                                                      </IconButton>
                                                    </TableCell>
                                                  </TableRow>
                                                )
                                              )
                                              : ""}
                                          </TableBody>
                                        </MTable>
                                      </TableContainer>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                      <hr></hr>
                                      <br></br>
                                    </Grid>
                                    <Grid item xs={12} sm={11} md={12}>
                                      {wordVoyageOtherFormSentences?.length >
                                        0 ? (
                                        <div
                                          className={
                                            classes.senOxfordheaderText
                                          }
                                          style={{ textAlign: "center" }}
                                        >
                                          Other Forms of Sentences
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <TableContainer>
                                        <MTable>
                                          <TableBody>
                                            {wordVoyageOtherFormSentences &&
                                              wordVoyageOtherFormSentences.map(
                                                (array, index) => (
                                                  <TableRow
                                                    key={index}
                                                    sx={{
                                                      "&:last-child td, &:last-child th": {
                                                        border: 0,
                                                      },
                                                    }}
                                                  >
                                                    {/* <TableCell align="left">
                                                      {array.authorName ===
                                                        "Google Data" ? (
                                                        <Checkbox
                                                          style={{
                                                            backgroundColor:
                                                              "red",
                                                          }}
                                                          inputProps={{
                                                            "aria-label":
                                                              "controlled",
                                                          }}
                                                          disabled
                                                        />
                                                      ) : (
                                                        <Checkbox
                                                          inputProps={{
                                                            "aria-label":
                                                              "controlled",
                                                          }}
                                                          disabled
                                                          defaultChecked={true}
                                                        />
                                                      )}
                                                    </TableCell> */}
                                                    <TableCell
                                                      align="left"
                                                      style={{
                                                        color: "#808080",
                                                      }}
                                                    >
                                                      {array.sentence}{" "}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                      {sentenceAudioRead &&
                                                        array.sentence ===
                                                        firstLegacySentence &&
                                                        array.authorName ==
                                                        "Word Voyage" ? (
                                                        <span>
                                                          <audio
                                                            id="playaudio3"
                                                            src={
                                                              sentenceAudioRead
                                                            }
                                                          />
                                                          <IconButton
                                                            aria-label="volume"
                                                            size="small"
                                                            onClick={playClip3}
                                                          >
                                                            <VolumeUpIcon
                                                              sx={{
                                                                color:
                                                                  "#34566A",
                                                              }}
                                                            />
                                                          </IconButton>
                                                        </span>
                                                      ) : (
                                                        // <IconButton
                                                        //   aria-label="volume"
                                                        //   size="small"
                                                        //   onClick={() =>
                                                        //     speak({
                                                        //       text:
                                                        //         array.sentence,
                                                        //     })
                                                        //   }
                                                        // >
                                                        //   <VolumeUpIcon
                                                        //     sx={{
                                                        //       color: "#34566A",
                                                        //     }}
                                                        //   />
                                                        // </IconButton>
                                                        <IconButton
                                                          id="playaudio1"
                                                          aria-label="volume"
                                                          size="small"
                                                          onClick={() => speakText(array.sentence)}
                                                        >
                                                          <VolumeUpIcon
                                                            sx={{
                                                              color: "#34566A",
                                                            }}
                                                          />
                                                        </IconButton>
                                                      )}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        color: "#808080",
                                                      }}
                                                    >
                                                      {array.POS ? (
                                                        <b>POS: {array.POS}</b>
                                                      ) : (
                                                        ""
                                                      )}
                                                      <Select
                                                        defaultValue={pos}
                                                        onChange={(e) =>
                                                          changePOS(
                                                            array.sentence,
                                                            e
                                                          )
                                                        }
                                                        options={optionPOS}
                                                      />
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        color: "#808080",
                                                        textTransform: 'capitalize',
                                                      }}
                                                    >
                                                      {array.bookName &&
                                                        array.authorName
                                                        ? `${array.bookName} by ${array.authorName.toLowerCase()}`
                                                        : array.authorName ===
                                                          ""
                                                          ? `${array.bookName}`
                                                          : `${array.authorName.toLowerCase()}`}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                      <IconButton
                                                        color="primary"
                                                        aria-label="edit"
                                                        onClick={() =>
                                                          editSentence(
                                                            array.sentence,
                                                            array.authorName,
                                                            array.bookName
                                                          )
                                                        }
                                                      >
                                                        <Edit />
                                                      </IconButton>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                      <IconButton
                                                        color="secondary"
                                                        aria-label="delete"
                                                        onClick={() =>
                                                          removeSentence(
                                                            array.sentence,
                                                            array.authorName
                                                          )
                                                        }
                                                      >
                                                        <Delete />
                                                      </IconButton>
                                                    </TableCell>
                                                  </TableRow>
                                                )
                                              )}
                                          </TableBody>
                                        </MTable>
                                      </TableContainer>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                      <hr></hr>
                                      <br></br>
                                    </Grid>

                                    <Grid
                                      container
                                      spacing={{ md: 2 }}
                                      justifyContent="center"
                                    >
                                      <Grid item xs={12} sm={7} md={7}>
                                        {oxfordSentences?.length > 0 ? (
                                          <div
                                            className={
                                              classes.senOxfordheaderText
                                            }
                                            style={{ textAlign: "center" }}
                                          >
                                            Oxford Sentences
                                          </div>
                                        ) : (
                                          ""
                                        )}

                                        <TableContainer>
                                          <MTable>
                                            <TableBody>
                                              {oxfordSentences
                                                ? oxfordSentences.map(
                                                  (array, index) => (
                                                    <TableRow
                                                      key={index}
                                                      sx={{
                                                        "&:last-child td, &:last-child th": {
                                                          border: 0,
                                                        },
                                                      }}
                                                    >
                                                      <TableCell align="right"></TableCell>
                                                      <TableCell align="left">
                                                        {array.sentence}{" "}
                                                      </TableCell>
                                                      <TableCell align="right">
                                                        {/* <IconButton
                                                          aria-label="volume"
                                                          size="small"
                                                          onClick={() =>
                                                            speak({
                                                              text:
                                                                array.sentence,
                                                            })
                                                          }
                                                        >
                                                          <VolumeUpIcon
                                                            sx={{
                                                              color:
                                                                "#34566A",
                                                            }}
                                                          />
                                                        </IconButton> */}
                                                        <IconButton
                                                          id="playaudio1"
                                                          aria-label="volume"
                                                          size="small"
                                                          onClick={() => speakText(array.sentence)}
                                                        >
                                                          <VolumeUpIcon
                                                            sx={{
                                                              color: "#34566A",
                                                            }}
                                                          />
                                                        </IconButton>
                                                      </TableCell>
                                                      <TableCell align="right">
                                                        <strong>
                                                          {" "}
                                                          {array.POS}{" "}
                                                        </strong>
                                                      </TableCell>
                                                    </TableRow>
                                                  )
                                                )
                                                : ""}
                                            </TableBody>
                                          </MTable>
                                        </TableContainer>
                                      </Grid>
                                    </Grid>
                                  </React.Fragment>
                                </>
                              ) : (
                                <>
                                  <Grid container>
                                    {loadingSentences && (
                                      <BeatLoader
                                        color={"#BD10E0"}
                                        loading={loadingSentences}
                                        size={30}
                                      />
                                    )}
                                    <Grid item xs={12} sm={12} md={12}>
                                      <br />
                                      <ReactTable3
                                        columns={columnsAllBookSentencesData}
                                        data={allBookSentencesData}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Button
                                    autoFocus
                                    onClose={() => setOpenModal(false)}
                                    color="secondary"
                                    onClick={addAllBookSentences}
                                  >
                                    Save Sentences
                                  </Button>
                                </>
                              )}

                              <Dialog
                                open={openModal}
                                onClose={() => setOpenModal(false)}
                                aria-labelledby="modal-slide-title"
                                aria-describedby="modal-slide-description"
                              >
                                <DialogTitle
                                  id="classic-modal-slide-title"
                                  style={{ textAlign: "center" }}
                                >
                                  <span className={classes.originHeaderText}>
                                    {" "}
                                    Sentences from {selectedOptionFunc.value}
                                  </span>
                                </DialogTitle>
                                <DialogContent>
                                  <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                      <table>
                                        <tbody>
                                          {bookSentences
                                            ? bookSentences.map(
                                              (array, index) => (
                                                <tr
                                                  key={index}
                                                  style={{
                                                    borderBottom:
                                                      "1px solid #ccc",
                                                  }}
                                                >
                                                  <GridContainer>
                                                    <GridItem
                                                      xs={12}
                                                      sm={12}
                                                      md={1}
                                                      style={{
                                                        paddingLeft: "5px",
                                                      }}
                                                    >
                                                      <td>{index + 1}</td>
                                                    </GridItem>
                                                    <GridItem
                                                      xs={12}
                                                      sm={12}
                                                      md={5}
                                                    >
                                                      <td>
                                                        {array.sentence}
                                                      </td>
                                                    </GridItem>
                                                    <GridItem
                                                      xs={12}
                                                      sm={12}
                                                      md={2}
                                                    >
                                                      <td>
                                                        <strong>
                                                          {" "}
                                                          {array.POS}
                                                        </strong>
                                                      </td>
                                                    </GridItem>
                                                    <GridItem
                                                      xs={12}
                                                      sm={12}
                                                      md={2}
                                                    >
                                                      <td>{authorName}</td>
                                                    </GridItem>
                                                    <GridItem
                                                      xs={12}
                                                      sm={12}
                                                      md={2}
                                                    >
                                                      <td>
                                                        <input
                                                          type="checkbox"
                                                          className="addCheckbox"
                                                          value={
                                                            array.sentence
                                                          }
                                                        />
                                                      </td>
                                                    </GridItem>
                                                  </GridContainer>
                                                </tr>
                                              )
                                            )
                                            : ""}
                                        </tbody>
                                      </table>
                                    </GridItem>
                                  </GridContainer>
                                </DialogContent>

                                <DialogActions>
                                  <Button
                                    autoFocus
                                    onClose={() => setOpenModal(false)}
                                    color="primary"
                                    onClick={addBookSentences}
                                  >
                                    Add Sentences
                                  </Button>
                                </DialogActions>
                              </Dialog>

                              {/* Edit sentence */}
                              <Dialog
                                open={openModalEdit}
                                onClose={() => setOpenModalEdit(false)}
                                aria-labelledby="modal-slide-title"
                                aria-describedby="modal-slide-description"
                              >
                                <DialogTitle
                                  id="classic-modal-slide-title"
                                  style={{ textAlign: "center" }}
                                >
                                  <span className={classes.originHeaderText}>
                                    {" "}
                                    {updateSentence
                                      ? "Edit Sentence"
                                      : "Add Sentence"}
                                  </span>
                                </DialogTitle>
                                <DialogContent>
                                  <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                      <textarea
                                        cols="60"
                                        rows="4"
                                        placeholder="Sentence......................................................................................"
                                        value={newSentence}
                                        onChange={(e) =>
                                          setNewSentence(e.target.value)
                                        }
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                      <CustomInput
                                        labelText="Type the name of a source or book here"
                                        id="sourceSentence"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        inputProps={{
                                          value: sourceSentence,
                                          onChange: (e) =>
                                            setSourceSentence(e.target.value),
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                      <CustomInput
                                        labelText="Enter the author's name here"
                                        id="authorName"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        inputProps={{
                                          value: authorNameSentence,
                                          onChange: (e) =>
                                            setAuthorNameSentence(e.target.value),
                                        }}
                                      />
                                    </GridItem>
                                  </GridContainer>
                                </DialogContent>

                                <DialogActions>
                                  <Button
                                    color="primary"
                                    onClick={() => setOpenModalEdit(false)}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    autoFocus
                                    onClose={() => setOpenModalEdit(true)}
                                    color="secondary"
                                    onClick={addNewSentence}
                                  >
                                    {updateSentence ? "Update" : "Submit"}
                                  </Button>
                                </DialogActions>
                              </Dialog>
                              {!isTableSenetences && (
                                <Button color="secondary" onClick={addSentence}>
                                  Add Sentence
                                </Button>
                              )}
                            </React.Fragment>
                          ),
                        },
                      ]}
                    />
                    <Dialog
                      style={{
                        fontSize: "12px",
                      }}
                      classes={{
                        root: classes.center,
                        paper: classes.modal,
                      }}
                      open={addWordDialog}
                      TransitionComponent={Transition}
                      keepMounted
                      onClose={() => setAddWordDialog(false)}
                      aria-labelledby="modal-slide-title"
                      aria-describedby="modal-slide-description"
                    >
                      <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                      >

                        <h4 className={classes.modalTitle}>
                          {isNewWordToform == false ? "Add new Word" : "Add word to this form"}
                        </h4>
                      </DialogTitle>
                      <DialogContent
                        id="modal-slide-description"
                        className={classes.modalBody}
                      >
                        <CustomInput
                          id="newWord"
                          labelText={isNewWordToform == false ? "Add new Word" : "Add word to this form"}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: newWord,
                            onChange: (e) =>
                              setNewWord(e.target.value),
                          }}
                        />
                        {isNewWordToform == false && <><CustomInput
                          id="newFrequency"
                          labelText="Add frequency"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: newFrequency,
                            onChange: (e) =>
                              setNewFrequency(e.target.value),
                          }}
                        />
                          <CustomInput
                            id="newSyllables"
                            labelText="Add syllables"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: newSyllables,
                              onChange: (e) =>
                                setNewSylables(e.target.value),
                            }}
                          />
                          <textarea
                            cols="80"
                            rows="5"
                            placeholder="Add definition"
                            value={newDefinition}
                            onChange={(e) => {
                              setNewDefinition(e.target.value);
                            }}
                          /></>}
                      </DialogContent>
                      <DialogActions
                        className={
                          classes.modalFooter +
                          " " +
                          classes.modalFooterCenter
                        }
                        style={{ margin: "auto" }}
                      >
                        <Button
                          color="secondary"
                          onClick={addNewWord}
                          style={{
                            fontWeight: 500,
                            fontSize: "12px",
                            textTransform: "uppercase",
                          }}
                        >
                          Add Word
                        </Button>

                        <Button
                          color="primary"
                          onClick={() => setAddWordDialog(false)}
                          style={{
                            fontWeight: 500,
                            fontSize: "12px",
                            textTransform: "uppercase",
                          }}
                        >
                          Cancel
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </GridItem>
                ),
              },
              {
                tabName: "Word Lists",
                tabIcon: BugReport,
                tabContent: (
                  <div>
                    <CustomTabs
                      title=""
                      showShadow={true}
                      headerColor="primary"
                      tabs={[
                        {
                          tabName: "Cognate Word lists",
                          tabIcon: Book,
                          tabContent: (
                            <div className={classes.searchWrapper}>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={1}></GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                  <CustomInput
                                    formControlProps={{
                                      className:
                                        classes.margin + " " + classes.search,
                                    }}
                                    labelText="Type in a Wordlist here"
                                    id="myInput"
                                    inputProps={{
                                      inputProps: {
                                        "aria-label": "Search",
                                      },
                                      value: searchWordList,
                                      onKeyUp: searchFunction(),
                                      // onChange: (e) => searchFunction(e),
                                      onChange: (e) => {
                                        setSearchWordList(e.target.value);
                                        setWordSyllables([]);
                                      },
                                    }}
                                  />
                                  <Button
                                    color="white"
                                    aria-label="edit"
                                    justIcon
                                    round
                                  >
                                    <Search onClick={handleSubmitWordListBtn} />
                                  </Button>
                                  {wordSyllables.map(function (item, i) {
                                    return (
                                      <p
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                        key={i}
                                        onClick={handleSearchSubmitWordList}
                                      >
                                        {item}
                                      </p>
                                    );
                                  })}
                                </GridItem>
                                <GridItem xs={12} sm={12} md={1}></GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                  <CustomInput
                                    formControlProps={{
                                      className:
                                        classes.margin + " " + classes.search,
                                    }}
                                    labelText="Search Wordlist by Word"
                                    id="float"
                                    inputProps={{
                                      // placeholder: "Type in a word here",
                                      inputProps: {
                                        "aria-label": "Search",
                                      },
                                      value: wordlistbyword,
                                      onChange: (e) => {
                                        setWordSyllables([]);
                                        setWordListByWord(e.target.value);
                                        // setSearchWordList('')
                                      },
                                    }}
                                  />
                                  <Button
                                    color="white"
                                    aria-label="edit"
                                    justIcon
                                    round
                                  >
                                    <Search
                                      onClick={handleSubmitWordListByWord}
                                    />
                                  </Button>
                                </GridItem>
                              </GridContainer>
                              {wordlistnamearr.map(function (item, i) {
                                return (
                                  <div key={i}>
                                    <Button
                                      color="primary"
                                      style={{ color: "#fff" }}
                                      onClick={handleSearchSubmitWordList}
                                    >
                                      {item}
                                    </Button>
                                    {averageWordFrequency ? (
                                      <strong>
                                        {" "}
                                        Average Word Frequency is{" "}
                                        {averageWordFrequency}
                                      </strong>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                );
                              })}
                              <div className={classes.root}>
                                <AppBar position="static">
                                  <Toolbar
                                    style={{ backgroundColor: "#34566A" }}
                                    className="table_wordlist_overflow"
                                  >
                                    <div>
                                      <div className="tab">
                                        <div>
                                          <h6>
                                            {searchWordList
                                              ? searchWordList
                                              : ""}
                                          </h6>
                                        </div>
                                        <div>
                                          <h6>
                                            <a
                                              onClick={(e) => {
                                                if (wordlistname == "") {
                                                  return toast.error(
                                                    "Wordlist name can't be empty",
                                                    {
                                                      position:
                                                        toast.POSITION
                                                          .TOP_CENTER,
                                                    }
                                                  );
                                                }
                                                setOpenRootFamilyStory(true);
                                              }}
                                              style={{
                                                color: "white",
                                                fontSize: "15px",
                                              }}
                                            >
                                              ROOT FAMILY STORY
                                            </a>
                                          </h6>
                                        </div>
                                        <div>
                                          <table className="table_wordlist1">
                                            <tbody>
                                              <tr>
                                                <th>
                                                  {" "}
                                                  <input
                                                    placeholder="Add word to this wordlist"
                                                    value={addWordWordlist}
                                                    onChange={(e) => {
                                                      setAddWordWorlist(
                                                        e.target.value
                                                      );
                                                    }}
                                                  />
                                                </th>
                                                <th>
                                                  <button
                                                    style={{
                                                      color: "#fff",
                                                      textTransform:
                                                        "uppercase",
                                                      backgroundColor:
                                                        "#34566A",
                                                    }}
                                                    onClick={
                                                      addWordWordlistFunction
                                                    }
                                                  >
                                                    Add
                                                  </button>
                                                </th>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </Toolbar>
                                </AppBar>
                              </div>
                              <main
                                style={{
                                  borderRight: "1px solid grey",
                                  borderLeft: "1px solid grey",
                                }}
                              >
                                <div
                                  style={{
                                    overflowX: "auto",
                                    overflowY: "auto",
                                    height: "500px",
                                  }}
                                >
                                  <table className="table_wordlist">
                                    <tbody>
                                      <tr>
                                        <th
                                          className="list-item"
                                          style={{ width: "20%" }}
                                        >
                                          <IconButton
                                            color="secondary"
                                            aria-label="delete"
                                            onClick={removeWordlist}
                                          >
                                            <Delete />
                                          </IconButton>
                                        </th>
                                        <th className="list-item">
                                          WORD
                                          {/* <IconButton onChange={sortCognateListFunction}>
                                            <Checkbox /> </IconButton><strong>{sortCognateList ? "Sort alphabetically" : "Sort by headwords "}</strong> */}
                                        </th>
                                        <th className="list-items1">
                                          CLASSIC MEANING
                                        </th>
                                        <th className="list-items">
                                          WORD AUDIO
                                        </th>
                                        <th className="list-items">
                                          SENTENCE AUDIO
                                        </th>
                                        <th className="list-items">
                                          HEAD WORD
                                        </th>
                                        <th className="list-items">
                                          DICTIONARY
                                        </th>
                                        <th className="list-items">ORIGIN</th>
                                        <th className="list-items">SYNONYMS</th>
                                        <th className="list-items">ANTONYMS</th>
                                        <th className="list-items">POS EXERCISE</th>
                                        <th className="list-items">STORY</th>
                                        <th className="list-items">
                                          SENTENCES 1
                                        </th>
                                        <th className="list-items">
                                          SENTENCES 2
                                        </th>
                                        <th className="list-items">
                                          FREQUENCY
                                        </th>
                                        <th className="list-items">COMMON</th>
                                        <th className="list-items">NON-COG</th>
                                      </tr>

                                      {Object.keys(wordsObjToDisplay).map(
                                        function (word, i) {
                                          return (
                                            <tr key={i + 1}>
                                              <td>
                                                <input
                                                  type="checkbox"
                                                  className="deleteCheckbox"
                                                  value={
                                                    wordsObjToDisplay[word].word
                                                  }
                                                />
                                                <span
                                                  style={{ marginLeft: "5px" }}
                                                >
                                                  {i + 1}
                                                </span>
                                              </td>
                                              <td
                                                className="wordBtn"
                                                onClick={() =>
                                                  fetchwordDetail(
                                                    1,
                                                    wordsObjToDisplay[word].word
                                                  )
                                                }
                                              >
                                                {wordsObjToDisplay[word].word}
                                                {/* </Button> */}
                                              </td>
                                              <td>
                                                {" "}
                                                {
                                                  wordsObjToDisplay[word]
                                                    .etym_meaning
                                                }
                                              </td>
                                              <td
                                                style={{
                                                  textAlign: "center",
                                                }}
                                              >
                                                {audioStatus[wordsObjToDisplay[word].word] === true ? '✓' : ''}
                                              </td>
                                              <td
                                                style={{
                                                  textAlign: "center",
                                                }}
                                              >
                                                {audioStatus[wordsObjToDisplay[word].word + "_s"] === true ? '✓' : ''}
                                              </td>
                                              <td
                                                style={{ textAlign: "center" }}
                                              >
                                                {wordsObjToDisplay[word]
                                                  .isHeadWord && "✓"}
                                              </td>
                                              <td
                                                style={{ textAlign: "center" }}
                                              >
                                                {wordsObjToDisplay[word]
                                                  .dictionary && "✓"}
                                              </td>
                                              <td
                                                style={{ textAlign: "center" }}
                                              >
                                                {wordsObjToDisplay[word]
                                                  .origin && "✓"}
                                              </td>
                                              <td
                                                style={{ textAlign: "center" }}
                                              >
                                                {wordsObjToDisplay[word]
                                                  .synonyms && "✓"}
                                              </td>
                                              <td
                                                style={{ textAlign: "center" }}
                                              >
                                                {wordsObjToDisplay[word]
                                                  .antonyms && "✓"}
                                              </td>
                                              <td
                                                style={{ textAlign: "center" }}
                                              >
                                                {wordsObjToDisplay[word]
                                                  .posExercise && "✓"}
                                              </td>
                                              <td
                                                style={{ textAlign: "center" }}
                                              >
                                                {wordsObjToDisplay[word]
                                                  .story && "✓"}
                                              </td>
                                              <td
                                                style={{ textAlign: "center" }}
                                              >
                                                {
                                                  wordsObjToDisplay[word]
                                                    .sentences1
                                                }
                                              </td>
                                              <td
                                                style={{ textAlign: "center" }}
                                              >
                                                {
                                                  wordsObjToDisplay[word]
                                                    .sentences2
                                                }
                                              </td>
                                              <td
                                                style={{ textAlign: "center" }}
                                              >
                                                {wordsObjToDisplay[word]
                                                  .frequency
                                                  ? wordsObjToDisplay[word]
                                                    .frequency
                                                  : 0}
                                              </td>
                                              <td>
                                                {" "}
                                                <input type="checkbox" />
                                              </td>
                                              <td>
                                                <input type="checkbox" />
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </main>
                              <Dialog
                                classes={{
                                  root: classes.center,
                                  paper: classes.modal,
                                }}
                                open={openRootFamilyStory}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={() => setOpenRootFamilyStory(false)}
                                aria-labelledby="modal-slide-title"
                                aria-describedby="modal-slide-description"
                              >
                                <DialogTitle
                                  id="classic-modal-slide-title"
                                  disableTypography
                                  className={classes.modalHeader}
                                >
                                  <h4 className={classes.modalTitle}>
                                    Add Root Family Story{" "}
                                  </h4>
                                </DialogTitle>
                                <DialogContent
                                  id="modal-slide-description"
                                  className={classes.modalBody}
                                >
                                  <TextField
                                    id="outlined-multiline-static"
                                    multiline
                                    rows={5}
                                    fullWidth
                                    value={newRootFamilyStory}
                                    onChange={(e) =>
                                      setNewRootFamilyStory(e.target.value)
                                    }
                                  />
                                </DialogContent>
                                <DialogActions
                                  className={
                                    classes.modalFooter +
                                    " " +
                                    classes.modalFooterCenter
                                  }
                                >
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      setOpenRootFamilyStory(false)
                                    }
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    color="secondary"
                                    onClick={SaveRootFamilyStory}
                                  >
                                    {newRootFamilyStory ? "Update" : "Save"}
                                  </Button>
                                </DialogActions>
                              </Dialog>
                              {openAddWordModalCognateWordlist && (
                                <AddConfirmationDialog
                                  itemName={selectedItemToAdd}
                                  handleAddWordToWordlist={handleAddWordToWordlist}
                                  onCancel={handleCancel}
                                  item={itemToAddWordModal}
                                />
                              )}
                            </div>
                          ),
                        },
                        {
                          tabName: "Bookscan Master Lists",
                          tabIcon: Book,
                          tabContent: (
                            <div className={classes.searchWrapper}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={3} md={3}>
                                  <FormGroup>
                                    <FormControlLabel control={<Switch size="x-large" onChange={(e) => { setShowUploadList(!showUploadList) }} checked={showUploadList} />} label={showUploadList ? "Show upload List" : "Show bookscan List"} />
                                  </FormGroup>
                                </Grid >
                              </Grid >
                              {showUploadList ? <GridContainer>
                                <Grid
                                  container
                                  spacing={{ md: 2 }}
                                  justifyContent="center"
                                >
                                  {/* <Grid item sm={2} md={3}></Grid> */}
                                  <Grid item xs={12} sm={8} md={6} lg={4}>
                                    <Card
                                      style={{
                                        width: "100%",
                                        margin: "1rem auto",
                                        backgroundColor: "#34566A",
                                        boxShadow: "none",
                                        border: "1px solid #34566A",
                                        borderRadius: "6px",
                                        color: "#FFFFFF",
                                      }}
                                    >
                                      <CardBody>
                                        <div
                                          style={{
                                            padding: "18px 10px 0 10px",
                                            boxShadow: "none",
                                            borderRadius: "0px",
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                            fontWeight: 700,
                                            fontSize: "1.1rem",
                                            lineHeight: "15px",
                                            color: "#FFFFFF",
                                          }}
                                        >
                                          Upload List
                                        </div>
                                        <form
                                          id="bookForm"
                                          name="bookForm"
                                          style={{ paddingBottom: "15px" }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              padding: "0px 18px",
                                              marginTop: "28px",
                                              fontSize: "14px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            <CustomInput
                                              formControlProps={{
                                                className: classes.search,
                                              }}
                                              whiteLabelRoot={true}
                                              labelText="Author"
                                              type="text"
                                              id="float"
                                              name="author"
                                              value={uploadBookauthor}
                                              inputProps={{
                                                value: uploadBookauthor,
                                                onChange: (e) =>
                                                  setUploadBookauthor(
                                                    e.target.value
                                                  ),
                                              }}
                                            />
                                            <div
                                              style={{
                                                borderRadius: "5px",
                                                maxHeight: "121px",
                                                height: "100%",
                                                border: "2px dashed #FFFFFF",
                                                textAlign: "center",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  flexDirection: "column",
                                                  color: "#C4C4C4",
                                                  paddingTop: "10px",
                                                  paddingBottom: "17px",
                                                }}
                                              // onDragOver={handleDragOver}
                                              // onDrop={handleDrop}
                                              >
                                                {/* <span>Drag &amp; drop</span>
                                                  <span>Or</span> */}

                                                <span>
                                                  <input
                                                    style={{
                                                      display: "none",
                                                    }}
                                                    type="file"
                                                    name="book"
                                                    className="custom-file-input"
                                                    id="customFile"
                                                    ref={fileInputRef}
                                                    onChange={
                                                      handleFileChange
                                                    }
                                                    required
                                                  />
                                                  <Button
                                                    type="button"
                                                    color="primary"
                                                    style={{
                                                      border:
                                                        "1px solid white",
                                                      maxWidth: "110px",
                                                      width: "100%",
                                                      margin: "0 auto",
                                                      borderRadius: "3px",
                                                      textTransform: "none",
                                                      color: "#bbb",
                                                    }}
                                                    onClick={
                                                      handleFileBtnClick
                                                    }
                                                  >
                                                    <Folder
                                                      sx={{ fontSize: 40 }}
                                                    />
                                                    Browse
                                                  </Button>
                                                  <span
                                                    style={{
                                                      display:
                                                        file !== ""
                                                          ? "inline-block"
                                                          : "none",
                                                      marginLeft: "5px",
                                                    }}
                                                  >
                                                    {file && file.name}
                                                  </span>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <br />
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Button
                                              color="secondary"
                                              onClick={handleFileUpload}
                                              startIcon={
                                                <FileUploadOutlined
                                                  fontSize={"14px"}
                                                />
                                              }
                                            >
                                              Upload
                                            </Button>
                                          </div>
                                        </form>
                                      </CardBody>
                                    </Card>
                                  </Grid></Grid>
                              </GridContainer> :
                                <>
                                  <GridContainer>
                                    <GridItem xs={12} sm={12} md={4}>
                                      <CustomInput
                                        formControlProps={{
                                          className:
                                            classes.margin + " " + classes.search,
                                        }}
                                        labelText="Search by Book Title"
                                        id="bookscanTitle"
                                        inputProps={{
                                          // placeholder: "Type in a word here",
                                          inputProps: {
                                            "aria-label": "Search",
                                          },
                                          value: bookscanTitle,
                                          // onChange: (e) => {
                                          //   setBookscanTitle(e.target.value);
                                          // },
                                          onChange: handleSearchBookscanListChange
                                        }}
                                      />
                                      {bookscanWordlistSpiner && (
                                        <BeatLoader
                                          color={"#BD10E0"}
                                          loading={bookscanWordlistSpiner}
                                          size={15}
                                        />
                                      )}
                                      {/* <Button
                                    color="white"
                                    aria-label="edit"
                                    justIcon
                                    round
                                  >
                                    <Search
                                      onClick={
                                        handleSubmitBookScanWordlistByTitle
                                      }
                                    />
                                  </Button> */}
                                    </GridItem>

                                  </GridContainer>
                                  {bookscanTitle && (
                                    <>
                                      {filteredBookscanLists.map((item, i) => (
                                        <p
                                          onMouseEnter={handleMouseEnter}
                                          onMouseLeave={handleMouseLeave}
                                          key={i} onClick={handleSubmitBookScanWordlistByTitle}>
                                          {item}
                                        </p>
                                      ))}
                                    </>
                                  )}

                                  <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                      <div>
                                        <Table
                                          tableHeaderColor="primary"
                                          tableHead={[
                                            "Title",
                                            "Author",
                                            "Type",
                                          ]}
                                          tableData={bookScanListsArr}
                                        />
                                        <p
                                          style={{
                                            textAlign: "center",
                                            paddingTop: "20px",
                                          }}
                                        >
                                          <strong
                                            className={
                                              classes1.highlightStyle
                                            }
                                          >
                                            Insert new word below last checked word.
                                          </strong>
                                          <br />
                                          {isFetchingBookCousinCouns &&
                                            <strong
                                              styles={{ backgroundColor: 'blue' }}
                                            >
                                              Fetching cousins in the book...
                                            </strong>}
                                        </p>
                                      </div>
                                      <div className={classes.root}>
                                        <AppBar position="static">
                                          <Toolbar
                                            className="table_wordlist_overflow"
                                            style={{ backgroundColor: "#34566A" }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-evenly",
                                              }}
                                            >
                                              <GridItem xs={12} sm={12} md={4}>
                                                <DropdownButton
                                                  variant="secondary"
                                                  title={tableHeading}
                                                >
                                                  {!isStudyList && (
                                                    <Dropdown.Item
                                                      onClick={saveStudyList}
                                                    >
                                                      Save
                                                    </Dropdown.Item>
                                                  )}
                                                  <Dropdown.Item
                                                    onClick={removeBookScanList}
                                                  >
                                                    Remove
                                                  </Dropdown.Item>
                                                </DropdownButton>
                                              </GridItem>
                                              <GridItem xs={12} sm={12} md={6}>
                                                <table className="table_wordlist1">
                                                  <tbody>
                                                    <tr>
                                                      <th>
                                                        {" "}
                                                        <input
                                                          placeholder="Add word to this wordlist"
                                                          value={addWordWordlist}
                                                          onChange={(e) => {
                                                            setAddWordWorlist(
                                                              e.target.value
                                                            );
                                                          }}
                                                        />
                                                      </th>
                                                      <th>
                                                        <button
                                                          style={{
                                                            color: "#fff",
                                                            textTransform:
                                                              "uppercase",
                                                            backgroundColor:
                                                              "#34566A",
                                                          }}
                                                          onClick={
                                                            addWordBookScanList
                                                          }
                                                        >
                                                          Add
                                                        </button>
                                                      </th>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </GridItem>
                                            </div>
                                          </Toolbar>
                                        </AppBar>
                                      </div>
                                      <main
                                        style={{
                                          borderRight: "1px solid grey",
                                          borderLeft: "1px solid grey",
                                        }}
                                      >
                                        <div
                                          style={{
                                            overflowX: "auto",
                                            overflowY: "auto",
                                            height: "500px",
                                          }}
                                        >
                                          <table className="table_wordlist">
                                            <tbody>
                                              <tr>
                                                <th style={{ width: "20%" }}>
                                                  <input
                                                    type="number"
                                                    placeholder="Enter a number"
                                                    value={checkedCheckboxes.length}
                                                    onChange={handleOnchangeCheckedCheckboxes}
                                                  />
                                                  {/* <input
                                                style={{ width: "40px" }}
                                                value={selectNumberOfWordDetail}
                                                name="selectNumberOfWordDetail"
                                                readOnly
                                              /> */}
                                                </th>
                                                <th className="list-item">WORD</th>

                                                <th className="list-items1">
                                                  CLASSIC MEANING
                                                </th>
                                                <th className="list-items">
                                                  WORD AUDIO
                                                </th>
                                                <th className="list-items">
                                                  SENTENCE AUDIO
                                                </th>
                                                <th className="list-items">
                                                  CHAPTER
                                                </th>
                                                <th className="list-items">
                                                  HEAD WORD
                                                </th>
                                                <th className="list-items">
                                                  DICTIONARY
                                                </th>
                                                <th className="list-items">
                                                  COUSINS Bk
                                                </th>
                                                <th className="list-items">
                                                  COUSINS Oth
                                                </th>
                                                <th className="list-items">
                                                  ORIGIN
                                                </th>
                                                <th className="list-items">
                                                  SYNONYMS
                                                </th>
                                                <th className="list-items">
                                                  ANTONYMS
                                                </th>
                                                <th className="list-items">
                                                  POS EXERCISE
                                                </th>
                                                <th className="list-items">
                                                  STORY
                                                </th>
                                                <th className="list-items">
                                                  SENTENCES 1
                                                </th>
                                                <th className="list-items">
                                                  SENTENCES 2
                                                </th>
                                                <th className="list-items" onClick={(e) => {
                                                  if (bookScanListsTableArr && bookScanListsTableArr?.length > 0) {
                                                    setBookScanListsTableArr([...bookScanListsTableArr.sort((a, b) => (ascendingBookscanSort ? a.frequency - b.frequency : b.frequency - a.frequency))]);
                                                    setAscendingBookscanSort(!ascendingBookscanSort);
                                                  }
                                                }}
                                                  style={{ cursor: 'pointer' }}
                                                >

                                                  FREQUENCY <span className={
                                                    classes1.highlightStyle
                                                  }>Sort</span>

                                                </th>
                                              </tr>
                                              {bookScanListsTableArr.map(function (
                                                item,
                                                i
                                              ) {
                                                return (
                                                  <tr key={i + 1}>
                                                    <td>

                                                      <input
                                                        type="checkbox"
                                                        onChange={handleOnChangeNumberOfWordDetail}
                                                        checked={checkedCheckboxes.includes(item.word)}
                                                        className="studyListCheckbox"
                                                        value={item.word}
                                                      />
                                                      {/* {selectNumberOfWordDetail<=60 && i <selectNumberOfWordDetail ?<Checkbox
                                                              inputProps={{
                                                                "aria-label":
                                                                  "controlled",
                                                              }}
                                                              value = {true}
                                                              checked
                                                            />:<Checkbox
                                                            inputProps={{
                                                              "aria-label":
                                                                "controlled",
                                                            }}
                                                            value =  {true}
                                                          />} */}
                                                      <span
                                                        className="span"
                                                        style={{
                                                          marginLeft: "12px",
                                                        }}
                                                      >
                                                        {i + 1}
                                                      </span>
                                                    </td>
                                                    <td
                                                      className="wordBtn"
                                                      style={{
                                                        backgroundColor: bookScanListsTableArrSaved?.includes(
                                                          item.word
                                                        )
                                                          ? "#808080"
                                                          : "",
                                                      }}
                                                      onClick={() =>
                                                        fetchwordDetail(
                                                          3,
                                                          item.word
                                                        )
                                                      }
                                                    >
                                                      {item.word}
                                                    </td>
                                                    <td> {item.etym_meaning}</td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {audioStatus[item.word] === true ? '✓' : ''}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {audioStatus[item.word + "_s"] === true ? '✓' : ''}
                                                    </td>
                                                    <td></td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {item.isHeadWord && "✓"}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {item.dictionary && "✓"}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {bookCousinsCount && bookCousinsCount[item.word]}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {bookCousinsCount && bookCousinsCount[item.word + "_oth"]}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {item.origin && "✓"}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {item.synonyms && "✓"}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {item.antonyms && "✓"}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {item.posExercise && "✓"}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {item.story && "✓"}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {item.sentences1}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {item.sentences2}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {item.frequency}
                                                    </td>
                                                  </tr>
                                                );
                                              })}
                                            </tbody>
                                          </table>
                                        </div>
                                      </main>
                                    </GridItem>
                                  </GridContainer>
                                  <Dialog
                                    open={openModalStudyList}
                                    onClose={() => setOpenModalStudyList(false)}
                                    aria-labelledby="modal-slide-title"
                                    aria-describedby="modal-slide-description"
                                  >
                                    <DialogTitle
                                      id="classic-modal-slide-title"
                                      style={{ textAlign: "center" }}
                                    >
                                      <span className={classes.originHeaderText}>
                                        Name the study list of selected words
                                      </span>
                                    </DialogTitle>
                                    <DialogContent>
                                      <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                          <CustomInput
                                            labelText=" Name the Study List"
                                            id="studyListName"
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                              value: studyListName,
                                              onChange: (e) =>
                                                setStudyListName(e.target.value),
                                            }}
                                          />
                                        </GridItem>
                                      </GridContainer>
                                    </DialogContent>

                                    <DialogActions>
                                      <Button
                                        color="primary"
                                        onClick={() => setOpenModalStudyList(false)}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        autoFocus
                                        onClose={() => setOpenModalStudyList(true)}
                                        color="secondary"
                                        onClick={saveStudyList}
                                      >
                                        Submit
                                      </Button>
                                    </DialogActions>
                                  </Dialog>
                                  {openAddWordModalBookscanWordlist && (
                                    <AddConfirmationDialog
                                      itemName={selectedItemToAdd}
                                      handleAddWordToWordlist={handleAddWordToWordlist}
                                      onCancel={handleCancel}
                                      item={itemToAddWordModal}
                                    />
                                  )}</>}
                            </div>
                          ),
                        },
                        {
                          tabName: "Lesson Word Lists",
                          tabIcon: Book,
                          tabContent: (
                            <div className={classes.searchWrapper}>
                              <CustomInput
                                formControlProps={{
                                  className:
                                    classes.margin + " " + classes.search,
                                }}
                                labelText="Search Wordlist by Name"
                                id="float"
                                inputProps={{
                                  inputProps: {
                                    "aria-label": "Search",
                                  },
                                  onKeyUp: searchWordListByTittleFunction(),
                                  value: wordlistbytitle,
                                  // value: wordlistbyword,
                                  onChange: (e) => {
                                    setWordListByTitle(e.target.value);
                                    setLiteratureLists([]);
                                  },
                                }}
                              />
                              {/* <Button
                                color="white"
                                aria-label="edit"
                                justIcon
                                round
                              >
                                <Search onClick={handleSubmitWordListByTitle} />
                              </Button> */}
                              {literatureWordlistSpiner && (
                                <BeatLoader
                                  color={"#BD10E0"}
                                  loading={literatureWordlistSpiner}
                                  size={15}
                                />
                              )}
                              {literatureLists.map(function (item, i) {
                                return (
                                  <p
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    key={i}
                                    onClick={(e) => {
                                      handleSubmitLiteratureWordListByTitle(item)

                                    }
                                    }
                                  >
                                    {item}
                                  </p>
                                );
                              })}
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                  <div>
                                    <Table
                                      tableHeaderColor="primary"
                                      tableHead={[
                                        "Wordlist Name",
                                        "Title",
                                        "Author",
                                        "Status",
                                        "Edit"
                                      ]}
                                      tableData={literatureListsArr}
                                    />
                                    { }
                                  </div>
                                  {/* <button onClick={handleLoadAllAudio}>Load All Audio</button> */}
                                  <div className={classes.root}>
                                    <AppBar position="static">
                                      <Toolbar
                                        style={{ backgroundColor: "#34566A" }}
                                        className="table_wordlist_overflow"
                                      >
                                        <div>
                                          <div className="tab">
                                            <div>
                                              <h6>
                                                {searchWordList
                                                  ? searchWordList
                                                  : ""}
                                              </h6>
                                            </div>
                                            <div>
                                              <table className="table_wordlist1">
                                                <tbody>
                                                  <tr>
                                                    <th>
                                                      {" "}
                                                      <input
                                                        placeholder="Add word to this wordlist"
                                                        value={
                                                          addWordLegacyWordlist
                                                        }
                                                        onChange={(e) => {
                                                          setAddWordLegacyWorlist(
                                                            e.target.value
                                                          );
                                                        }}
                                                      />
                                                    </th>
                                                    <th>
                                                      <button
                                                        style={{
                                                          color: "#fff",
                                                          textTransform:
                                                            "uppercase",
                                                          backgroundColor:
                                                            "#34566A",
                                                        }}
                                                        onClick={
                                                          addWordLegacyWorlistFunction
                                                        }
                                                      >
                                                        Add
                                                      </button>
                                                    </th>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </Toolbar>
                                    </AppBar>
                                  </div>
                                  <main
                                    style={{
                                      borderRight: "1px solid grey",
                                      borderLeft: "1px solid grey",
                                    }}
                                  >
                                    <div
                                      style={{
                                        overflowX: "auto",
                                        overflowY: "auto",
                                        height: "500px",
                                      }}
                                    >
                                      <table className="table_wordlist">
                                        <tbody>
                                          <tr>
                                            <th
                                              className="list-item"
                                              style={{ width: "20%" }}
                                            >
                                              <IconButton
                                                color="secondary"
                                                aria-label="delete"
                                                onClick={
                                                  removeLegacyWordWordlist
                                                }
                                              >
                                                <Delete />
                                              </IconButton>
                                            </th>
                                            <th className="list-item">WORD</th>
                                            <th className="list-items1">
                                              CLASSIC MEANING
                                            </th>
                                            <th className="list-items">
                                              WORD AUDIO
                                            </th>
                                            <th className="list-items">
                                              SENTENCE AUDIO
                                            </th>
                                            <th className="list-items">
                                              HEAD WORD
                                            </th>
                                            <th className="list-items">
                                              DICTIONARY
                                            </th>
                                            <th className="list-items">
                                              COUSINS Bk
                                            </th>
                                            <th className="list-items">
                                              COUSINS Oth
                                            </th>
                                            <th className="list-items">
                                              ORIGIN
                                            </th>
                                            <th className="list-items">
                                              SYNONYMS
                                            </th>
                                            <th className="list-items">
                                              ANTONYMS
                                            </th>
                                            <th className="list-items">
                                              POS EXERCISE
                                            </th>
                                            <th className="list-items">
                                              STORY
                                            </th>
                                            <th className="list-items">
                                              SENTENCES 1
                                            </th>
                                            <th className="list-items">
                                              SENTENCES 2
                                            </th>
                                            <th className="list-items">
                                              FREQUENCY
                                            </th>
                                          </tr>
                                          {literatureListsTableArr.map(
                                            function (item, i) {
                                              return (
                                                <tr key={i + 1}>
                                                  <td>
                                                    {" "}
                                                    <input
                                                      type="checkbox"
                                                      className="deleteLegacyCheckbox"
                                                      value={item.word}
                                                      style={{
                                                        marginLeft: "8px",
                                                      }}
                                                    />
                                                    <span
                                                      className="span"
                                                      style={{
                                                        marginLeft: "12px",
                                                      }}
                                                    >
                                                      {i + 1}
                                                    </span>
                                                  </td>
                                                  <td
                                                    className="wordBtn"
                                                    onClick={() =>
                                                      fetchwordDetail(
                                                        2,
                                                        item.word
                                                      )
                                                    }
                                                  >
                                                    {/* <Button
                                                      style={{
                                                        textDecoration:
                                                          "underline",
                                                        color: "#000",
                                                        backgroundColor: "#fff",
                                                      }}
                                                      onClick={handleSubmit}
                                                    > */}
                                                    {item.word}
                                                    {/* </Button> */}
                                                  </td>
                                                  <td> {item.etym_meaning}</td>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {audioStatus[item.word] === true ? '✓' : ''}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {audioStatus[item.word + "_s"] === true ? '✓' : ''}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {item.isHeadWord && "✓"}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {item.dictionary && "✓"}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  ></td>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  ></td>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {item.origin && "✓"}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {item.synonyms && "✓"}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {item.antonyms && "✓"}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {item.posExercise && "✓"}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  ></td>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {item.sentences1}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {item.sentences2}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {item.frequency}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </main>
                                </GridItem>
                              </GridContainer>
                              <Dialog
                                open={openModalWordList}
                                onClose={() => setOpenModalWordList(false)}
                                aria-labelledby="modal-slide-title"
                                aria-describedby="modal-slide-description"
                              >
                                <DialogTitle
                                  id="classic-modal-slide-title"
                                  style={{ textAlign: "center" }}
                                > {openModalModifyLessonWordlist == true ?
                                  (<span
                                    style={{ textTransform: "none" }}
                                    className={classes.originHeaderText}
                                  >
                                    Edit Lesson wordlist

                                  </span>) :
                                  <span
                                    style={{ textTransform: "none" }}
                                    className={classes.originHeaderText}
                                  >
                                    {" "}
                                    Are you sure you want to{" "}
                                    <strong>{legacyLiteratureStatus == 1 ? 'activate' : 'deactivate'}</strong> this
                                    wordlist name ?
                                  </span>}
                                </DialogTitle>
                                <DialogContent>
                                  {openModalModifyLessonWordlist == true ?
                                    <GridContainer>
                                      <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                          labelText="Lesson wordlist name"
                                          id="newLessonWordlistName"
                                          formControlProps={{
                                            fullWidth: true,
                                          }}
                                          inputProps={{
                                            value: newLessonWordlistName,
                                            onChange: (e) =>
                                              setNewLessonWordlistName(e.target.value),
                                          }}
                                        />
                                      </GridItem>
                                      <CustomInput
                                        labelText="Lesson wordlist title"
                                        id="newLessonWordlistTitle"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        inputProps={{
                                          value: newLessonWordlistTitle,
                                          onChange: (e) =>
                                            setNewLessonWordlistTitle(e.target.value),
                                        }}
                                      />
                                      <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                          labelText="Lesson wordlist author"
                                          id="newLessonWordlistAuthor"
                                          formControlProps={{
                                            fullWidth: true,
                                          }}
                                          inputProps={{
                                            value: newLessonWordlistAuthor,
                                            onChange: (e) =>
                                              setNewLessonWordlistAuthor(e.target.value),
                                          }}
                                        />
                                      </GridItem>
                                    </GridContainer> :
                                    <GridContainer>
                                      <GridItem xs={12} sm={12} md={12}>
                                        {literatureListsArr.map(function (
                                          item,
                                          index
                                        ) {
                                          return (
                                            <React.Fragment key={index}>
                                              <p>
                                                Wordlist ID:{" "}
                                                <strong>
                                                  {legacyLiteratureWord_list_id}
                                                </strong>
                                              </p>
                                              <p>
                                                Wordlist Name:{" "}
                                                <strong>{item[0]}</strong>
                                              </p>
                                              <p>
                                                Wordlist Title:{" "}
                                                <strong>{item[1]}</strong>
                                              </p>
                                              <p>
                                                Wordlist Author:{" "}
                                                <strong>{item[2]}</strong>
                                              </p>
                                            </React.Fragment>
                                          );
                                        })}
                                      </GridItem>
                                    </GridContainer>}
                                </DialogContent>

                                <DialogActions>
                                  <Button
                                    color="primary"
                                    onClick={() => {
                                      setOpenModalWordList(false);
                                      setOpenModalModifyLessonWordlist(false);
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                  {openModalModifyLessonWordlist == true ?
                                    <Button
                                      autoFocus
                                      onClose={() => setOpenModalWordList(true)}
                                      color="secondary"

                                      onClick={
                                        handleOnChangeModifyLegacyLiterature
                                      }
                                    >
                                      Submit
                                    </Button> : <Button
                                      autoFocus
                                      onClose={() => setOpenModalWordList(true)}
                                      color="secondary"

                                      onClick={
                                        handleOnChangeLegacyLiteratureStatus
                                      }
                                    >
                                      Yes
                                    </Button>}
                                </DialogActions>
                              </Dialog>
                              {openAddWordModalLegacyWordlist && (
                                <AddConfirmationDialog
                                  itemName={selectedItemToAdd}
                                  handleAddWordToWordlist={handleAddWordToWordlist}
                                  onCancel={handleCancel}
                                  item={itemToAddWordModal}
                                />
                              )}
                            </div>
                          ),
                        },
                      ]}
                    />
                  </div>
                ),
              },
              {
                tabName: "Root Families",
                tabIcon: Book,
                tabContent: (
                  <div>
                    <div className={classes.searchWrapper}>
                      <Grid
                        container
                        spacing={2}
                        columnSpacing={{ sm: 4, md: 2 }}
                        style={{
                          padding: "3rem 3rem 1rem 3rem",
                        }}
                      >
                        <Grid item xs={8} md={6}>
                          <FormControl
                            variant="standard"
                            sx={{ m: 1, mt: 3, width: "30ch" }}
                          >
                            <InputLabel
                              htmlFor="standard-adornment-search"
                              style={{
                                color: "#34566A",
                                fontSize: "13px",
                                fontWeight: 500,
                              }}
                            >
                              Search root by meaning
                            </InputLabel>

                            <Input
                              id="myInput"
                              value={searchTerm}
                              onChange={handleRootFamiliesSearch}

                              aria-describedby="standard-search-helper-text"
                              inputProps={{
                                "aria-label": "search",
                              }}
                            />
                            {/* <Input
                              id="myInput"
                              value={searchRootList}
                              onKeyUp={searchRootFunction()}
                              onChange={(e) => {
                                setSearchRootList(e.target.value);
                                setFilterRootsArr([]);
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="search icon"
                                    onClick={handleSubmitRootListBtn}
                                  >
                                    <SearchOutlinedIcon />
                                  </IconButton>
                                </InputAdornment>
                              }
                              aria-describedby="standard-search-helper-text"
                              inputProps={{
                                "aria-label": "search",
                              }}
                            /> */}
                          </FormControl>
                          <div className={classes.rootMeaningFilterWrapper}>
                            <Grid item>
                              <List>
                                {/* {filterRootsArr.map(function (item, i) {
                                  return (
                                    <ListItem
                                      key={i}
                                      style={{ paddingBottom: "0px" }}
                                    >
                                      <ListItemText
                                        primary={item}
                                        name="meaning"
                                        onClick={handleSearchSubmitRoot}
                                        style={{ fontSize: "12px" }}
                                      />
                                    </ListItem>
                                  );
                                })} */}
                                {filteredData && filteredData.length > 0 ? (
                                  <>
                                    {filteredData.map((item, i) => (
                                      <div key={i}>
                                        <p onClick={(e) => {
                                          handleSearchSubmitRoot(item)
                                        }}><strong>{item.meaning}</strong>{" : Exact root ("}{item.exact_root}{")"}</p>
                                      </div>
                                    ))}
                                  </>
                                ) : ""}
                              </List>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item xs={8} md={6}>
                          <FormControl
                            variant="standard"
                            sx={{ m: 1, mt: 3, width: "30ch" }}
                          >
                            <InputLabel
                              htmlFor="standard-adornment-search"
                              style={{
                                color: "#34566A",
                                fontSize: "13px",
                                fontWeight: 500,
                              }}
                            >
                              Search by root
                            </InputLabel>
                            <Input
                              id="float"
                              value={searchbyroot}
                              onChange={(e) => {
                                setWordSyllables([]);
                                setSearchByRoot(e.target.value);
                                setSearchWordList("");
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="search icon"
                                    onClick={handleSubmitSearchByRoot}
                                  >
                                    <SearchOutlinedIcon />
                                  </IconButton>
                                </InputAdornment>
                              }
                              aria-describedby="standard-search-helper-text"
                              inputProps={{
                                "aria-label": "search",
                              }}
                            />
                            <FormHelperText id="standard-search-helper-text">
                              e.g bene
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item>
                          <div style={{ marginLeft: "9px" }}>
                            {rootFamily &&
                              rootFamily.meaning &&
                              rootFamily.root_family ? (
                              <Grid
                                container
                                spacing={2}
                                columnSpacing={{ sm: 4, md: 2 }}
                              >
                                <Grid item xs={12} md={6}>
                                  <Mbutton
                                    variant="outlined"
                                    sx={{ color: "#34566A" }}
                                  >
                                    {rootFamily.meaning}
                                  </Mbutton>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Mbutton
                                    variant="outlined"
                                    sx={{ color: "#34566A" }}
                                  >
                                    {rootFamily.root_family}
                                  </Mbutton>
                                </Grid>
                                <Grid item xs={12} md={1}>
                                  <IconButton
                                    color="primary"
                                    aria-label="edit"
                                    onClick={() => {
                                      setIsEditRootFamilies(true);
                                      setNewRootFamilyFromRootFamilies(
                                        rootFamily.root_family
                                      );
                                      setNewRootMeaningFromRootFamilies(
                                        rootFamily.meaning
                                      );
                                    }}
                                  >
                                    <Edit />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            ) : (
                              ""
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <div className={classes.rootFamSearchText}>
                            Words containing this root
                          </div>
                          <hr />
                          <ol>
                            {(rootWordsArrToDisplay || []).map(function (
                              item,
                              i
                            ) {
                              return (
                                <li
                                  key={i}
                                  onClick={handleClose}
                                  className={classes.dropdownItem}
                                >
                                  <Link to={`/admin/words/${item}`}>
                                    {item}
                                  </Link>
                                </li>
                              );
                            })}
                          </ol>
                        </Grid>
                      </Grid>
                    </div>
                    <Dialog
                      style={{
                        fontSize: "12px",
                      }}
                      classes={{
                        root: classes.center,
                        paper: classes.modal,
                      }}
                      open={isEditRootFamilies}
                      TransitionComponent={Transition}
                      keepMounted
                      onClose={() => setIsEditRootFamilies(false)}
                      aria-labelledby="modal-slide-title"
                      aria-describedby="modal-slide-description"
                    >
                      <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                      >
                        <h4 className={classes.modalTitle}>Edit Root</h4>
                      </DialogTitle>
                      <DialogContent
                        id="modal-slide-description"
                        className={classes.modalBody}
                      >
                        <span>Root meaning</span>
                        <TextField
                          id="outlined-multiline-static"
                          multiline
                          rows={3}
                          fullWidth
                          value={newRootMeaningFromRootFamilies}
                          onChange={(e) =>
                            setNewRootMeaningFromRootFamilies(e.target.value)
                          }
                        />
                        <br />
                        <span>Root family</span>
                        <TextField
                          id="outlined-multiline-static"
                          multiline
                          rows={3}
                          fullWidth
                          value={newRootFamilyFromRootFamilies}
                          onChange={(e) =>
                            setNewRootFamilyFromRootFamilies(e.target.value)
                          }
                        />
                      </DialogContent>
                      <DialogActions
                        className={
                          classes.modalFooter + " " + classes.modalFooterCenter
                        }
                        style={{ margin: "auto" }}
                      >
                        <Button
                          autoFocus
                          color="primary"
                          onClick={() => setIsEditRootFamilies(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          autoFocus
                          onClose={() => setIsEditRootFamilies(true)}
                          color="secondary"
                          onClick={updateRootFromRootFamilies}
                        >
                          Update
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                ),
              },
              // {
              //   tabName: "Macro Roots Report",
              //   // tabIcon: Code,setShowSentencePOS
              //   tabContent: (
              //     <>
              //       {macroRootsReport && macroRootsReport?.length == 0 ? (
              //         <Button
              //           color="secondary"
              //           onClick={() => getMacroRootsReport("false")}
              //         >
              //           Fetch the Macro Roots Report
              //         </Button>
              //       ) : (
              //         ""
              //       )}

              //       {macroRootsReport && macroRootsReport?.length > 0 ? (
              //         <Button
              //           color="secondary"
              //           onClick={() => getMacroRootsReport("true")}
              //         >
              //           Recreate the Macro Roots Report
              //         </Button>
              //       ) : (
              //         ""
              //       )}

              //       <span>
              //         {loadMacroReport ? (
              //           <BeatLoader
              //             color={"#BB2BFF"}
              //             loading={loadMacroReport}
              //             size={20}
              //           />
              //         ) : (
              //           ""
              //         )}
              //       </span>
              //       <Grid container spacing={2}>
              //         <Grid item xs={10} sm={10} md={12}>
              //           <br />
              //           <ReactTable columns={columns} data={macroRootsReport} />
              //         </Grid>
              //       </Grid>
              //     </>
              //   ),
              // },
              {
                tabName: "POS Sentences",
                // tabIcon: Code,setShowSentencePOS
                tabContent: (
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={11} md={10}>
                      <br />
                      {showSentencePOS &&
                        showSentencePOS.map((array, index) => {
                          return (
                            <p key={index} className={classes.posText}>
                              <span className={classes.posTextStrong}>
                                <strong>{array.token}</strong>
                              </span>
                              : {array.tag}
                            </p>
                          );
                        })}
                    </Grid>
                    <Grid item xs={12} sm={11} md={10}>
                      <TextField
                        id="outlined-multiline-static"
                        label="Sentence............"
                        multiline
                        rows={5}
                        fullWidth
                        value={sentencePOS}
                        onChange={(e) => setSentencePOS(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={11} md={10}>
                      <Button
                        color={sentencePOS ? "secondary" : "white"}
                        onClick={getSentencePOS}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                ),
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
