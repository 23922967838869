/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import BackArrow from "@material-ui/icons/ArrowBackIos";
import ForwardArrow from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";
import { KeyboardArrowLeftOutlined }  from '@mui/icons-material';
// core components
import { Link } from 'react-router-dom';
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";

import { ReactComponent as MenuImage} from "assets/img/menu-image.svg";
import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();
  const classes1 = useStyles();
  let location = useLocation();
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return location.pathname === routeName;
  }
  const { color, display, logo, bgColor, logoText, routes } = props;
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        var activePro = " ";
        var listItemClasses;
        if (prop.path === "/upgrade-to-pro") {
          activePro = classes.activePro + " ";
          listItemClasses = classNames({
            [" " + classes[color]]: true,
          });
        } else {
          listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop.layout + prop.path),
          });
        }
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
        });
        return (
          <NavLink
            to={prop.layout + prop.path}
            className={activePro + classes.item}
            activeClassName="active"
            key={key}
          >
            {/* <Link to={prop.layout + prop.path}>{prop.layout + prop.path}</Link><br/> */}
            <ListItem button className={classes.itemLink + listItemClasses}>
              {typeof prop.icon === "string" ? (
                <Icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive,
                  })}
                >
                  {prop.icon}
                </Icon>
              ) : (
                <prop.icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive,
                  })}
                />
              )}
              <ListItemText
                primary={props.rtlActive ? prop.rtlName : prop.name}
                className={classNames(classes.itemText, whiteFontClasses, {
                  [classes.itemTextRTL]: props.rtlActive,
                })}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
      <div className={classes.menuCardWrapper}>
        <div className={classes.menuCardText}>We Teach the Roots that Power Vocabulary</div>
        <div className={classes.menuCardImageWrapper}>
          <MenuImage fill='#808080' className={classes.menuImage} />
        </div>
      </div>
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a
        href="https://wordvoyage.com/"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive,
        })}
        target="_blank"
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {logoText}
      </a>
      <Tooltip
                 style={{ 
                  color: 'white', 
                  position: 'absolute',  
                  cursor: 'pointer',
                  bottom: '27px',
                  height: '20px',
                  left: '212px'}}
                data-id='tooltip' 
                onClick={() => {
      props.collapseSideBar();
    } }
                  title="Hide Sidebar"
                  placement="top"
                  classes={{tooltip:classes1.tooltip}}>
                  <IconButton data-id='tooltip' aria-label="Add" className={classes1.tableActionButton}>
                    <BackArrow data-id='tooltip' className={classes1.tableActionButtonIcon + " " + classes1.edit}/>
                  </IconButton>
                </Tooltip>
    </div>
  );
  return (
    display === '' ?
    <div style={{ display: display}}>
      
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {links}
          </div>
          {bgColor !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundColor: bgColor }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {bgColor !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundColor: bgColor }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div> :
    
    <Tooltip
                style={{position: 'fixed', zIndex: '10', color: 'white'}}
                data-id='tooltip' 
                onClick={() => {
      props.showSideBar();
    } }
                  title="Show Sidebar"
                  placement="top"
                  classes={{tooltip:classes1.tooltip}}>
                  <IconButton data-id='tooltip' aria-label="Add" className={classes1.tableActionButton}>
                    
                    <ForwardArrow data-id='tooltip' className={classes1.tableActionButtonIcon + " " + classes1.edit}/>
                  </IconButton>
                </Tooltip>
     

  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  collapseSideBar: PropTypes.func,
  showSideBar: PropTypes.func,
  bgColor: PropTypes.oneOf(["#34566A", "purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};
