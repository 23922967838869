import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory
} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import classNames from "classnames";
import MenuList from "@material-ui/core/MenuList";
import { ToastContainer, toast } from 'react-toastify';
import MenuItem from "@material-ui/core/MenuItem";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Search from "@material-ui/icons/Search";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import Header from "../../Header";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Popover from "@material-ui/core/Popover";
import BugReport from "@material-ui/icons/Home";
import Book from "@material-ui/icons/Book";
import Code from "@material-ui/icons/Person";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Poppins, 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};
const useStyles = makeStyles(styles);

export default function WordPage(props) {
  
  const [word, setWord] = useState("");
  const [wordList, setWordList] = useState("");
  const [allWordList, setAllWordList] = useState("");
  const [allLiteratureLists, setAllLiteratureLists] = useState("");
  const [literatureLists, setLiteratureLists] = useState([]);
  const [literatureListsArr, setLiteratureListsArr] = useState([]);
  const [allRootList, setAllRootList] = useState("");
  const [searchWordList, setSearchWordList] = useState("");
  const [searchRootList, setSearchRootList] = useState("");
  const [searchbyroot, setSearchByRoot] = useState("");
  const [wordSyllables, setWordSyllables] = useState([]);
  const [wordlistbyword, setWordListByWord] = useState("");
  const [wordlistbytitle, setWordListByTitle] = useState("");
  const [wordListWords, setWordListWords] = useState([]);
  const [wordlistname, setWordListName] = useState([]);
  const [roots, setRoots] = useState([]);
  const [filterRootsArr, setFilterRootsArr] = useState([]);
  const [wordlistnamearr, setWordListNameArr] = useState([]);
  const [literatureWordlistnamearr, setLiteratureWordListNameArr] = useState([]);
  const [wordlistnamearrwords, setWordListNameArrWords] = useState({});
  const [wordlistnamearrheadwords, setWordListNameArrHeadWords] = useState({});
  const [wordsArrToDisplay, setWordsArrToDisplay] = useState([]);
  const [headWordsArrToDisplay, setHeadWordsArrToDisplay] = useState([]);
  const [rootWordsArrToDisplay, setRootWordsArrToDisplay] = useState([]);
  const [headWord, setHeadWord] = useState("");
  const [wordFamily, setWordFamily] = useState([]);
  const [frequency, setFrequency] = useState("");
  const [speechPart, setSpeechPart] = useState("");
  const [firstSpeechPart, setFirstSpeechPart] = useState({});
  const [secondSpeechPart, setSecondSpeechPart] = useState({});
  const [thirdSpeechPart, setThirdSpeechPart] = useState({});
  const [wordMeaning, setWordMeaning] = useState("");
  const [syllable, setSyllable] = useState("");
  let history = useHistory();
  const handleSubmit = (evt) => {
    setSyllable('');
    setHeadWord('');
    setWordFamily([]);
    setFrequency('');
    setSpeechPart('');
    setWordMeaning('');
    setFirstSpeechPart({})
    setSecondSpeechPart({})
    setThirdSpeechPart({})
    
    
    if (word === "" || word.replace(/\s/g, '') === '') {
      return toast.error(`Please enter a word`)
    }
    if (!(/[a-zA-Z]/).test(word)) {
      return toast.error(`Please enter a valid word`)
    }
    evt.preventDefault();
    fetch(process.env.REACT_APP_API_URL + "words/" + word.replace(/\s/g, ''))
      .then((res) => res.json())
      .then((data) => {
        if (data === 'Error: no such file or directory' || data.found === false) {
          return toast.error(`Sorry, the word, ${word}, does not exits!`)
        }
        let rootarr = []
        if (data.roots) {
        
        data.roots.forEach(eachroot => {
          // console.log(eachroot);
          const root = [
            "Add/Edit Root 1",
            "Clear 1",
            eachroot['root_type'],
            // eachroot['root_type'] === 'R' ? 'base' : eachroot['root_type'] === 'S' ? 'suffix' : eachroot['root_type'] === 'P' ? 'prefix' : '',
            eachroot.root,
            eachroot['exact_root'],
            eachroot.meaning,
          ]
          rootarr.push(root);
        });
          
      }
        setRoots(rootarr)
        if (data.headWord) {
          
          fetch(process.env.REACT_APP_API_URL + "words/" + data.headWord)
            .then((res) => res.json())
            .then((data1) => {
              setHeadWord(data.headWord);
              setFrequency(data1.frequency);
              if (data1.speech_part) {
                setSpeechPart(data1.speech_part);
              } else if (data1[0]) {
                // console.log(data1);
                let info = {
                  partOfSpeech: data1[0].speech_part,
                  meaning: data1[0].meaning
                }
                setFirstSpeechPart(info)
              } else if (data1[1]) {
                // console.log(data1);
                let info = {
                  partOfSpeech: data1[1].speech_part,
                  meaning: data1[1].meaning
                }
                setSecondSpeechPart(info)
              } else if (data1[2]) {
                // console.log(data1);
                let info = {
                  partOfSpeech: data1[2].speech_part,
                  meaning: data1[2].meaning
                }
                setThirdSpeechPart(info)
              }
              
              
              setWordMeaning(data1.meaning);
            if (data.syllables) {
              setSyllable(data.syllables);
            }
              setWordFamily(data1.otherForms);
            })
            .catch(error => {
              console.log(error);
          return toast.error(`Sorry, the word, ${word}, does not exits in the database!`)
          });
        } else if (data.otherForms) {
          setHeadWord(word);
          setWordFamily(data.otherForms);
          setFrequency(data.frequency);
          
          if (data.speech_part) {
            setSpeechPart(data.speech_part);
          }
          if (data[0]) {
            
            let info = {
              partOfSpeech: data[0].speech_part,
              meaning: data[0].meaning
            }
            setFirstSpeechPart(info)
          }
          if (data[1]) {
            
            let info = {
              partOfSpeech: data[1].speech_part,
              meaning: data[1].meaning
            }
            setSecondSpeechPart(info)
          }
          if (data[2]) {
            
            let info = {
              partOfSpeech: data[2].speech_part,
              meaning: data[2].meaning
            }
            setThirdSpeechPart(info)
          }
          setWordMeaning(data.meaning);
          if (data.syllables) {
            setSyllable(data.syllables);
          }
        } else {
          
          
          
          if (data.syllables) {
            setSyllable(data.syllables);
          }
          if (data.meaning) {
            setWordMeaning(data.meaning);
          }
          if (data.speech_part) {
            setSpeechPart(data.speech_part);
          }
          if (data[0]) {
            let info = {
              partOfSpeech: data[0].speech_part,
              meaning: data[0].meaning
            }
            setFirstSpeechPart(info)
          }
          if (data[1]) {
            let info = {
              partOfSpeech: data[1].speech_part,
              meaning: data[1].meaning
            }
            setSecondSpeechPart(info)
          }
          if (data[2]) {
            let info = {
              partOfSpeech: data[2].speech_part,
              meaning: data[2].meaning
            }
            setThirdSpeechPart(info)
          }
          if (data.frequency) {
            setFrequency(data.frequency);
          }
        }
        
        
        
      })
      .catch(error => {
        console.log(error);
        // if (booktoedit === '') {
        //   toast.error('please choose book to scan')
        // } else {
        //   toast.error('scan fail')
        // }
        
    });
  };
  
  const searchRootFunction = () => {
    var filter, i, txtValue;
    filter = searchRootList.toUpperCase() || '#';
    for (i = 0; i < allRootList.length; i++) {
      txtValue = allRootList[i];
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        filterRootsArr.push(txtValue.replace('.json', ''))
      } 
    }
  }

  const searchFunction = () => {
    var filter, i, txtValue;
    filter = searchWordList.toUpperCase() || '#';
    for (i = 0; i < allWordList.length; i++) {
      txtValue = allWordList[i];
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        wordSyllables.push(txtValue.replace('.json', ''))
      } 
    }
  }

  const searchWordListByTittleFunction = () => {
    var filter, i, txtValue;
    filter = wordlistbytitle.toLowerCase() || '##';
    for (i = 0; i < allLiteratureLists.length; i++) {
      let text = allLiteratureLists[i]
      txtValue = allLiteratureLists[i].toLowerCase();
      if (txtValue.indexOf(filter) > -1) {
        literatureLists.push(text)
      } 
    }
  }

  const handleSubmitWordListBtn = (evt) => {
    evt.preventDefault();
    setWordsArrToDisplay([])
    setHeadWordsArrToDisplay([])
    setWordSyllables([])
    if (searchWordList === "" || searchWordList.replace(/\s/g, '') === '') {
      return toast.error(`Please enter a word list`)
    }
    if (!(/[a-zA-Z]/).test(searchWordList)) {
      return toast.error(`Please enter a valid word list`)
    }
    fetch(process.env.REACT_APP_API_URL + "wordlists/" + searchWordList)
      .then((res) => res.json())
      .then((data) => {
        if (data === 'This wordlist does not exist') {
          setWordListWords([])
          let arr = [searchWordList + ': This wordlist does not exist']
          setWordListNameArr(arr)
          setSearchWordList('')
          setWordList('')
        } else if (data.words) {
          setWordListWords([])
          let arr1 = [searchWordList]
          setWordsArrToDisplay(data.words)
          setWordsArrToDisplay(data.headwords)
          // setHeadWordsArrToDisplay(data.headwords)
          setWordListNameArr(arr1)
          setSearchWordList('')
          setWordList('')
          setWordSyllables([])
        }
        
  });
}
// const handleSubmitSearchByRoot = (evt) => {
//   evt.preventDefault();
//   setRootWordsArrToDisplay([])
//   setFilterRootsArr([])
//   if (searchRootList === "" || searchRootList.replace(/\s/g, '') === '') {
//     return toast.error(`Please enter a root list`)
//   }
//   if (!(/[a-zA-Z]/).test(searchRootList)) {
//     return toast.error(`Please enter a valid root list`)
//   }
//   fetch(process.env.REACT_APP_API_URL + "roots/" + searchRootList)
//     .then((res) => res.json())
//     .then((data) => {
//       if (data === 'This root meaning does not exist') {
//         // setWordListWords([])
//         let arr = [searchRootList + ': This root meaning does not exist']
//         // setWordListNameArr(arr)
//         setSearchRootList('')
//         // setWordList('')
//       } else if (data.words) {
//         console.log(data.words);
//         // setWordListWords([])
//         let arr1 = [searchRootList]
//         setRootWordsArrToDisplay(data.words)
//         // setWordListNameArr(arr1)
//         setSearchRootList('')
//         // setWordList('')
//         // setWordSyllables([])
//       }
      
// });
// }
const handleSubmitRootListBtn = (evt) => {
  evt.preventDefault();
  setRootWordsArrToDisplay([])
  setFilterRootsArr([])
  if (searchRootList === "" || searchRootList.replace(/\s/g, '') === '') {
    return toast.error(`Please enter a root list`)
  }
  if (!(/[a-zA-Z]/).test(searchRootList)) {
    return toast.error(`Please enter a valid root list`)
  }
  fetch(process.env.REACT_APP_API_URL + "roots/" + searchRootList)
    .then((res) => res.json())
    .then((data) => {
      if (data === 'This root meaning does not exist') {
        // setWordListWords([])
        let arr = [searchRootList + ': This root meaning does not exist']
        // setWordListNameArr(arr)
        setSearchRootList('')
        // setWordList('')
      } else if (data.words) {
        // console.log(data.words);
        // setWordListWords([])
        let arr1 = [searchRootList]
        setRootWordsArrToDisplay(data.words)
        // setWordListNameArr(arr1)
        setSearchRootList('')
        // setWordList('')
        // setWordSyllables([])
      }
      
});
}
const handleSearchSubmitWordList = (evt) => {
  evt.preventDefault();
  setWordsArrToDisplay([])
  setHeadWordsArrToDisplay([])
  if (evt.target.textContent === "" || evt.target.textContent.replace(/\s/g, '') === '') {
    return toast.error(`Please enter a word list`)
  }
  if (!(/[a-zA-Z]/).test(evt.target.textContent)) {
    return toast.error(`Please enter a valid word list`)
  }
  fetch(process.env.REACT_APP_API_URL + "wordlists/" + evt.target.textContent)
    .then((res) => res.json())
    .then((data) => {
      setWordListWords(data.words)
      setWordListName(evt.target.textContent)
      let arr1 = [evt.target.textContent]
      setWordsArrToDisplay(data.words)
      setHeadWordsArrToDisplay(data.headwords)
      setWordListNameArr(arr1)
      setSearchWordList('')
      setWordList('')
      setWordSyllables([])
});
}
const handleSearchSubmitRoot = (evt) => {
  evt.preventDefault();
  setRootWordsArrToDisplay([])
  if (evt.target.textContent === "" || evt.target.textContent.replace(/\s/g, '') === '') {
    return toast.error(`Please enter a root list`)
  }
  if (!(/[a-zA-Z]/).test(evt.target.textContent)) {
    return toast.error(`Please enter a valid root list`)
  }
  fetch(process.env.REACT_APP_API_URL + "roots/" + evt.target.textContent)
    .then((res) => res.json())
    .then((data) => {
     
      setRootWordsArrToDisplay(data.words)
      // setWordListWords(data.words)
      // setWordListName(evt.target.textContent)
      // let arr1 = [evt.target.textContent]
      // setWordsArrToDisplay(data.words)
      // setWordListNameArr(arr1)
      setSearchRootList('')
      setFilterRootsArr([])
      // setWordList('')
});
}

useEffect(() => {
  fetch(process.env.REACT_APP_API_URL + "wordlists")
  .then((res) => res.json())
  .then((data) => {
    setAllWordList(data);
    fetch(process.env.REACT_APP_API_URL + "roots")
    .then((res) => res.json())
    .then((data) => {
      // console.log(data);
      setAllRootList(data);
      fetch(process.env.REACT_APP_API_URL + "literature/wordlists")
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setAllLiteratureLists(Object.keys(data));
    });
  });
});
}, []);

const handleSubmitWordListByWord = (evt) => {
  evt.preventDefault();
  setWordsArrToDisplay([])
  setHeadWordsArrToDisplay([])
  if (wordlistbyword === "" || wordlistbyword.replace(/\s/g, '') === '') {
    return toast.error(`Please enter a word`)
  }
  if (!(/[a-zA-Z]/).test(wordlistbyword)) {
    return toast.error(`Please enter a valid word`)
  }
  fetch(process.env.REACT_APP_API_URL + "words/" + wordlistbyword)
  .then((res) => res.json())
  .then((data) => {
    if (data.headWord) {
      fetch(process.env.REACT_APP_API_URL + "words/" + data.headWord)
      .then((res) => res.json())
      .then((data) => {
        let arr = []
        let counter = 0
        if (data.wordlist) {
      
        data.wordlist.forEach(element => {
        fetch(process.env.REACT_APP_API_URL + "wordlists/" + element)
        .then((res) => res.json())
        .then((data2) => {
          setWordListWords(data2.words)
          // setHeadWordsArrToDisplay(data2.headwords)
          arr.push(element)
          counter += 1;
          if (counter === data.wordlist.length) {
            setWordListNameArr(arr)
          }
          setWordListByWord('')
        });
      })
    } else {
      toast.error('Sorry, Wordlist doesn\'t exist')
    }
    })
    } else {
      let arr = []
      let counter = 0
      if (data.wordlist) {
      
      data.wordlist.forEach(element => {
        fetch(process.env.REACT_APP_API_URL + "wordlists/" + element)
        .then((res) => res.json())
        .then((data2) => {
          // console.log(data2);
          setWordListWords(data2.words)
          
          arr.push(element)
          wordlistnamearrwords[element] = data2.words
          wordlistnamearrheadwords[element] = data2.headwords
          counter += 1;
          if (counter === data.wordlist.length) {
            setWordListNameArr(arr)
          }
          setWordListByWord('')
        });
      });
        
    } else {
      toast.error('Sorry, Wordlist doesn\'t exist')
    }
    }
  });
}

const handleSubmitWordListByTitle = (evt) => {
  evt.preventDefault();
  setWordsArrToDisplay([])
  setHeadWordsArrToDisplay([])
  setWordListByTitle('')
  if (wordlistbytitle === "" || wordlistbytitle.replace(/\s/g, '') === '') {
    return toast.error(`Please enter a word`)
  }
  if (!(/[a-zA-Z]/).test(wordlistbytitle)) {
    return toast.error(`Please enter a valid word`)
  }
  fetch(process.env.REACT_APP_API_URL + "literature/wordlists")
  .then((res) => res.json())
  .then((data) => {
    let object = {}
    literatureLists.forEach(element => {
      object[element] = data[element]
    });
    let array =[]
    for (const key in object) {
      array.push([object[key]['title'], object[key]['author'], object[key]['status']])
    }
    setLiteratureListsArr(array)
    setLiteratureLists([])
    // Object.keys(data).forEach(element => {
    //   var matcher = new RegExp(element.toLowerCase());
      
    //   if (new RegExp(wordlistbytitle.toLowerCase()).test(matcher)) {
    //     console.log(element);
    //   }
    // });
  });
}

  const classes = useStyles();
  const [open, setOpen] = React.useState(null);
  const handleToggle = (event) => {
    if (open && open.contains(event.target)) {
      setOpen(null);
    } else {
      setOpen(event.currentTarget);
    }
  };

  const handleClose = () => {
    setOpen(null);
  };
  return (
    <div>
    {/* <Header/> */}
    <div className="form-group">
        <ToastContainer />
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title=""
            headerColor="primary"
            tabs={[
              {
                tabName: 'Edit ' + props.match.params.word,
                tabContent: (
                  <Card>
    <CardBody>
                                      <GridContainer>
                                        <GridItem xs={12} sm={12} md={5}>
                                        <button onClick={() => history.goBack()}>Back</button>
                                          <CustomInput
                                            labelText="Syllables"
                                            id="company-disabled"
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                              disabled: true,
                                              value: syllable,
                                            }}
                                          />
                                        </GridItem>
                                        {/* <GridItem xs={12} sm={12} md={3}>
                                          <CustomInput
                                            labelText="Word mp3"
                                            id="username"
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                              disabled: true,
                                            }}
                                          />
                                        </GridItem> */}
                                        {/* <GridItem xs={12} sm={12} md={4}>
                                          <CustomInput
                                            labelText="Synonyms"
                                            id="email-address"
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                              disabled: true,
                                            }}
                                          />
                                        </GridItem> */}
                                      </GridContainer>
                                      <GridContainer>
                                        {/* <GridItem xs={12} sm={12} md={6}>
                                          <CustomInput
                                            labelText="Antonyms"
                                            id="first-name"
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                              disabled: true,
                                            }}
                                          />
                                        </GridItem> */}
                                        <GridItem xs={12} sm={12} md={6}>
                                          <CustomInput
                                            labelText="Frequency"
                                            id="float"
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                              disabled: true,
                                              value: frequency,
                                            }}
                                          />
                                        </GridItem>
                                        {/* <div><button onClick={BrowserHistory.goBack}>Go Back</button></div> */}
                                        {!speechPart ? 
                                          <GridItem xs={12} sm={12} md={6}>
                                          <CustomInput
                                            labelText="Part of Speech"
                                            id="last-name"
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                              disabled: true,
                                              value: speechPart,
                                            }}
                                          />
                                        </GridItem>
                                        :
                                        <span></span>
                                        }
                                        {wordMeaning ? 
                                        <GridItem xs={12} sm={12} md={12}>
                                          <InputLabel
                                            style={{ color: "#AAAAAA" }}
                                          >
                                            <br />
                                            Meaning
                                          </InputLabel>
                                          <p>{wordMeaning}</p>
                                          {/* <CustomInput
                                            // labelText={wordMeaning}
                                            id="about-me"
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                            }}
                                          /> */}
                                        </GridItem>
                                      :
                                      <span></span>
                                      }
                                        {firstSpeechPart.partOfSpeech ? 
                                          <GridItem xs={12} sm={12} md={12}>
                                          <CustomInput
                                            labelText="1st Part of Speech"
                                            id="last-name"
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                              disabled: true,
                                              value: firstSpeechPart.partOfSpeech,
                                            }}
                                          />
                                        </GridItem>
                                        :
                                        <span></span>
                                        }
                                        {firstSpeechPart.meaning ? 
                                        <GridItem xs={12} sm={12} md={12}>
                                          <InputLabel
                                            style={{ color: "#AAAAAA" }}
                                          >
                                            <br />
                                            Meaning
                                          </InputLabel>
                                          <p>{firstSpeechPart.meaning}</p>
                                          {/* <CustomInput
                                            // labelText={wordMeaning}
                                            id="about-me"
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                            }}
                                          /> */}
                                        </GridItem>
                                      :
                                      <span></span>
                                      }
                                        {secondSpeechPart.partOfSpeech ? 
                                          <GridItem xs={12} sm={12} md={6}>
                                          <CustomInput
                                            labelText="2nd Part of Speech"
                                            id="last-name"
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                              disabled: true,
                                              value: secondSpeechPart.partOfSpeech,
                                            }}
                                          />
                                        </GridItem>
                                        :
                                        <span></span>
                                        }
                                        {secondSpeechPart.meaning ? 
                                        <GridItem xs={12} sm={12} md={12}>
                                          <InputLabel
                                            style={{ color: "#AAAAAA" }}
                                          >
                                            <br />
                                            Meaning
                                          </InputLabel>
                                          <p>{secondSpeechPart.meaning}</p>
                                          {/* <CustomInput
                                            // labelText={wordMeaning}
                                            id="about-me"
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                            }}
                                          /> */}
                                        </GridItem>
                                      :
                                      <span></span>
                                      }
                                        {thirdSpeechPart.partOfSpeech ? 
                                          <GridItem xs={12} sm={12} md={6}>
                                          <CustomInput
                                            labelText="3rd Part of Speech"
                                            id="last-name"
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                              disabled: true,
                                              value: thirdSpeechPart.partOfSpeech,
                                            }}
                                          />
                                        </GridItem>
                                        :
                                        <span></span>
                                        }
                                      </GridContainer>
                                      

                                      
                                      
                                      {thirdSpeechPart.meaning ? 
                                        <GridItem xs={12} sm={12} md={12}>
                                          <InputLabel
                                            style={{ color: "#AAAAAA" }}
                                          >
                                            <br />
                                            Meaning
                                          </InputLabel>
                                          <p>{thirdSpeechPart.meaning }</p>
                                          {/* <CustomInput
                                            // labelText={wordMeaning}
                                            id="about-me"
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                            }}
                                          /> */}
                                        </GridItem>
                                      :
                                      <span></span>
                                      }
                                      
                                    </CardBody>
                                    <CardFooter>
                                      <Button color="primary" disabled>
                                        Update Word
                                      </Button>
                                    </CardFooter>
                                  </Card>

                ),
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
